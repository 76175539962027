import { Box, Grid2 } from "@mui/material"
import { AppColors } from "../../theme"
import "./AddActivity.scss"
import AppInput from "../../components/AppInput/AppInput"
import { useTranslation } from "react-i18next"
import { ActivityProposalDetails } from "../../api/types/Project"
import { formatToDate } from "../../utils/dateTime"
import AppDatePicker from "../../components/AppDatePicker/AppDatePicker"
import moment from "moment"

interface TeamPreviousProposalDatesPropos {
    disabled?: boolean
    proposalData: ActivityProposalDetails
    sessionId: number
    screenProp?: string
}

export default function TeamPreviousProposalDates({
    disabled,
    proposalData,
    sessionId,
    screenProp,
}: TeamPreviousProposalDatesPropos) {
    const { t } = useTranslation("translation", { keyPrefix: "activity" })

    const isDisabled = proposalData?.status === "approved" ||
        proposalData?.status === "rejected" ? true : false


    return (
        <Box>
            <Grid2
                display={"flex"}
                justifyContent={"space-between"}
                gap={4}
                marginBottom={"20px"}
            >
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Planned Start Date"}
                        name={"planned_start_date"}
                        disabled={true}
                        value={moment(proposalData?.activity_current_dates.planned_start_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.secondary } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Planned Finish Date"}
                        name={"planned_finish_date"}
                        disabled={true}
                        value={moment(proposalData?.activity_current_dates.planned_finish_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.secondary } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        type="date"
                        label={"Actual Start Date"}
                        name={"actual_start_date"}
                        disabled={true}
                        value={moment(
                            proposalData?.activity_current_dates?.actual_start_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.brgOrange } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Actual Finish Date"}
                        name={"actual_finish_date"}
                        disabled={true}
                        value={moment(proposalData?.activity_current_dates?.actual_finish_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.brgOrange } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
            </Grid2>
            <Grid2 display={"flex"} justifyContent={"space-between"} gap={4}>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Proposed Planned Start Date"}
                        name={"proposed_planned_start_date"}
                        disabled={isDisabled}
                        value={moment(proposalData?.proposed_planned_start_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.secondary } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Proposed Planned Finish Date"}
                        name={"proposed_planned_finish_date"}
                        disabled={isDisabled}
                        value={moment(proposalData?.proposed_planned_finish_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.secondary } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Proposed Actual Start Date"}
                        name={"proposed_actual_start_date"}
                        disabled={isDisabled || !proposalData?.activity_current_dates?.actual_start_date}
                        value={moment(proposalData?.proposed_actual_start_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.brgOrange } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
                <Grid2 width={"50%"}>
                    <AppDatePicker
                        label={"Proposed Actual Finish Date"}
                        name={"proposed_actual_finish_date"}
                        disabled={isDisabled || !proposalData?.activity_current_dates?.actual_finish_date}
                        value={moment(proposalData?.proposed_actual_finish_date || " ")}
                        slotProps={{
                            input: { style: { height: "40px" } },
                            inputLabel: { style: { color: AppColors.brgOrange } },
                            labelSize: "14px"
                        }}
                    />
                </Grid2>
            </Grid2>
        </Box>
    )
}

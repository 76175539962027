/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from "react"

import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import "./SwimlaneActivityDetail.scss"
import {
	Avatar,
	AvatarGroup,
	Grid2,
	LinearProgress,
	Typography,
} from "@mui/material"
import { activitiesStatusArray } from "../../utils"
import { Svgs } from "../../assets/svgs"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { capitalizeEachWord } from "../../utils/index"
import { getRelationshipDescription, Item } from "./SwimlaneData"
import { useNavigate } from "react-router-dom"
import {
	useGetActivityByIdQuery,
	useGetSessionIdQuery,
	useUpdateActivityMutation,
} from "../../api/network/projectApiService"
import { toast } from "react-toastify"
import { routeUrls } from "../../routes/routeUrls"
import SubActivityModal from "./SubActivityModal"
import StatusConfirmationModal from "../../components/AppConfirmationModal/StatusConfirmationModal"
import { format as dateFnsFormat } from "date-fns"
import { ProjectUserRole } from "../../api/types/Auth"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter";

export default function SwimlaneActivityDetail({
	openDrawer,
	onToggleDrawer,
	activityItem,
}: {
	openDrawer: boolean
	onToggleDrawer: () => void
	activityItem: Item
}) {
	const navigate = useNavigate()
	const [statusFilter, setStatusFilter] = useState("")
	const [statusConfirmation, setStatusConfirmation] = useState(false)
	const [showHide, setShowHide] = useState(false)
	const [showHide2, setShowHide2] = useState(false)
	const [openSubActivities, setOpenSubActivities] = useState(false)
	const [tempStatus, setTempStatus] = useState("")
	const [updateActivityApiTrigger, { isLoading: isSavingActivity }] =
		useUpdateActivityMutation()
	const { data, error } = useGetSessionIdQuery(
		activityItem?.project, { skip: !activityItem?.project }
	)

	const { data: activityData, isFetching: isFetchingActivity } =
		useGetActivityByIdQuery(activityItem?.id?.toString() ?? "", {
			skip: !openDrawer,
		})

	useEffect(() => {
		if (activityData) {
			setTempStatus(activityData?.activity_status ?? "")
			setStatusFilter(activityData?.activity_status ?? "")
		}
	}, [activityData])

	const handleShowHide = () => {
		setShowHide(!showHide)
	}

	const handleShowHide2 = () => {
		setShowHide2(!showHide2)
	}

	const activitiesStatusOptions = useMemo(() => {
		return activitiesStatusArray.map((s) => ({ id: s.status, label: s.status }))
	}, [])

	const changeStatus = () => {
		setStatusConfirmation(false)
	}

	const updateActivityStatus = (value: any) => {
		const payload: { id: string; [key: string]: any } = {
			id: activityData?.id?.toString() ?? "",
		}
		if (statusFilter == "Planned") {
			payload["id"] = activityData?.id.toString() ?? ""
			payload["activity_status"] = "planned"
			payload["session"] = data?.id
			payload["actual_start_date"] = value?.actual_start_date
				? dateFnsFormat(
						new Date(value.actual_start_date ?? ""),
						"yyyy-MM-dd hh:mm:ss",
					)
				: null
			payload["actual_finish_date"] = value?.actual_finish_date
				? dateFnsFormat(
						new Date(value.actual_finish_date ?? ""),
						"yyyy-MM-dd hh:mm:ss",
					)
				: null
		} else if (statusFilter == "In Progress") {
			payload["id"] = activityData?.id?.toString() ?? ""
			payload["activity_status"] = "in_progress"
			payload["session"] = data?.id
			payload["actual_start_date"] = dateFnsFormat(
				new Date(value.actual_start_date),
				"yyyy-MM-dd HH:mm:ss",
			)
		} else if (statusFilter == "Completed") {
			payload["id"] = activityData?.id?.toString() ?? ""
			payload["activity_status"] = "completed"
			payload["session"] = data?.id
			payload["actual_finish_date"] = dateFnsFormat(
				new Date(value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		updateActivityApiTrigger(payload)
			.unwrap()
			.then((res) => {
				setStatusConfirmation(false)
				changeStatus()
				toast.success("Status updated successfully")
				onToggleDrawer()
				// window.location.reload()
			})
			.catch((err) => {
				console.log("err", err)
			})
	}

	const handleCloseStatusChangeModal = () => {
		setStatusConfirmation(false)
		setStatusFilter(tempStatus ?? "")
	}

	const handleStatusFilterChange = (value: string) => {
		setStatusFilter(value)
	}

	const handleSubActivitiesClick = () => {
		setOpenSubActivities(true)
	}

	const handleEditActivity = () => {
		if (activityData?.is_editable) {
			navigate(
				routeUrls.activity.activityDetails(activityData?.id?.toString() ?? ""),
				{
					state: { fromActivityList: false },
				},
			)
		}
	}

	useEffect(() => {
		if (activityData) {
			setTempStatus(activityData?.activity_status ?? "")
			setStatusFilter(activityData?.activity_status ?? "")
		}
	}, [activityData])

	const previousStartDate = useMemo(() => {
		if (
			activityData?.previous_session &&
			activityData?.previous_session?.planned_start_date
		) {
			return formatDateWithMonthName(
				activityData?.previous_session?.planned_start_date as string,
			)
		} else {
			return formatDateWithMonthName(
				activityData?.p6_planned_start_date as string,
			)
		}
	}, [activityData])

	const previousFinishDate = useMemo(() => {
		if (
			activityData?.previous_session &&
			activityData?.previous_session?.planned_finish_date
		) {
			return formatDateWithMonthName(
				activityData?.previous_session?.planned_finish_date as string,
			)
		} else {
			return formatDateWithMonthName(
				activityData?.p6_planned_finish_date as string,
			)
		}
	}, [activityData])

	const ActivityDataView = () => {
		return (
			<>
				<Box width={260} m={1} className="content-container">
					<Box className="content-box" height={"80vh"}>
						<Box display={"flex"} justifyContent={"space-between"}>
							<Grid2 className="title">
								{activityData?.task_code}
								<span
									style={{ marginLeft: "5px", cursor: "pointer" }}
									// onClick={() =>
									// 	navigate(
									// 		routeUrls.activity.activityactivityItem(
									// 			activityactivityItem.id,
									// 		),
									// 	)
									// }

									onClick={handleEditActivity}
								>
									{activityData?.is_editable &&
										activityData?.current_user_role !==
											ProjectUserRole.TeamMember &&
										activityData?.current_user_role !==
											ProjectUserRole.Viewer && <Svgs.EditIcon />}
								</span>
							</Grid2>
							<Grid2>
								<AppDropdownFilter
									customStyle={{
										background: `${
											statusFilter.toLowerCase() === "planned"
												? "#F57F32"
												: statusFilter === "in_progress" ||
													  statusFilter == "In Progress"
													? "#00A79D"
													: "#54AE2A"
										}`,
										color: "#fff",
										marginTop: "-5px",
										marginRight: "-6px",
									}}
									disabled={Boolean(error)}
									placeholder={statusFilter}
									menuItems={activitiesStatusOptions}
									onChange={status => {
										const isCompleted = (
											statusFilter === "completed" ||
											statusFilter === "Completed"
										)
										const isDisabled = isCompleted && (
											status?.label.toLowerCase() === "in progress" ||
											status?.label.toLowerCase() === "planned"
										)
										if (!isDisabled) {
											setStatusConfirmation(true);
											handleStatusFilterChange(status?.label)
										}
									}}
								/>
							</Grid2>
						</Box>
						<Box>
							<Grid2 className={"title3"}>{activityData?.activity_name}</Grid2>
							<Grid2 display={"flex"} justifyContent={"space-between"} mt={1}>
								<Grid2 width={220}>
									<LinearProgress
										variant="determinate"
										value={activityData?.progress}
										color="success"
									/>
								</Grid2>
								<Typography variant={"caption"} mt={-1}>
									{`${activityData?.progress}%`}
								</Typography>
							</Grid2>
							<Box>
								<Grid2 className="title2">{"Plan Details"}</Grid2>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Planned Start Date"}
									</Typography>
									<Typography className="content3" variant={"caption"}>
										{activityData?.planned_start_date
											? formatDateWithMonthName(
													activityData?.planned_start_date as string,
												)
											: ""}
									</Typography>
								</Grid2>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Planned Finish Date"}
									</Typography>
									<Typography className="content3" variant={"caption"}>
										{activityData?.planned_finish_date
											? formatDateWithMonthName(
													activityData?.planned_finish_date as string,
												)
											: ""}
									</Typography>
								</Grid2>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Actual Start Date"}
									</Typography>
									<Typography
										className="content3"
										color="#54AE2A"
										variant={"caption"}
									>
										{activityData?.actual_start_date
											? formatDateWithMonthName(
													(activityData?.actual_start_date as string) ?? "",
												)
											: ""}
									</Typography>
								</Grid2>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Actual Finish Date"}
									</Typography>
									<Typography
										className="content3"
										color="#FDB913"
										variant={"caption"}
									>
										{activityData?.actual_finish_date
											? formatDateWithMonthName(
													activityData?.actual_finish_date as string,
												)
											: ""}
									</Typography>
								</Grid2>

								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Previous Session Start Date"}
									</Typography>
									<Typography
										className="content3"
										color="#FDB913"
										variant={"caption"}
									>
										{previousStartDate}
									</Typography>
								</Grid2>

								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Previous Session Finish Date"}
									</Typography>
									<Typography
										className="content3"
										color="#FDB913"
										variant={"caption"}
									>
										{previousFinishDate}
									</Typography>
								</Grid2>

								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Total Float"}
									</Typography>
									<Typography
										className="content3"
										color="#FDB913"
										variant={"caption"}
									>
										{activityData?.total_float}
									</Typography>
								</Grid2>
							</Box>
							<Box>
								<Grid2 className="title2">{"Duration"}</Grid2>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"Original Duration"}
									</Typography>
									<Typography className="content3" variant={"caption"}>
										{`${activityData?.original_duration} days`}
									</Typography>
								</Grid2>
								{/* <Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Remaining Duration"}
												</Typography>
												<Typography className="content3" variant={"caption"}>
													{"10 days"}
												</Typography>
											</Grid2> */}
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="content2" variant={"caption"}>
										{"At Completion Duration"}
									</Typography>
									<Typography
										className="content3"
										color="#E72222"
										variant={"caption"}
									>
										{/* {"Est. 6 days (1 Day Delay)"} */}
										{activityData?.at_completion_duration}
									</Typography>
								</Grid2>
							</Box>
							<Box>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="title2">
										{"Predecessor Activities"}
									</Typography>
									<Typography
										onClick={handleShowHide}
										style={{ cursor: "pointer" }}
									>
										{showHide ? <Svgs.ShowIcon /> : <Svgs.HideIcon />}
									</Typography>
								</Grid2>
								{showHide && (
									<>
										{activityData?.predecessors?.map((predecessor: any) => (
											<Box key={predecessor?.id} mb={"5px"}>
												<Grid2
													display={"flex"}
													justifyContent={"space-between"}
												>
													<Typography className="content1" variant={"caption"}>
														<span className="bold-text gray-color">
															{predecessor.predecessor_id}
														</span>
													</Typography>
													<Typography className="content1" variant={"caption"}>
														<span className="bold-text">
															{`${getRelationshipDescription(predecessor.relationship_type)} 
																			(${predecessor.relationship_type})`}
														</span>
													</Typography>
												</Grid2>
												<Grid2 className="content-container">
													<Typography
														variant={"caption"}
														width={200}
														className="predecessor-name gray-color"
													>
														{capitalizeEachWord(predecessor.predecessor_name)}
													</Typography>
													<Typography variant={"caption"} className="style-one">
														<span className="right-margin gray-color">Lag</span>
														<span className="bold-text">{`${predecessor.lag_days} days`}</span>
													</Typography>
												</Grid2>
												<Grid2 className="content-container">
													<Typography
														variant={"caption"}
														width={200}
														style={{ flexGrow: 1 }}
													>
														<span className="gray-color">Start Date: </span>
														<span className="bold-text">
															{formatDateWithMonthName(
																predecessor.planned_start_date as string,
															)}
														</span>
													</Typography>
													<Typography
														variant={"caption"}
														width={200}
														className="style-one"
													>
														<span className="gray-color">End Date: </span>
														<span className="bold-text">
															{formatDateWithMonthName(
																predecessor.planned_finish_date as string,
															)}
														</span>
													</Typography>
												</Grid2>
											</Box>
										))}
									</>
								)}
							</Box>
							<Box mt={1}>
								<Grid2 display={"flex"} justifyContent={"space-between"}>
									<Typography className="title2">
										{"Successor Activities"}
									</Typography>
									<Typography
										onClick={handleShowHide2}
										style={{ cursor: "pointer" }}
									>
										{showHide2 ? <Svgs.ShowIcon /> : <Svgs.HideIcon />}
									</Typography>
								</Grid2>
								{showHide2 && (
									<Box>
										{activityData?.successors?.map((successor: any) => (
											<Box key={successor?.id} mb={"5px"}>
												<Grid2
													display={"flex"}
													justifyContent={"space-between"}
												>
													<Typography className="content1" variant={"caption"}>
														<span className="bold-text gray-color">
															{successor.successor_id}
														</span>
													</Typography>
													<Typography className="content1" variant={"caption"}>
														<span className="bold-text">
															{`${getRelationshipDescription(successor.relationship_type)} 
																			(${successor.relationship_type})`}
														</span>
													</Typography>
												</Grid2>
												<Grid2 className="content-container">
													<Typography
														variant={"caption"}
														width={200}
														className="predecessor-name gray-color"
													>
														{capitalizeEachWord(successor.successor_name)}
													</Typography>
													<Typography variant={"caption"} className="style-one">
														<span className="right-margin gray-color">Lag</span>
														<span className="bold-text">{`${successor.lag_days} days`}</span>
													</Typography>
												</Grid2>
												<Grid2 className="content-container">
													<Typography
														variant={"caption"}
														width={200}
														style={{ flexGrow: 1 }}
													>
														<span className="gray-color">Start Date: </span>
														<span className="bold-text">
															{formatDateWithMonthName(
																successor.planned_start_date as string,
															)}
														</span>
													</Typography>
													<Typography
														variant={"caption"}
														width={200}
														className="style-one"
													>
														<span className="gray-color">End Date: </span>
														<span className="bold-text">
															{formatDateWithMonthName(
																successor.planned_finish_date as string,
															)}
														</span>
													</Typography>
												</Grid2>
											</Box>
										))}
									</Box>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
				<Box mt={1}>
					<Typography
						textAlign={"center"}
						fontWeight={"700"}
						style={{ cursor: "pointer" }}
						onClick={handleSubActivitiesClick}
					>
						{"View Sub Activities"}
					</Typography>
				</Box>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					p={1}
					style={{ background: "#F5DCAE", borderRadius: "5px" }}
					mt={1}
					className="footer"
				>
					<Grid2 mt={1}>
						<span className="bold-text">{"Electricians "}</span>
						{"(Electrical)"}
					</Grid2>
					<Grid2>
						<AvatarGroup variant="square" max={4}>
							{activityData?.assignee?.map((assignee: any) => (
								<Avatar
									key={assignee?.id}
									style={{
										color: "#FFFFFF",
										background: "#22BCE7",
										borderRadius: "10px",
									}}
									alt={assignee?.name}
									src="/static/images/avatar/1.jpg"
								/>
							))}
						</AvatarGroup>
					</Grid2>
				</Box>
			</>
		)
	}

	return (
		<div>
			{openSubActivities && (
				<SubActivityModal
					activityDetails={activityData}
					open={openSubActivities}
					editable={activityData?.is_editable}
					onClose={() => {
						setOpenSubActivities(false)
					}}
				/>
			)}
			<>
				{/* <Button onClick={toggleDrawer}>{"Open Drawer"}</Button> */}
				{statusConfirmation ? (
					// <AppConfirmationModal
					// 	open={true}
					// 	onConfirm={updateActivityStatus}
					// 	onClose={() => setStatusConfirmation(false)}
					// 	confirmationMessage="Are you sure you want to change the status?"
					// />
					<StatusConfirmationModal
						open={true}
						onConfirm={updateActivityStatus}
						onClose={handleCloseStatusChangeModal}
						confirmationMessage="Are you sure you want to change the status?"
						status={statusFilter}
						task_code={activityData?.task_code}
						start_date={activityData?.actual_start_date}
						previous_session_start_date={
							activityData?.previous_session_start_date
						}
						previous_session_finish_date={
							activityData?.previous_session_finish_date
						}
						finish_date={activityData?.actual_finish_date}
					/>
				) : (
					<>
						<Drawer
							anchor={"right"}
							open={openDrawer}
							onClose={onToggleDrawer}
							sx={{ alignItems: "center" }}
						>
							<Box
								position="fixed"
								marginLeft={"-22px"}
								marginTop={"-2px"}
								style={{ cursor: "pointer" }}
								onClick={onToggleDrawer}
							>
								<Svgs.CloseIcon />
							</Box>
							{activityData && !isFetchingActivity ? (
								<ActivityDataView />
							) : (
								<Box
									width={260}
									m={1}
									className="content-container"
									alignContent="center"
								>
									Loading...
								</Box>
							)}
						</Drawer>
					</>
				)}
			</>
		</div>
	)
}

import { duration } from "@mui/material"
import { error } from "console"
import { sub } from "date-fns"

export const language_en = {
	save: "Save",
	cancel: "Cancel",
	authLayout: {
		app_name_pull: "Pull",
		app_name_pro: "Pro",
		app_description:
			"Streamline construction projects and boost efficiency with our Pull Planning App — collaborative scheduling made simple.",
	},
	loginPage: {
		login_heading: "Login",
		email_address: "Email address",
		password: "Password",
		remember_me: "Remember Me",
		forgot_password: "Forgot Password",
		login_btn: "Login",
		enter_email: "Enter email address",
		enter_password: "Enter password",
		errors: {
			email_required: "Email is required",
			invalid_email: "Invalid email",
			password_required: "Password is required",
			invalid_password:
				"Password must be minimum of 10 characters and include at least one lowercase letter, one uppercase letter, one number, and one special character",
		},
	},
	profileSetupPage: {
		welcome: "Welcome",
		description:
			"Kindly verify and furnish the necessary details to facilitate further proceedings.",
		personal_information: "Personal Information",
		name: "Name",
		enter_name: "Enter Name",
		email: "Email",
		phone_number: "Phone Number",
		enter_phone_number: "Please enter your phone number",
		next: "Next",
		professional_information: "Professional Information",
		job_title: "Job Title",
		company_name: "Company Name",
		office_phone_number: "Office Phone Number",
		onsite_emergency_contact_number: "Onsite Emergency Contact Number",
		offsite_emergency_contact_number:
			"Offsite Emergency Contact Number (Optional)",
		enter_the_phone_number: "Please enter the phone number",
		proceed_to_dashboard: "Proceed to Dashboard",
		save_button: "Save",
		cancel_button: "Cancel",
		edit_button: "Edit Profile",
		errors: {
			name_required: "Name is required",
			error_company_name: "Company Name is required",
			error_phone_number: "Phone number is required",
			error_email: "Email is required",
			error_office_phone_number: "Office phone number is required",
			error_onsite_emergency_contact_number:
				"Onsite emergency contact number is required",
		},
	},
	resetPassword: {
		title: "Reset Password",
		description:
			"You are on the verge of completion! Kindly generate a new password that complies with the security requirements and verify it to finalize the reset process.",
		instruction:
			"Your password must be 14 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character. Please note that your new password can not be same as the previous password.",
		password: "Password",
		enter_password: "Enter password",
		confirm_password: "Confirm Password",
		re_enter_password: "Re-enter password",
		confirm_new_pwd: "Confirm New Password",
		cancel: "Cancel",
		reset_successfull: "Password Reset Successful!",
		success_msg: "You have successfully completed re-setting your password. ",
		login_instruction:
			"Please click the 'Login' button to proceed with the login process.",
		proceed_to_login: "Proceed to Login",
		errors: {
			password_required: "Password is required",
			invalid_password: "Password does not meet the requirements",
			// invalid_password:"Your password must be 14 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
			password_not_match: "Passwords do not match, please try again",
			password_cannot_be_same:
				"Password cannot be same as the previous password",
			link_expired: "Link Has Expired",
			link_expired_detail: "The link you are trying to use has expired.",
			forgot_pwd_process:
				"Please click the 'Forgot Password' button below to reinitiate the process.",
			forgot_password: "Forgot Password",
		},
	},
	createPassword: {
		heading: "Hello",
		heading1:
			"Welcome to PullPro, please setup your password to proceed further.",
		heading2:
			"Your password must be 14 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
		password: "Password",
		confirm_password: "Confirm Password",
		enter_password: "Enter Password",
		enter_confirm_password: "Enter Confirm Password",
		cancel: "Cancel",
		errors: {
			password_required: "Password is required",
			confirm_password_required: "Confirm Password is required",
			invalid_password: "Password does not meet the requirements",
			// "Password must be minimum of 14 characters and include at least one lowercase letter, one uppercase letter, one number, and one special character",
			password_mismatch: "Passwords do not match",
		},
		password_setup_title: "Password Setup Complete!",
		password_setup_description:
			"You have successfully completed setting up your password.",
		password_setup_login_instruction:
			"Please click the 'Login' button to proceed with the login process.",
		proceed_to_login: "Proceed to Login",
	},
	usersList: {
		users: "Users",
		add_a_user: "Add a User",
		search_here: "Search here",
		company: "Company",
		companies: "Companies",
		job_title: "Job Title",
		status: "Status",
		reporter: "Reporter",
		existing: "Existing",
		invited: "Invited",
		locked: "Locked",
		name: "Name",
		email: "Email",
		joined_on: "Joined On",
		all: "all",
		active: "Active",
		deactivated: "Deactivated",
		// suspended: "Suspected",
		suspended: "Inactive",
		removed: "Removed",
		number_of_rows: "Number of rows",
		prev: "Prev",
		next: "Next",
		addUser: {
			personal_details: "Personal Details",
			name: "Name",
			name_placeholder: "Please enter a name",
			email: "Email",
			email_placeholder: "Please enter an email",
			job_title_placeholder: "Please enter a job title",
			personal_message: "Personal Message",
			personal_message_placeholder: "This is default message",
			//  "Please type a message here",
			project_details: "Project Details",
			company: "Company",
			is_company_admin: "Is Company Admin",
			message: "Message",
			project: "Project",
			project_placeholder: "Please select a project",
			role: "Role",
			role_placeholder: "Please select a role",
			add: "Add",
			send_invite: "Send Invite",
			select_company: "Please select a company",
			select_role: "Please select a role",
			errors: {
				name_required: "Name is required",
				email_required: "Email is required",
				job_title_required: "Job title is required",
				message_error: "Personal message is required",
				company_required: "Company is required",
				role_required: "Role is required",
				project_required: "Project is required",
			},
		},
	},
	// Company
	company: {
		id: "Company ID",
		name: "Company Name",
		address: "Company Address",
		email: "Email",
		phone_number: "Phone Number",
		logo: "Company Logo",
		company: "Company",
		companies: "Companies",
		search_here: "Search here",
		status: "Status",
		all: "All",
		active: "Active",
		// suspended: "Suspended",
		suspended: "Inactive",
		removed: "Removed",
		edit_company: "Edit Company Details",
		no_logo: "Company Logo Here",
		addCompany: {
			add_id: "Company ID(Only 3 letters)",
			add_name: "Please enter company name",
			add_address: "Please enter an address",
			add_email: "Please enter an email",
			add_phone_number: "Please enter a phone number",
			add_company: "Add Company",
			details: "Details",
		},
		errors: {
			company_id_required: "Company ID is required",
			company_name_required: "Company Name is required",
			company_address_required: "Company Address is required",
			invalid_email: "Invalid email",
			email_required: "Email is required",
			phone_number_required: "Phone number is required",
			logo_required: "Company Logo is required",
			company_name_length: "Company Name should not exceed 50 characters",
		},
	},
	fileUpload: {
		choose_file: "Choose File",
		no_file: "Choose a file to upload",
	},
	createProject: {
		projects: "Projects",
		add_project: "Add a Project",
		cancel: "Cancel",
		create_project: "Create Project",
		general_project_details: "General Project Details",
		company: "Company",
		select_company: "Please select a company",
		project_name: "Project Name",
		enter_project_name: "Please enter name",
		project_description: "Project Description",
		provide_project_description: "Please provide a description",
		start_date: "Start Date",
		end_date: "End Date",
		select_date: "Please select a date",
		project_client: "Project Client",
		select_client: "Please select a client",
		project_budget: "Project Budget",
		enter_numeric_value: "Please enter a numerical value",
		project_created_success: "Project created successfully",
		project_created_error: "Error while creating project",
		errors: {
			company_required: "Company is required",
			project_required: "Project Name is required",
			start_date_required: "Start date is required",
			end_date_required: "End date is required",
			end_less_than_start: "End date should not be less than start date",
			project_client_required: "Project client is required",
			project_budget_required: "Project budget must be greater than 0",
		},
	},
	viewAndEditProject: {
		edit_project: "Edit Project Details",
		project_id: "Project ID: {{id}}",
		cancel: "Cancel",
		save: "Save",
		viewProjectPlan: "View Project Plan",
		member_details: "Member Details",
		team_members: "Team Member(s)",
		calendar_details: "Calendar Details",
		no_members: "No team member(s) added",
		add_superintendents: "Please add superintendent(s)",
		add_team_member: "Add a Team Member",
		want_invite_user: "Want to invite new user?",
		swimlane_settings: "Swimlane Settings",
		session_logs: "Session Logs",
		go_to_add_user: {
			first: "Please go to ",
			bold: "‘Add a User’",
			remaining: " screen",
		},
		project_updated_success: "Project updated successfully",
		project_update_error: "Error while updating project",
		select_team_member_and_role:
			"Please select a team member and appoint a role to add.",
		team_member: "Team Member",
		select_team_member: "Please select a team member",
		role: "Role",
		select_role: "Please select a role",
		add_to_project: "Add to Project",
		team_member_added: "Team member added successfully",
		team_member_adding_error: "Error while adding team member",
		role_update_success: "Role updated successfully",
		role_update_error: "Error while updating the role",
		status_updated_success: "Project status updated successfully",
		status_updated_error: "Error while updating project status",
		errors: {
			not_found: "Project not found",
			select_user: "Select user to add",
			select_role: "Select role for the user",
		},
	},
	common: {
		listTable: {
			no_data: "No data available!",
			number_of_rows: "Number of rows",
			prev: "Prev",
			next: "Next",
		},
		sideNavbar: {
			dashboard: "Dashboard",
			customer: "Customers",
			companies: "Companies",
			company: "Company",
			users: "Users",
			projects: "Projects",
			logs: "Logs",
			activities: "Activities",
			sub_activities: "Sub Activties",
			logout: "Log Out",
			confirm_logout: "Are you sure, you want to logout?",
			yes: "Yes",
			no: "No",
			logout_error: "Error while logging out",
			feedback: "Feedback/Suggestion",
			configuration: "Configuration",
			cancel: "Cancel",
			confirm: "Confirm",
			actual_start_date: "Actual Start Date",
			actual_finish_date: "Actual Finish Date",
		},
	},
	forgotPassword: {
		title: "Forgot Password?",
		description:
			"If you have forgotten your password, please provide your email, and we will promptly send you a link to reset your password.",
		email: "Email address",
		enter_email: "Enter email address",
		submit: "Submit",
		button_text: "Send Reset Link",
		back_button: "Back",
		errors: {
			email_required: "Email is required",
			invalid_email: "Invalid email",
		},
		emailConfirmation: {
			title: "Reset Link Sent",
			description:
				"An email containing a password reset link has been dispatched to you. Kindly utilize the provided link to initiate the password reset process.",
			back_button: "Go Back to Login",
		},
	},
	dashboard: {
		dashboard: "Dashboard",
		import_p6: "Import via P6",
		export_p6: "Export to P6",
		welcome_to: "Welcome to",
		total_project_count: "Total Count Of Project",
		company: "Company",
		upload_file: "Upload file",
		file_uploaded_successfully: "File uploaded successfully",
		file_upload_failed: "File upload failed",
		errors: {
			file_required: "Please select a file to upload",
			company_required: "Please select company",
		},
	},
	logs: {
		logs: "Logs",
		company: "Company",
		user: "User",
		fetch_button: "Fetch Details",
		search: "Search",
		search_text: "Sample Company Pvt Ltd.",
	},
	feedback: {
		title: "Feedback/Suggestion",
		type: "Type",
		message: "Message",
		submit: "Submit",
		send_reply: "Send Reply via Email",
		errors: {
			type_required: "Please select type",
			message_required: "Message is required",
		},
		user_name: "User Name",
		company: "Company",
		status: "Status",
		email: "Email",
		submission_date: "Submission Date",
		post_reply: "Post a Reply",
	},
	file_upload: {
		no_file: "Choose a file to upload",
		choose_file: "Choose File",
	},
	profilePage: {
		errors: {
			not_found: "User not found",
		},
	},
	activity: {
		activities: "Activities",
		add_an_activity: "Add an Activity",
		cancel: "Cancel",
		save: "Save",
		add_activity: "Add Activity",
		edit_activity: "Edit Activity",
		delete_activity: "Delete Activity",
		general_details: "General Details",
		activity_id: "Activity ID",
		activity_type: "Activity Type",
		activity_name: "Activity Name",
		wbs_code: "WBS Code",
		wbs_name: "WBS Name",
		assignee: "Assignee",
		activity: "Activity",
		search_here: "Search here",
		date_and_duration: "Date & Duration",
		activity_status: "Activity Status",
		view_previous_proposal: "View Previous Proposals",
		activity_team_tracker: "Activity Team Tracker",
		activity_reviewer_screen: "Activity Reviewer Screen",
		view_proposal_logs: "View Proposal Logs",
		proposal_logs: "Proposal Logs",
		search_by_activity_code: "Search by Activity Code",
		planned_start_date: "Planned Start Date",
		planned_finished_date: "Planned Finished Date",
		actual_start_date: "Actual Start Date",
		actual_finished_date: "Actual Finished Date",
		proposed_planned_start_date: "Proposed Planned Start Date",
		proposed_planned_finished_date: "Proposed Planned Finished Date",
		proposed_actual_start_date: "Proposed Actual Start Date",
		proposed_actual_finished_date: "Proposed Actual Finished Date",
		proposed_status: "Proposed Status",
		original_duration: "Original Duration (Days)",
		at_completion_durations: "At Completion Duration (days)",
		days_of_operation: "Days of Operations",
		start_time: "Start Time",
		end_time: "End Time",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
		type: "Type",
		add_a_trade: "Add a Trade",
		activity_code: "Activity Code",
		select_type: "Please select a type",
		predecessor_details: "Predecessor Details",
		successors_details: "Successors Details",
		relation: "Relation",
		lag_days: "Lag Days",
		successor_details: "Successor Details",
		other_details: "Other Details",
		activity_notes: "Activity Notes",
		activity_resources: "Activity Resources",
		activity_cost: "Activity Cost",
		no_data: "No data available!",
		activity_created_successfully: "Activity created successfully",
		activity_creation_error: "Error while creating activity",
		activity_not_found: "Activity not found",
		activity_updated_successfully: "Activity updated successfully",
		activity_update_error: "Error while updating activity",
		status_updated_success: "Activity status updated successfully",
		status_updated_error: "Error while updating activity status",
		errors: {
			activity_type_required: "Activity Type is required",
			activity_name_required: "Activity Name is required",
			wbs_code_required: "WBS Code is required",
			wbs_name_required: "WBS Name is required",
			planned_start_date_required: "Planned Start Date is required",
			planned_finish_date_required: "Planned Finish Date is required",
			planned_finish_date_less_than_start:
				"Planned Finish Date should be greater than Planned Start Date",
			original_duration_required: "Original Duration is required",
			calendar_working_days_required: "Days of operations are required",
			predecessors_invalid: "Please select relation for selected predecessors",
		},
	},
	subActivity: {
		sub_activities: "Sub-Activities",
		add_sub_activity: "Add a Sub Activity",
		cancel: "Cancel",
		save: "Save",
		edit_sub_activity: "Edit Sub Activity",
		delete_sub_activity: "Delete Sub Activity",
		activity: "Activity",
		general_details: "General Details",
		sub_activity_id: "Sub Activity ID",
		sub_activity_name: "Sub Activity Name",
		assignee: "Assignee",
		type: "Type",
		sub_activity_code: "Sub Activity Code",
		select_type: "Please select a type",
		description: "Sub Activity Description",
		date_duration: "Date & Duration",
		planned_start_date: "Planned Start Date",
		planned_end_date: "Planned End Date",
		plan_duration: "Planned Duration",
		actual_duration: "Actual Duration",
		actual_start_date: "Actual Start Date",
		actual_end_date: "Actual End Date",
		notes: "Notes",
		trade: "Trade is ",
		errors: {
			activity: "Activity is required.",
			sub_activity_name: "Sub Activity name is required.",
			description: "Sub Activity description is required.",
			planned_start_date: "Planned Start date is required.",
			planned_end_date: "Planned End date is required.",
			planned_duration: "Planned duration is required.",
			actual_start_date: "Actual Start date is required.",
			actual_end_date: "Actual End date is required.",
			actual_duration: "Actual Duration is required.",
			trade_responsible: "Trade Responsible is required.",
		},
	},
}

import { Box, Grid2, Menu, MenuItem } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import AppButton from "../../components/AppButton/AppButton"
import { useTranslation } from "react-i18next"
import ActivityGeneralDetailsForm from "./ActivityGeneralDetailsForm"
import { format as dateFnsFormat } from "date-fns"
import ActivityDateTime from "./ActivityDateTime"
import ActivityDays from "./ActivityDays"
import ActivityRelation from "./ActivityRelation"
import ActivitySuccessor from "./ActivitySuccessors"
import ActivityOtherDetails from "./ActivityOtherDetails"
import { useFormik } from "formik"
import {
	AddActivityInitialValue,
	AddActivityValidationSchema,
	IActivity,
} from "./validation"
import {
	useGetAllSubActivitiesByActivityQuery,
	useGetSessionIdQuery,
	useLazyGetActivityByIdQuery,
	useUpdateActivityMutation,
} from "../../api/network/projectApiService"
import { ActivityDetail, UpdateActivityPayload } from "../../api/types/Project"
import { toast } from "react-toastify"
import { routeUrls } from "../../routes/routeUrls"
import { Svgs } from "../../assets/svgs"
import SubActivitiesList from "./SubActivitiesList"
import AppLoader from "../../components/AppLoader/AppLoader"
import {
	activityStatusArray,
	getActivityStatusColor,
	getActivityStatusLabel,
} from "../../utils"
import { ExpandMoreOutlined } from "@mui/icons-material"
import ActivityTrade from "./ActivityTrade"

export default function ActivityDetailsPage() {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const [isEditing, setIsEditing] = useState(false)
	const [assigneeOptions, setAssigneeOptions] = useState<any>()
	const [activityCodeOptions, setActivityCodeOptions] = useState<any>()
	const [prjId, setPrjId] = useState<any>()

	const navigate = useNavigate()
	const location = useLocation()
	// console.log("Location", location?.state?.session)
	const { id: id = "" } = useParams()
	const { data: subActivities, refetch } =
		useGetAllSubActivitiesByActivityQuery(id)
	const [activityData, setActivityData] = useState<ActivityDetail | null>()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const activityStatusMenuOpen = Boolean(anchorEl)

	const [getActivityByIdApiTrigger, { isFetching: isFetchingActivity }] =
		useLazyGetActivityByIdQuery()

	const [updateActivityApiTrigger, { isLoading: isSavingActivity }] =
		useUpdateActivityMutation()
	const { data } = useGetSessionIdQuery(prjId, { skip: !prjId })
	const projectId = prjId

	const fetchActivity = () => {
		getActivityByIdApiTrigger(id)
			.unwrap()
			.then((res) => {
				setPrjId(res?.project)
				setActivityData(res)
				res.assignee.map((a: any) => { setAssigneeOptions(res?.assignee) })
				res.activity_codes.map((a: any) => {
					setActivityCodeOptions(res?.activity_codes?.map((a: any) => a) || [])
				})
				setValues({
					id: res.id,
					activity_id: res.activity_id,
					activity_type: res.activity_type,
					activity_status: res.activity_status,
					activity_name: res.activity_name,
					trade_responsible: res.trade_responsible,
					wbs_code: res.wbs_code,
					wbs_name: res.wbs_name,
					planned_start_date: res.planned_start_date,
					planned_finish_date: res.planned_finish_date,
					actual_start_date: res.actual_start_date,
					actual_finish_date: res.actual_finish_date,
					original_duration: res.original_duration,
					at_completion_duration: res.at_completion_duration,
					assignee: res.assignee.map((a) => a.id),
					activity_codes: res.activity_codes.map((a) => a.id),
					predecessors: res.predecessors,
					successors: res.successors,
					activity_notes: res.notes,
					activity_resources: res.resources,
					activity_cost: res.costs,
					calendar_working_days: res.calendar?.working_days,
					notes: res.notes,
				})
			})
			.catch((err) => {
				console.log("Activity error", err)
				if (err.status === 404) {
					toast.error(t("activity_not_found"))
					navigate(routeUrls.activity.list)
				}
			})
	}

	useEffect(() => {
		fetchActivity()
	}, [id])

	function hasDateChanged(
		originalDate: string | null | undefined,
		newDate: string | null | undefined,
	): boolean {
		const formattedOriginalDate = dateFnsFormat(
			new Date(originalDate ?? ""),
			"yyyy-MM-dd",
		)
		const formattedNewDate = dateFnsFormat(
			new Date(newDate ?? ""),
			"yyyy-MM-dd",
		)
		return formattedOriginalDate !== formattedNewDate
	}

	function compareAndTransformArrays(
		newArray: any,
		existingArray: any,
	): boolean {
		const isDifferent =
			newArray.length !== existingArray?.length ||
			!newArray.every((item: any) => {
				const correspondingItem = existingArray?.find(
					(e: any) => e.id === item.id,
				)
				return (
					correspondingItem &&
					correspondingItem.relationship_type === item.relationship_type &&
					(correspondingItem.lag_days || 0) === (item.lag_days || 0)
				)
			})
		return isDifferent
	}

	const handleUpdateActivity = (_value: IActivity) => {
		const activityPayload: UpdateActivityPayload = {
			id: String(id),
			session: data?.id || location?.state?.session,
			...(_value.activity_name !== activityData?.activity_name && {
				activity_name: _value.activity_name,
			}),
			...(_value.activity_type !== activityData?.activity_type && {
				activity_type: _value.activity_type,
			}),
			...(_value.assignee.join(",") !==
				(activityData?.assignee
					? activityData.assignee.map((a) => a.id).join(",")
					: "") && {
				assignee: _value.assignee,
			}),
			...(_value.wbs_code !== activityData?.wbs_code && {
				wbs_code: _value.wbs_code,
			}),
			...(_value.wbs_name !== activityData?.wbs_name && {
				wbs_name: _value.wbs_name,
			}),
			...(_value.notes !== activityData?.notes && {
				notes: _value.activity_notes,
			}),
			...(hasDateChanged(
				activityData?.planned_start_date,
				_value.planned_start_date,
			) && {
				planned_start_date: `${dateFnsFormat(new Date(_value.planned_start_date ?? ""), "yyyy-MM-dd")} 08:00:00`,
			}),
			...(hasDateChanged(
				activityData?.planned_finish_date,
				_value.planned_finish_date,
			) && {
				planned_finish_date: `${dateFnsFormat(new Date(_value.planned_finish_date ?? ""), "yyyy-MM-dd")} 08:00:00`,
			}),
		}
		if (
			activityData?.actual_start_date &&
			_value.actual_start_date &&
			hasDateChanged(activityData?.actual_start_date, _value.actual_start_date)
		) {
			activityPayload.actual_start_date = dateFnsFormat(
				new Date(_value.actual_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (
			activityData?.actual_finish_date &&
			_value.actual_finish_date &&
			hasDateChanged(
				activityData?.actual_finish_date,
				_value.actual_finish_date,
			)
		) {
			activityPayload.actual_finish_date = dateFnsFormat(
				new Date(_value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (
			compareAndTransformArrays(_value.predecessors, activityData?.predecessors)
		) {
			activityPayload.predecessors = _value.predecessors.map((predecessor) => ({
				predecessor: predecessor.id,
				relationship_type: predecessor.relationship_type,
				lag_days: predecessor.lag_days || 0,
			}))
		}
		if (
			compareAndTransformArrays(_value.successors, activityData?.successors)
		) {
			activityPayload.successors = _value.successors.map((successors) => {
				return {
					successor: successors.id,
					relationship_type: successors.relationship_type,
					lag_days: successors.lag_days || 0,
				}
			})
		}

		updateActivityApiTrigger(activityPayload)
			.unwrap()
			.then((res) => {
				console.log("Activity updated", res)
				toast.success(t("activity_updated_successfully"))
				setIsEditing(false)
			})
			.catch((err) => {
				console.log("Activity update error", err)
				toast.error(t("activity_update_error"))
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		setValues,
	} = useFormik({
		initialValues: {
			id: Number(id || 0),
			activity_id: "",
			activity_status: "",
			...AddActivityInitialValue,
		},
		onSubmit: handleUpdateActivity,
		validationSchema: AddActivityValidationSchema,
	})

	const handleEdit = () => {
		setIsEditing(true)
	}

	const cancelEdit = () => {
		setIsEditing(false)
	}

	const handleBack = () => {
		const fromActivityList = location.state?.fromActivityList ?? true
		if (fromActivityList) {
			navigate(routeUrls.activity.list, { state: { projectId } })
		} else {
			navigate(-1)
		}
	}

	const activityStatusList = useMemo(() => {
		return activityStatusArray.filter(
			(status) => status.status_code !== values.activity_status,
		)
	}, [values.activity_status])

	const handleActivityStatusMenuOpen = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setAnchorEl(event.currentTarget)
	}
	const handleActivityStatusMenuClose = () => {
		setAnchorEl(null)
	}

	const handleActivityStatusMenuChange = (status: string) => () => {
		const _value = values
		const activityPayload: UpdateActivityPayload = {
			id: String(id),
			// project: projectId,
			activity_type: _value.activity_type,
			activity_name: _value.activity_name,
			wbs_code: _value.wbs_code,
			wbs_name: _value.wbs_name,
			planned_start_date: `${dateFnsFormat(
				new Date(_value.planned_start_date ?? ""),
				"yyyy-MM-dd",
			)} 08:00:00`,
			planned_finish_date: `${dateFnsFormat(
				new Date(_value.planned_finish_date ?? ""),
				"yyyy-MM-dd",
			)} 17:00:00`,
			original_duration: _value.original_duration,
			at_completion_duration: _value.at_completion_duration,
			assignee: _value.assignee,
			session: data?.id || location?.state?.session,
			calendar: {
				working_days: _value.calendar_working_days,
			},
			predecessors: [],
			notes: _value.activity_notes,
		}
		if (_value.actual_start_date) {
			activityPayload.actual_start_date = dateFnsFormat(
				new Date(_value.actual_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.actual_finish_date) {
			activityPayload.actual_finish_date = dateFnsFormat(
				new Date(_value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.predecessors.length > 0) {
			activityPayload.predecessors = _value.predecessors.map((predecessor) => {
				return {
					predecessor: predecessor.id,
					relationship_type: predecessor.relationship_type,
					lag_days: predecessor.lag_days || 0,
				}
			})
		}
		if (_value.successors.length > 0) {
			activityPayload.successors = _value.successors.map((successors) => {
				return {
					successor: successors.id,
					relationship_type: successors.relationship_type,
					lag_days: successors.lag_days || 0,
				}
			})
		}
		updateActivityApiTrigger({
			...activityPayload,
			activity_status: status,
		})
			.unwrap()
			.then(() => {
				toast.success(t("status_updated_success"))
				setFieldValue("activity_status", status)
			})
			.catch((err) => {
				console.log("Error while updating Activity status", err)
				toast.error(t("status_updated_error"))
			})
		handleActivityStatusMenuClose()
	}

	const renderActivityStatusDropdown = () => {
		const _color = getActivityStatusColor(values?.activity_status)
		return (
			<>
				<AppButton
					onClick={handleActivityStatusMenuOpen}
					variant="text"
					endIcon={<ExpandMoreOutlined />}
					sx={{
						color: _color,
					}}
					startIcon={<Svgs.ProjectStatusLogo color={_color} />}
				>
					{getActivityStatusLabel(values.activity_status)}
				</AppButton>
				<Menu
					anchorEl={anchorEl}
					open={activityStatusMenuOpen}
					onClose={handleActivityStatusMenuClose}
				>
					{activityStatusList.map((m) => (
						<MenuItem
							key={m.status_code}
							onClick={handleActivityStatusMenuChange(m.status_code)}
						>
							{m.status}
						</MenuItem>
					))}
				</Menu>
			</>
		)
	}

	const headerButtons = useMemo(() => {
		return (
			<>
				<AppButton
					sx={{
						display: isEditing ? "block" : "none",
					}}
					btnSize="medium"
					color="error"
					variant="outlined"
					disabled={isSavingActivity}
					onClick={cancelEdit}
				>
					{t("cancel")}
				</AppButton>
				<AppButton
					sx={{
						display: isEditing ? "block" : "none",
						color: "white",
					}}
					btnSize="medium"
					disabled={isSavingActivity}
					type="submit"
					color="success"
				>
					{t("save")}
				</AppButton>
				<AppButton
					sx={{
						display: isEditing ? "none" : "block",
					}}
					btnSize="medium"
					disabled={isSavingActivity}
					onClick={handleEdit}
				>
					{t("edit_activity")}
				</AppButton>
			</>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing, isSavingActivity])

	if (isFetchingActivity || !activityData) {
		return <AppLoader open />
	}

	return (
		<Box className="activity-form-container">
			<form onSubmit={handleSubmit}>
				<DetailNavBar
					title={"Excavation of Building Site"}
					showBackButton={true}
					// onBackPress={() => navigate(routeUrls.activity.list, { state: { projectId: projectId, session: data?.id || location?.state?.session } })
					// }
					onBackPress={handleBack}
					rightView={
						<Grid2 display={"flex"} gap={2}>
							{renderActivityStatusDropdown()}
							{headerButtons}
						</Grid2>
					}
				/>
				<ActivityGeneralDetailsForm
					projectId={activityData?.project}
					values={values}
					errors={errors}
					disabled={!isEditing}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					mode="edit"
				/>
				<ActivityDateTime
					values={values}
					errors={errors}
					disabled={!isEditing}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					mode={isEditing ? "edit" : "view"}
				/>
				{/* <ActivityDays
					values={values}
					errors={errors}
					touched={touched}
					disabled={!isEditing}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/> */}
				<ActivityTrade
					values={values}
					projectId={projectId}
					disabled={true}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					errors={errors}
					mode="view"
				/>
				<ActivityRelation
					values={values}
					errors={errors}
					touched={touched}
					disabled={!isEditing}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					currentActivityId={Number(id)}
				/>
				<ActivitySuccessor
					values={values}
					errors={errors}
					touched={touched}
					disabled={!isEditing}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					currentActivityId={Number(id)}
				/>
				<ActivityOtherDetails
					values={values}
					errors={errors}
					disabled={!isEditing}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/>
				<SubActivitiesList
					assigneeOptions={assigneeOptions}
					session={data?.id || location?.state?.session}
					projectId={projectId}
					subActivities={subActivities?.results || []}
					refetch={refetch}
				/>
			</form>
		</Box>
	)
}

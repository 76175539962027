import { format, parse } from "date-fns"
import moment from "moment"

/**
 * Method to format date in "DD MMM YYYY" format
 * @param date Date in string format
 * @returns formatted date in "DD MMM YYYY" format
 */
export const getDateFromTimestamp = (
	date: string,
	dateTimeFormat = "dd MMM yyyy",
) => {
	if (!date) return ""
	return format(new Date(date), dateTimeFormat)
}

export const formatDateWithMonthName = (date: string) => {
	const dt = new Date(date)
	if (dt.toString() === "Invalid Date") return date

	return moment(dt).format("MMM DD, yyyy")
}

export const formatToDate = (date: string) => {
	// const dt = moment(date)
	// if (dt.toString() === "Invalid Date") return date

	// return moment(dt)
	const dt = new Date(date)
	if (dt.toString() === "Invalid Date") return date

	return format(dt, "yyyy-MM-dd")
}

export const formatToDateInput = (date: string | null) => {
	if (!date) {
		return format(new Date(), "yyyy-MM-dd")
	}
	const parsedDate = parse(date, "dd-MM-yyyy", new Date())
	if (isNaN(parsedDate.getTime())) {
		return format(new Date(), "yyyy-MM-dd")
	}
	return format(parsedDate, "yyyy-MM-dd")
}

import { Grid2, Modal, } from "@mui/material"
import { useState } from "react"
import AppButton from "../../../components/AppButton/AppButton"
import { useLazyChangeUserStatusQuery } from "../../../api/network/userApiService"
import "./DeactivateUser.scss"
import { Svgs } from "../../../assets/svgs"

interface DeactivateUserProps {
	handleUserStatus: () => void,
	userStatus: boolean,
	role_type: string
}

const DeactivateUser = ({ handleUserStatus, userStatus, role_type }: DeactivateUserProps) => {
	console.log("userStatus", userStatus)
	const [isOpen, setIsOpen] = useState(false)

	const [changeUserStatusApiTrigger] = useLazyChangeUserStatusQuery()

	const handleOpenModal = () => {
		setIsOpen(true)
	}

	const handleCloseModal = () => {
		setIsOpen(false)
	}

	const handleUserStatusModal = () => {
		handleUserStatus()
		handleCloseModal()
		// Logic to deactivate the user goes here
		// changeUserStatusApiTrigger({ is_active: true })
		// 	.unwrap()
		// 	.then((response) => {
		// 		console.log("response", response)
		// 		toast.success("User deactivated successfully")
		// 		handleCloseModal()
		// 	})
		// 	.catch((error) => {
		// 		toast.error("Error while deactivating user")
		// 	})
	}

	return (
		<>
			<Grid2 display={'flex'} justifyContent={'end'}
				marginTop={-4} marginBottom={-1}>
				<AppButton
					onClick={handleOpenModal}
					btnSize="medium"
					color="primary"
					sx={{ background: userStatus ? "#e72222" : '#54AE2A' }}
					variant="contained">
					{`${userStatus ? `Deactivate ${role_type}` : `Activate ${role_type}`}`}
				</AppButton>
			</Grid2>
			<Modal open={isOpen} onClose={handleCloseModal}>
				<div className="modal-container">
					{userStatus ?
						<div className="header-wrapper">
							<Svgs.Deactivate className="icon" />
							<div className="title">{`Deactivate ${role_type} ?`}</div>
						</div>
						: <div className="header-wrapper">
							<Svgs.ActiveUserIcon className="icon" />
							<div className="title" style={{ color: '#54AE2A' }}>{`Activate ${role_type} ?`}</div>
						</div>
					}
					<div className="sub-title">
						{`Are you sure you want to ${!userStatus ? 'activate' : 'deactivate'} this ${role_type}?`}
					</div>
					<Grid2 display={'flex'} justifyContent={'center'}>
						<AppButton
							onClick={handleCloseModal}
							btnSize="medium"
							variant="outlined"
							color="error"
							sx={{ margin: 2 }}
						>
							Cancel
						</AppButton>
						<AppButton
							onClick={handleUserStatusModal}
							// className="deactivate-button"
							btnSize="medium"
							sx={{ margin: 2, color: 'white', background: !userStatus ? '#54AE2A' : '#e72222' }}
							variant="contained"
						// color={userStatus?'success':'error'}
						>
							{userStatus ? 'Deactivate' : 'Activate'}
						</AppButton>

					</Grid2>
				</div>
			</Modal>
		</>
	)
}

export default DeactivateUser

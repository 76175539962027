import { useState } from "react"
import Typography from "@mui/material/Typography"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import "./ProfileSetup.scss"
import {
	initialProfileSetupValues,
	IProfileSetupFields,
	profileSetupValidationSchema,
	TProfileInformationType,
} from "./validation"
import AppFormField from "../../components/AppFormField/AppFormField"
import AppButton from "../../components/AppButton/AppButton"
import { useLocation, useNavigate } from "react-router-dom"
import { Grid2 } from "@mui/material"
import { useProfileSetupMutation } from "../../api/network/userApiService"
import { toast } from "react-toastify"
const ProfileSetupPage = () => {
	const [currentSection, setCurrentSection] =
		useState<TProfileInformationType>("personal")
	const [profilesetUpTrigger] = useProfileSetupMutation()
	const { t } = useTranslation("translation", {
		keyPrefix: "profileSetupPage",
	})
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "profileSetupPage.errors",
	})
	const location = useLocation()
	const nav = useNavigate()
	const { userData } = location.state || {}
	const handleProfileSetupSubmit = (_values: IProfileSetupFields) => {
		if (_values.phone_number === "") {
			toast.error("Please enter phone number")
			return
		} else if (_values.onsite_emergency_contact === "") {
			toast.error("Please enter onsite emergency contact")
			return
		} else {
			profilesetUpTrigger(_values)
				.unwrap()
				.then((response) => {
					toast.success("Profile setup successfully")
					nav("/profile")
				})
				.catch((error) => {
					toast.error(error.data?.detail || "Something went wrong")
				})
		}
	}

	const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
		useFormik({
			initialValues: {
				...initialProfileSetupValues, // use initial values from validation file
				name: userData?.user?.name || initialProfileSetupValues.name,
				email: userData?.user?.email || initialProfileSetupValues.email,
				phone_number:
					userData?.user?.phone_number ||
					initialProfileSetupValues.phone_number,
				company:
					userData?.user?.company_name || initialProfileSetupValues.company,
				office_phone_number:
					userData?.user?.office_phone_number ||
					initialProfileSetupValues.office_phone_number,
				onsite_emergency_contact:
					userData?.user?.onsite_emergency_contact ||
					initialProfileSetupValues.onsite_emergency_contact,
				offsite_emergency_contact_number:
					userData?.user?.offsite_emergency_contact_number ||
					initialProfileSetupValues.offsite_emergency_contact_number,
			},
			onSubmit: handleProfileSetupSubmit,
			validationSchema: profileSetupValidationSchema,
		})

	const renderProfileSetupForm = () => {
		switch (currentSection) {
			case "professional":
				return (
					<div className="form-sub-section">
						<Typography className="form-subtitle">
							{t("professional_information")}
						</Typography>
						<div className="form-fields-section">
							{/* <AppFormField
								label={t("job_title")}
								name="jobTitle"
								placeholder={""}
								value={values.jobTitle}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.jobTitle}
								errorMsg={errors.jobTitle ? t(errors.jobTitle) : ""}
								inputProps={{
									disabled: true,
								}}
							/> */}
							<AppFormField
								label={t("company_name")}
								isRequired
								name="company"
								placeholder=""
								value={values.company}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={!!touched.company}
								errorMsg={errors.company ? tErrors("error_company_name") : ""}
								inputProps={{
									disabled: true,
								}}
							/>
							<AppFormField
								label={t("office_phone_number")}
								name="office_phone_number"
								type="text"
								placeholder={t("enter_the_phone_number")}
								value={values.office_phone_number}
								onChange={(e) => {
									const value = e.target.value
									if (/[^0-9]/.test(value)) {
										const numericValue = value.replace(/[^0-9]/g, "")
										handleChange({
											target: {
												name: "office_phone_number",
												value: numericValue,
											},
										})
									} else {
										handleChange(e)
									}
								}}
								onBlur={handleBlur}
								touched={!!touched.office_phone_number}
								errorMsg={
									typeof errors.office_phone_number === "string"
										? errors.office_phone_number
										: ""
								}
							/>
							<AppFormField
								label={t("onsite_emergency_contact_number")}
								isRequired
								name="onsite_emergency_contact"
								placeholder={t("enter_the_phone_number")}
								type="text"
								value={values.onsite_emergency_contact}
								// onChange={handleChange}
								onChange={(e) => {
									const value = e.target.value
									if (/[^0-9]/.test(value)) {
										const numericValue = value.replace(/[^0-9]/g, "")
										handleChange({
											target: {
												name: "onsite_emergency_contact",
												value: numericValue,
											},
										})
									} else {
										handleChange(e)
									}
								}}
								onBlur={handleBlur}
								touched={!!touched.onsite_emergency_contact}
								errorMsg={
									typeof errors.onsite_emergency_contact === "string"
										? errors.onsite_emergency_contact
										: ""
								}
							/>
							<AppFormField
								label={t("offsite_emergency_contact_number")}
								name="offsite_emergency_contact_number"
								placeholder={t("enter_the_phone_number")}
								type="text"
								value={values.offsite_emergency_contact_number}
								onChange={(e) => {
									const value = e.target.value
									if (/[^0-9]/.test(value)) {
										const numericValue = value.replace(/[^0-9]/g, "")
										handleChange({
											target: {
												name: "offsite_emergency_contact_number",
												value: numericValue,
											},
										})
									} else {
										handleChange(e)
									}
								}}
								onBlur={handleBlur}
								touched={!!touched.offsite_emergency_contact_number}
								errorMsg={
									typeof errors.offsite_emergency_contact_number === "string"
										? errors.offsite_emergency_contact_number
										: ""
								}
							/>
						</div>
					</div>
				)
			case "personal":
			default:
				return (
					<div className="form-sub-section">
						<Typography className="form-subtitle">
							{t("personal_information")}
						</Typography>
						<div className="form-fields-section">
							<AppFormField
								label={t("name")}
								isRequired
								name="name"
								placeholder={t("enter_name")}
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={!!touched.name}
								errorMsg={
									typeof errors.name === "string"
										? tErrors("name_required")
										: ""
								}
							/>
							<AppFormField
								label={t("email")}
								name="email"
								type="email"
								placeholder=""
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={!!touched.email}
								errorMsg={
									typeof errors.email === "string" ? tErrors("error_email") : ""
								}
								inputProps={{
									disabled: true,
								}}
							/>
							<AppFormField
								label={t("phone_number")}
								isRequired
								name="phone_number"
								type="text"
								placeholder={t("enter_phone_number")}
								value={values.phone_number}
								// onChange={handleChange}
								// onChange={(e) => {
								// 	const value = e.target.value
								// 	if (!/[eE]/.test(value)) {
								// 		handleChange(e)
								// 	}
								// }}
								onChange={(e) => {
									const value = e.target.value
									if (/[^0-9]/.test(value)) {
										const numericValue = value.replace(/[^0-9]/g, "")
										handleChange({
											target: { name: "phone_number", value: numericValue },
										})
									} else {
										handleChange(e)
									}
								}}
								onBlur={handleBlur}
								touched={!!touched.phone_number}
								errorMsg={
									typeof errors.phone_number === "string"
										? errors.phone_number
										: ""
								}
								// inputProps={{
								// 	onKeyDown: (e) => {
								// 		if (["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(e.key)) {
								// 			e.preventDefault();
								// 		}
								// 	},
								// 	onWheel: (e) => e.preventDefault(), // Prevent mouse wheel scroll
								// }}
							/>
						</div>
					</div>
				)
		}
	}

	const handleNextClick = () => {
		if (values.phone_number === "") {
			toast.error("Please enter phone number")
		} else {
			setCurrentSection("professional")
		}
	}

	const renderProceedButton = () => {
		return (
			<Grid2 display="flex" justifyContent={"space-between"}>
				<AppButton
					btnSize="large"
					type="submit"
					style={{
						display: `${currentSection === "personal" ? "none" : "block"}`,
					}}
					onClick={() => handleProfileSetupSubmit(values)}
				>
					{t("proceed_to_dashboard")}
				</AppButton>
				<AppButton
					btnSize="large"
					style={{
						width: 207,
						display: `${currentSection === "professional" ? "none" : "block"}`,
					}}
					type="button"
					onClick={handleNextClick}
				>
					{t("next")}
				</AppButton>
			</Grid2>
		)
	}

	return (
		<AuthLayout>
			<form onSubmit={handleSubmit} className="profile-setup-form-container">
				<div className="form-title-wrapper">
					<Typography className="form-title">
						{`${t("welcome")}, ${values.name}!`}
					</Typography>
					<Typography className="form-description">
						{t("description")}
					</Typography>
				</div>
				{renderProfileSetupForm()}
				<div className="action-btn-wrapper">{renderProceedButton()}</div>
			</form>
		</AuthLayout>
	)
}

export default ProfileSetupPage

import moment from "moment"
import { ActivityDetail } from "../../api/types/Project"
import { Group, Item, Lines } from "./SwimlaneData"

export const getSwimlaneItemsFromActivity = (
	activityArr: ActivityDetail[],
	groupArr: Group[],
	isEditable: boolean,
): Item[] => {
	const arr = activityArr.map((activity, index) => {
		const groupIdForActivity = getGroupIdFromGroupArr(groupArr, activity.id)
		return {
			id: activity.id,
			task_code: activity?.task_code,
			group: groupIdForActivity,
			activity_name: activity.activity_name,
			start_time: activity.actual_start_date
				? new Date(
						moment(activity.actual_start_date, "YYYY-MM-DDTHH:mm:ssZ").format(
							"YYYY-MM-DDT00:00:00Z",
						),
					)
				: new Date(
						moment(activity.planned_start_date, "YYYY-MM-DDTHH:mm:ssZ").format(
							"YYYY-MM-DDT00:00:00Z",
						),
					),
			end_time: activity.actual_finish_date
				? new Date(
						moment(activity.actual_finish_date, "YYYY-MM-DDTHH:mm:ssZ").format(
							"YYYY-MM-DDT24:00:00Z",
						),
					)
				: new Date(
						moment(activity.planned_finish_date, "YYYY-MM-DDTHH:mm:ssZ").format(
							"YYYY-MM-DDT24:00:00Z",
						),
					),
			type: activity.activity_codes
				?.map((codeObj) => codeObj.actv_code_name)
				.join(", "),
			// type: activity.trade_responsible,
			// status: getStatusByActivityDates(activity),
			status: activity.activity_status,
			stackItems: false,
			is_editable:
				isEditable &&
				activity.is_editable &&
				(!activity.actual_start_date || !activity.actual_finish_date),
			total_float: activity.total_float,
			activity_id: activity.activity_id,
			canResize: canResizeActivity(activity, isEditable),
			canMove: canMoveActivity(activity, isEditable),
			actual_start_date: activity.actual_start_date ?? undefined,
			actual_finish_date: activity.actual_finish_date ?? undefined,
			planned_start_date: activity.planned_start_date || undefined,
			planned_finish_date: activity.planned_finish_date || undefined,
			previous_session_finish_date:
				activity.previous_session_finish_date || undefined,
			previous_session_start_date:
				activity.previous_session_start_date || undefined,
			original_duration: activity.original_duration,
			at_completion_duration: activity.at_completion_duration,
			notes: activity.notes,
			progress: activity.progress,
			project: activity.project,
			assignee: activity.assignee,
			lines: getPredecessorLinesFromActivity(activity, activityArr),
			details: activity,
			is_marked_red: activity.is_marked_red,
		}
	})
	return arr
}

/**
 * MEthod to determine wether activity can be resized/dragged or not
 * If the session is editable and the activity is not started or finished, then it can be edited
 * @param activity Activity details
 * @param isEditable Is swimlane editable
 */
const canResizeActivity = (
	activity: ActivityDetail,
	isEditable: boolean,
): "left" | "right" | "both" | false => {
	if (
		isEditable &&
		!activity.actual_start_date &&
		!activity.actual_finish_date
	) {
		return "both"
	} else if (isEditable && !activity.actual_start_date) {
		return "both"
	} else if (isEditable && !activity.actual_finish_date) {
		return "right"
	}
	return false
}

/**
 *
 * @param activity Activity details
 * @param isEditable Is swimlane editable
 * @returns
 */
const canMoveActivity = (
	activity: ActivityDetail,
	isEditable: boolean,
): boolean => {
	if (
		isEditable &&
		!activity.actual_start_date &&
		!activity.actual_finish_date
	) {
		return true
	}
	return false
}

/**
 *
 * @param activity Method to check if activity is delayed or leading or on time
 */
const getStatusByActivityDates = (
	activity: ActivityDetail,
): string | undefined => {
	const actualStartDate = moment(activity.actual_start_date)
	const actualFinishDate = moment(activity.actual_finish_date)
	const plannedStartDate = moment(activity.planned_start_date)
	const plannedFinishDate = moment(activity.planned_finish_date)

	if (actualStartDate.isBefore(plannedStartDate)) {
		return "early"
	} else if (
		actualStartDate.isAfter(plannedStartDate) ||
		actualFinishDate.isAfter(plannedFinishDate)
	) {
		return "delayed"
	} else if (actualStartDate.isSame(plannedStartDate)) {
		return "on-time"
	}
}

const getPredecessorLinesFromActivity = (
	activity: ActivityDetail,
	activityArray: ActivityDetail[],
) => {
	const lines: Lines[] = []
	activity?.predecessors?.forEach((predecessor) => {
		const predecessorActivity = activityArray.find(
			(act) => act.id === predecessor.id,
		)
		if (predecessorActivity) {
			lines.push({
				id: `${predecessorActivity.id}`,
				linkedTo: `${activity.id}`,
				linkType: predecessor.relationship_type,
			})
			// lines.push({
			// 	id: `${predecessorActivity.id}`,
			// 	linkedTo: `${activity.id}`,
			// 	linkType: predecessor.relationship_type,
			// })
		}
	})
	return lines
}

/**
 * Function to create groups based on number of activities.
 *
 */

export const getGroupsFromActivties = (items: Item[]): Group[] =>
	items.map((item, index) => {
		return {
			id: Math.random(),
			title: item.activity_name,
			activityIds: [item.id],
			height: 55,
			stackItems: false,
		}
	})

// Method to create 100 empty group when no data is available
export const getEmptyDataGroups = (): Group[] => {
	const groups: Group[] = []
	for (let i = 0; i < 1; i++) {
		groups.push({
			id: i,
			title: "",
			activityIds: [],
			height: 55,
			stackItems: true,
		})
	}
	return groups
}

// export const getUniqueDateGroupsFromActivities = (
// 	items: ActivityDetail[],
// ): Group[] => {
// 	const groups: Group[] = []
// 	const dateMap: { [key: string]: number } = {}

// 	items.forEach((item) => {
// 		const startDate = moment(item.actual_start_date).format("YYYY-MM-DD")
// 		const endDate = moment(item.actual_finish_date).format("YYYY-MM-DD")

// 		let groupId = dateMap[startDate] || dateMap[endDate]

// 		if (groupId !== undefined) {
// 			// Check if the group already has an activity with the same start or end date
// 			const group = groups[groupId - 1]
// 			const hasCollidingDate = group?.activityIds?.some((activityId) => {
// 				const activity = items.find((act) => act.id === activityId)
// 				const activityStartDate = moment(activity?.actual_start_date).format(
// 					"YYYY-MM-DD",
// 				)
// 				const activityEndDate = moment(activity?.actual_finish_date).format(
// 					"YYYY-MM-DD",
// 				)
// 				// Start date and End date should not match. Also, actvity start date and end date shouyld lie between the start and end date.
// 				return (
// 					(activityStartDate === startDate && activityEndDate === endDate) ||
// 					(activityStartDate >= startDate && activityEndDate <= endDate)
// 				)
// 			})

// 			if (!hasCollidingDate) {
// 				group?.activityIds?.push(item.id)
// 			} else {
// 				groupId = -1
// 			}
// 		}

// 		if (groupId === -1 || groupId === undefined) {
// 			const newGroupId = groups.length + 1
// 			dateMap[startDate] = newGroupId
// 			dateMap[endDate] = newGroupId
// 			groups.push({
// 				id: newGroupId,
// 				title: moment(item.actual_start_date).format("YYYY-MM-DD"),
// 				activityIds: [item.id],
// 				height: 30,
// 				stackItems: true,
// 			})
// 		}
// 	})
// 	return groups
// }

const getGroupIdFromGroupArr = (groupArr: Group[], itemId: number): number => {
	const updatedArr = groupArr.filter((group) =>
		group.activityIds?.includes(itemId),
	)
	if (updatedArr.length > 0) {
		return updatedArr[0].id
	} else {
		return -1
	}
}

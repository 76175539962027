import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProposalDraft, ProposalListState } from "../types/Project"

const initialState: ProposalListState = {
	draftActivityProposal: [],
}

export const ProposalSlice = createSlice({
	name: "proposalSliceData",
	reducerPath: "proposalSliceData",
	initialState,
	reducers: {
		updateTeamProposalDrafts(
			state,
			action: PayloadAction<ProposalDraft[] | null>,
		) {
			state.draftActivityProposal = action.payload || []
			return state
		},
		clearProposals(state) {
			state.draftActivityProposal = []
			return state
		},
	},
})

// Export actions
export const { updateTeamProposalDrafts, clearProposals } =
	ProposalSlice.actions

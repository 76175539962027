import { enUS } from "@mui/x-date-pickers/locales"
import { createTheme, Theme } from "@mui/material"

export const AppColors = {
	primary: "#1a2945",
	secondary: "#22bce7",
	error: "#e72222",
	brgOrange: "#F57F32",
	brgTeal: "#00A79D",
	primaryGreen: "#54AE2A",
	yellowOrange: "#FDB913",
}

export const AppTheme: Theme = createTheme(
	{
		palette: {
			primary: {
				main: AppColors.primary,
			},
			secondary: {
				main: AppColors.secondary,
			},
			success: {
				main: AppColors.primaryGreen,
			},
		},
		typography: {
			fontFamily: "DIN",
			button: {
				textTransform: "none",
			},
		},
	},
	enUS,
)

import {
	Box,
	Grid2,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import "./SessionLogDetailsPage.scss"
import { useGetSessionDetailsQuery } from "../../api/network/projectApiService"
import { useParams } from "react-router-dom"
import { formatDateWithMonthName } from "../../utils/dateTime"
import AppPagination from "../../components/AppPagination/AppPagination"
import useAppPagination from "../../hooks/useAppPagination"
import { getTotalPages } from "../../utils"

export default function SessionLogDetailsPage() {
	const param = useParams()
	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()
	const { data, isFetching } = useGetSessionDetailsQuery({
		id: param?.id,
		page: currentPage,
		page_size: pageRowsCount,
	})
	const sessionDetails = data?.results || []
	const totalCount = data?.count || 0
	// const sessionDetails=results?.results || []
	// console.log("Session Details",results);

	function capitalizeFirstLetter(data: string): string {
		const formattedString = data.replace(/_/g, ' '); // Replace underscores with spaces
		return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
	}


	const tableHeader = [
		{ name: "Changed By", sortable: false },
		{ name: "New", sortable: false },
		{ name: "Original", sortable: false },
	]


	const handleBackClick = () => {
		console.log("Back Clicked")
		window.history.back()
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
			<Grid2 sx={{ flex: 1, overflow: "hidden" }} >
				<DetailNavBar title="Session Details" onBackPress={handleBackClick} />
			</Grid2>
			<Box className="session-details-modal">
				<TableContainer sx={{ maxHeight: "70vh", overflowY: "auto" }}>
					<Table
						stickyHeader
						sx={{ borderCollapse: "separate", borderSpacing: "0 6px" }}
					>
						<TableHead>
							<TableRow
								sx={{
									"& .MuiTableCell-root": {
										// padding: "12px",
										color: "#ffffff",
										fontWeight: "600",
										fontSize: "14px",
										backgroundColor: "#1B2944",
										textAlign: "center",
									},
									"& .MuiTableCell-root:first-of-type": {
										borderTopLeftRadius: "10px", // Adjust the value as needed
									},
									"& .MuiTableCell-root:last-of-type": {
										borderTopRightRadius: "10px", // Adjust the value as needed
									},
								}}
							>
								{tableHeader.map((header, index) => (
									<TableCell
										key={index}
										style={{
											width: "320px",
											wordBreak: "break-word",
											textAlign: "center",
										}}
									>
										{header.name}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{sessionDetails?.map(
								(row: any, index: any) => (
									(
										<TableRow
											key={row?.id}
											sx={{
												"& .MuiTableCell-root": {
													padding: "1px",
													backgroundColor: "white",
													textAlign: "center",
													wordBreak: "break-word",
												},
											}}
										>
											<TableCell
												style={{
													width: "320px",
													wordBreak: "break-word",
													textAlign: "center",
												}}
											>
												<span className="title">{row.user}</span>
												<br></br>
												<span className="title2">
													{formatDateWithMonthName(row?.created_at)}
												</span>
											</TableCell>
											<TableCell
												style={{
													width: "320px",
													wordBreak: "break-word",
													textAlign: "center",
												}}
											>
												{!row?.did_update ? row?.update_log : row?.update_log?.map((item: any, index: any) => (
													<div key={index}>
														<span className="title2">
															{capitalizeFirstLetter(item?.field_name)}
														</span>
														<br></br>
														<span className="title">
															{typeof item?.new_value == "string"
																? capitalizeFirstLetter(item?.new_value)
																: item?.field_name == 'assignee' ?
																	item?.new_value?.map((assignee: any) => assignee).join(',')
																	: item?.field_name == 'predecessors' ?
																		item?.new_value?.map((predecessor: any) => `${predecessor?.predecessor_name}, ${predecessor?.relationship}, ${predecessor?.lag_days}`)
																		: item?.field_name == 'successors' ?
																			item?.new_value?.map((successor: any) => `${successor?.successor_name}, ${successor?.relationship}, ${successor?.lag_days}`)
																			: ''}
														</span>
													</div>
												))}
											</TableCell>
											<TableCell
												style={{
													width: "320px",
													wordBreak: "break-word",
													textAlign: "center",
												}}
											>
												{!row?.did_update ? row?.update_log : row?.update_log?.map((item: any, index: any) => (
													<div key={index}>
														<span className="title2">
															{capitalizeFirstLetter(item?.field_name)}
														</span>
														<br></br>
														<span className="title">
															{typeof item?.old_value == "string"
																? capitalizeFirstLetter(item?.old_value)
																: item?.field_name == 'assignee' ?
																	item?.old_value?.map((assignee: any) => assignee).join(',')
																	: item?.field_name == 'predecessors' ?
																		item?.old_value?.map((predecessor: any) => `${predecessor?.predecessor_name}, ${predecessor?.relationship_type}, ${predecessor?.lag_days}`)
																		: item?.field_name == 'successors' ?
																			item?.old_value?.map((successor: any) => `${successor?.successor_name}, ${successor?.relationship_type}, ${successor?.lag_days}`)
																			: ""}
														</span>
													</div>
												))}
											</TableCell>

											<TableCell>{row.original}</TableCell>
										</TableRow>
									)
								),
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{
					(sessionDetails?.length <= 0 && !isFetching) &&
					<Box
						flex={1}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						minHeight="60vh"
					// marginRight={12}
					>
						<Grid2 className="no-data">{"No Data Available"}</Grid2>
					</Box>
				}
			</Box>
			{((!isFetching) && (sessionDetails?.length > 0)) &&
				(<AppPagination
					totalPages={getTotalPages(totalCount, pageRowsCount)}
					currentPage={currentPage}
					onPageChange={handlePageChange}
					onChangesRowsPerPage={handlePageCountChange}
				/>)
			}
		</Box>
	)
}

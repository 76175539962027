import { Box, Grid2, Modal, Typography } from "@mui/material"
// import "./AppSidebar.scss"
import { useTranslation } from "react-i18next"
import AppButton from "../AppButton/AppButton"
import AppFormField from "../AppFormField/AppFormField"
import { useFormik } from "formik"
import { DateInitialValue, DateValidationSchema } from "./Validation"
import { toast } from "react-toastify"
import { format as dateFnsFormat } from "date-fns"
import AppDatePicker from "../../components/AppDatePicker/AppDatePicker"
import moment from "moment"

interface StatusConfirmationModalProps {
	open: boolean
	onConfirm: (values: any) => void
	onClose?: () => void
	isLogoutLoading?: boolean
	confirmationMessage?: string
	closeButtonText?: string
	confirmButtonText?: string
	status?: string
	task_code?: string
	start_date?: string
	finish_date?: string
	previous_session_start_date?: string
	previous_session_finish_date?: string
}

const StatusConfirmationModal = ({
	open,
	onConfirm,
	onClose,
	isLogoutLoading,
	confirmationMessage,
	closeButtonText,
	confirmButtonText,
	status,
	task_code,
	start_date,
	finish_date,
	previous_session_start_date,
	previous_session_finish_date,
}: StatusConfirmationModalProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "activity",
	})
	const today = new Date().toISOString().split("T")[0]

	const handleStatusChange = (values: any) => {
		try {
			if (status === "Completed") {
				const finishDate = values?.actual_finish_date
				if (!finishDate) {
					return toast.error("Actual Finish Date is required")
				} else if (finishDate && start_date) {
					const startDate = dateFnsFormat(new Date(start_date), "yyyy-MM-dd")
					if (startDate > finishDate) {
						return toast.error("Finish date cannot be earlier than start date.")
					}
				}
			}
			if (status === "In Progress") {
				const startDate = values?.actual_start_date
				if (!startDate) {
					return toast.error("Actual Start Date is required")
				} else if (finish_date && startDate) {
					if (startDate > finish_date) {
						return toast.error("Start date cannot be later than finish date")
					}
				}
			}
			if (status === "Planned") {
				const startDate = values?.actual_start_date
				const finishDate = values?.actual_finish_date
				if (!startDate) {
					return toast.error("Planned Start Date is required")
				} else if (!finishDate) {
					return toast.error("Planned Finish Date is required")
				} else if (startDate > finishDate) {
					return toast.error("Start date cannot be later than finish date")
				}
			}
		} catch (e) {
			console.log("Error", e)
			return
		}
		onConfirm(values)
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		// resetForm,
	} = useFormik({
		initialValues: {
			actual_start_date:
				status === "In Progress" ? previous_session_start_date : null,
			actual_finish_date:
				status === "Completed" ? previous_session_finish_date : null,
		},
		onSubmit: onConfirm,
		validationSchema: null,
	})

	return (
		<Modal open={open}>
			<div className="logout-confirm-modal-container">
				<Typography variant="h5" color="primary" fontWeight={"700"} mb={1}>
					Status Update
				</Typography>
				<Typography color="primary">
					{/* {confirmationMessage || "No confirmation message"} */}
					<b>{task_code}</b>
					{` status will be changed to "${status}". Are you sure you want to apply the change?`}
				</Typography>
				<Grid2 mt={1}>
					{status == "Completed" && (
						<AppDatePicker
							label={t("actual_finished_date")}
							isRequired
							type="date"
							name={"actual_finish_date"}
							placeholder={t("actual_finished_date")}
							value={values.actual_finish_date ? moment(values.actual_finish_date) : null}
							onChange={(e: string) =>
								setFieldValue("actual_finish_date", e, true)
							}
							onBlur={handleBlur}
							touched={touched.actual_finish_date}
							errorMsg={
								errors.actual_finish_date ? errors.actual_finish_date : ""
							}
						/>
					)}
					{status === "In Progress" && (
						<AppDatePicker
							label={t("actual_start_date")}
							isRequired
							type="date"
							name={"actual_start_date"}
							placeholder={t("actual_start_date")}
							value={values.actual_start_date ? moment(values.actual_start_date) : null}
							onChange={(e: string) =>
								setFieldValue("actual_start_date", e, true)
							}
							onBlur={handleBlur}
							touched={touched.actual_start_date}
							errorMsg={
								errors.actual_start_date ? errors.actual_start_date : ""
							}
						/>
					)}
				</Grid2>
				{status === "Planned" && (
					<>
						<Grid2 mt={1}>
							<AppDatePicker
								label={t("planned_start_date")}
								type="date"
								name={"actual_start_date"}
								placeholder={t("planned_start_date")}
								value={values.actual_start_date ? moment(values.actual_start_date) : null}
								onChange={(e: string) =>
									setFieldValue("actual_start_date", e, true)
								}
							/>
						</Grid2>
						<Grid2 mt={1}>
							<AppDatePicker
								label={t("planned_finished_date")}
								type="date"
								name={"actual_finish_date"}
								placeholder={t("planned_finished_date")}
								value={values.actual_finish_date ? moment(values.actual_finish_date) : null}
								onChange={(e: string) =>
									setFieldValue("actual_finish_date", e, true)
								}
							/>
						</Grid2>
					</>
				)}
				<Box
					mt="30px"
					mb="10px"
					display="flex"
					gap="16px"
					justifyContent="flex-end"
				>
					<AppButton
						onClick={onClose}
						btnSize="medium"
						variant="outlined"
						disabled={isLogoutLoading}
					>
						{closeButtonText ? closeButtonText : t("cancel")}
					</AppButton>
					<AppButton
						// onClick={onConfirm}
						onClick={() => handleStatusChange(values)}
						btnSize="medium"
						disabled={isLogoutLoading}
					>
						{confirmButtonText ? confirmButtonText : t("save")}
					</AppButton>
				</Box>
			</div>
		</Modal>
	)
}

export default StatusConfirmationModal

import { Box, Grid2 } from "@mui/material"
import { useTranslation } from "react-i18next"
import { IAddSubActivitySectionFormProps } from "./validation"
import { useGetProjectTradesQuery } from "../../api/network/projectApiService"
import { useMemo } from "react"
import { ActivityCode } from "../../api/types/Project"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"

interface ISubActivityTradeFormProps
	extends IAddSubActivitySectionFormProps {
	projectId: any
	mode: string
}

export default function SubActivityTrade(
	{ values, errors, disabled, handleBlur, projectId }: ISubActivityTradeFormProps
) {
	const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "subActivity.errors",
	})
	const { data: { results: activityCodes = [] } = {} } = useGetProjectTradesQuery(projectId)

	const subActivityCodes = useMemo(() => {
		return activityCodes?.map((trade: ActivityCode) => ({
			id: trade.id,
			value: trade.id,
			label: trade.actv_code_name,
		})) || []
	}, [activityCodes])

	const subActivityArray = values?.activity_codes?.map(item => item.id) || []

	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}>
			<Grid2 display={"flex"} justifyContent={"space-between"}>
				<Grid2 className="title">{t("type")}</Grid2>
			</Grid2>
			<Grid2>
				<AppAutocompleteFormField
					label={t("sub_activity_code")}
					name={"activity_codes"}
					placeholder={t("select_type")}
					multiple={true}
					options={subActivityCodes || []}
					value={subActivityArray}
					disabled={true}
					onBlur={handleBlur}
				/>
			</Grid2>
		</Box>
	)
}

import React, { useMemo, useState, useEffect } from "react"
import {
	MenuItem,
	Box,
	Typography,
	Autocomplete,
	TextField,
	Menu,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ExpandMoreOutlined, AddOutlined } from "@mui/icons-material"
import { useGetAllProjectsQuery } from "../../../api/network/projectApiService"
import { routeUrls } from "../../../routes/routeUrls"
import AppButton from "../../../components/AppButton/AppButton"
import useUserRole from "../../../hooks/useUserRole"
import { Svgs } from "../../../assets/svgs"
import { UserRole } from "../../../api/types/Auth"
import DisplayTable from "../../../components/DisplayTable/DisplayTable"
import useAppPagination from "../../../hooks/useAppPagination"
import {
	getProjectStatusColor,
	getTotalPages,
	projectStatusArray,
} from "../../../utils"
import { formatDateWithMonthName } from "../../../utils/dateTime"
import { ProjectDetails } from "../../../api/types/Project"
import ViewDetailButton from "../../../components/DisplayTable/ViewDetailButton"
import TableCellText from "../../../components/DisplayTable/TableCellText"
import AppDropdownFilter from "../../../components/AppDropdownFilter/AppDropdownFilter"
import AppAutocompleteFilter from "../../../components/AppDropdownFilter/AppAutocompleteFilter"
import useCompaniesListOption from "../../../hooks/useCompaniesListOption"
import ImportXerModal from "../../Dashboard/ImportXerModal"

const tableHeaders = [
	{ name: "Project ID", sortable: false },
	{ name: "Project Name", sortable: false },
	{ name: "Project Description", sortable: false },
	{ name: "Company", sortable: false },
	{ name: "Start Date", sortable: false },
	{ name: "End Date", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "", sortable: false },
]

const ProjectsList: React.FC = () => {
	const navigate = useNavigate()
	const { t: tComp } = useTranslation("translation", { keyPrefix: "company" })
	const { t: tCreatePrj } = useTranslation("translation", {
		keyPrefix: "createProject",
	})

	const [statusFilter, setStatusFilter] = useState("")
	const [companyFilter, setCompanyFilter] = useState<number>()
	const [showImportPopup, setShowImportPopup] = useState(false)
	const appUserRole = useUserRole()

	const { isFetching: isCompaniesFetching, companiesOptions } =
		useCompaniesListOption()

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const { data, isFetching } = useGetAllProjectsQuery({
		page_size: pageRowsCount,
		page: currentPage,
		status: statusFilter,
		company: companyFilter,
	})

	const results = data?.results ?? []

	const onProjectDetailClick = (id: number) => () => {
		navigate(routeUrls.projects.viewAndEdit(String(id || 0)))
	}

	const tableColumns = useMemo(
		() => [
			"project_id",
			"name",
			"description",
			"company_name",
			(data: ProjectDetails) => {
				return formatDateWithMonthName(data.start_date)
			},
			(data: ProjectDetails) => {
				return formatDateWithMonthName(data.end_date)
			},
			(data: ProjectDetails) => {
				return (
					<TableCellText
						style={{
							color: getProjectStatusColor(data.status),
						}}
					>
						{data.status}
					</TableCellText>
				)
			},
			(data: ProjectDetails) => {
				return <ViewDetailButton onClick={onProjectDetailClick(data.id)} />
			},
		],
		[],
	)

	const projectStatusOptions = useMemo(() => {
		return projectStatusArray.map((s) => ({ id: s.status, label: s.status }))
	}, [])

	const onAddProjectClick = () => {
		navigate(routeUrls.projects.create)
	}

	const handleProjectStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	const openImportPopup = () => {
		if (appUserRole === UserRole.SuperAdmin) {
			setShowImportPopup(true)
		}
	}

	const closeImportPopup = () => {
		setShowImportPopup(false)
	}

	const projectImportButton = useMemo(() => {
		return (
			(appUserRole === UserRole.SuperAdmin ||
				appUserRole === UserRole.CompanyAdmin) && (
				<Box display="flex" gap="24px">
					<AppButton
						btnSize="medium"
						onClick={openImportPopup}
						disabled={appUserRole !== UserRole.SuperAdmin}
						endIcon={<AddOutlined />}
					>
						{tCreatePrj("add_project")}
					</AppButton>
				</Box>
			)
		)
	}, [appUserRole])

	useEffect(() => {
		navigate(window.location.pathname, { replace: true })
		const handleBackNavigation = () => {
			navigate(routeUrls.dashboard, { replace: true })
		}
		window.history.pushState(null, "", window.location.href)
		window.addEventListener("popstate", handleBackNavigation)

		return () => {
			window.removeEventListener("popstate", handleBackNavigation)
		}
	}, [navigate])

	return (
		<div className="users-list-section">
			<div className="heading-wrapper">
				<Typography className="title">{tCreatePrj("projects")}</Typography>
				{projectImportButton}
			</div>
			<div className="search-and-filters-container">
				<div className="search-and-filters">
					{appUserRole === UserRole.SuperAdmin && (
						<AppAutocompleteFilter
							disabled={isCompaniesFetching}
							label={tComp("companies")}
							placeholder={tComp("search_here")}
							options={companiesOptions}
							value={companyFilter}
							onChange={setCompanyFilter}
						/>
					)}
					<AppDropdownFilter
						placeholder={tComp("status")}
						value={statusFilter}
						menuItems={projectStatusOptions}
						onChange={handleProjectStatusFilterChange}
					/>
				</div>
			</div>
			<DisplayTable
				isFetching={isFetching}
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={results}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(data?.count, data?.page_size)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPageCount={data?.count}
			/>
			<ImportXerModal open={showImportPopup} onClose={closeImportPopup} />
		</div>
	)
}

export default ProjectsList

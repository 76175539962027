import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"
import "./ActivitiesList.scss"
import { useTranslation } from "react-i18next"
import { useState, useEffect, useMemo } from "react"
import { AppColors } from "../../theme"
import { Svgs } from "../../assets/svgs"
import { ActivityProposalDetails } from "../../api/types/Project"
import { formatDateWithMonthName } from "../../utils/dateTime"
import {
	useGetAllActivityProposalsQuery,
	useGetSwimlanesListByActivityCodeQuery,
} from "../../api/network/projectApiService"
import { Tooltip, Grid2, Box, IconButton, Typography } from "@mui/material"
import TableCellText from "../../components/DisplayTable/TableCellText"
import AppAutocompleteFilter from "../../components/AppDropdownFilter/AppAutocompleteFilter"
import {
	getTotalPages,
	proposedStatusArray,
	getActivityStatusColor,
} from "../../utils"
import useAppPagination from "../../hooks/useAppPagination"
import AppButton from "../../components/AppButton/AppButton"
import { useLocation, useNavigate } from "react-router-dom"

const tableHeaders = [
	{ name: "Task Code", sortable: false },
	{ name: "Activity Name", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "Activity Code", sortable: false },
	{ name: "Created At", sortable: false },
	{ name: "View Changes", sortable: false },
	{ name: "Proposed Status", sortable: false },
]

const TeamPreviousProposals = () => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })

	const navigate = useNavigate()
	const location = useLocation()
	const [expandedRowId, setExpandedRowId] = useState<number>(0)
	const [statusFilter, setStatusFilter] = useState<string>()
	const [projectId, setProjectId] = useState(null)
	const [sessionId, setSessionId] = useState(null)
	const [activityCodes, setActivityCodes] = useState<
		{ id: string; label: string }[]
	>([])
	const [selectedActivityCode, setSelectedActivityCode] = useState<string>()

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const handleToggleDetailsOpen = (id: number) => {
		if (expandedRowId === id) {
			setExpandedRowId(0)
			return
		} else {
			setExpandedRowId((prev) => id)
		}
	}

	const {
		data: { results: previousProposals = [], count: totalCount } = {},
		isFetching: isFetchingPreviousProposals,
	} = useGetAllActivityProposalsQuery({
		sessionId: location?.state?.project?.active_session || sessionId,
		projectId:
			location?.state?.project?.id || location?.state?.projectId || projectId,
		page: currentPage,
		page_size: pageRowsCount,
		status: statusFilter ? statusFilter : "rejected,approved",
		act_code_id: selectedActivityCode || "",
	})

	const { data: activityCodeData, isFetching: isFetchingActivityCodes } =
		useGetSwimlanesListByActivityCodeQuery({
			page: 1,
			page_size: 500,
			project: location?.state?.project?.id || location?.state?.projectId,
		})

	useEffect(() => {
		if (activityCodeData && activityCodeData?.results) {
			setActivityCodes(
				activityCodeData.results.map((actCode: any) => ({
					id: actCode.id,
					label: actCode.actv_code_name,
				})),
			)
		}
	}, [activityCodeData])

	const tableColumns = [
		(data: ActivityProposalDetails) => <span>{data?.activity?.task_code}</span>,
		(data: ActivityProposalDetails) => (
			<span>{data?.activity?.activity_name}</span>
		),
		(data: ActivityProposalDetails) => (
			<TableCellText
				sx={{ color: getActivityStatusColor(data.activity.status) }}
			>
				{data?.activity?.status}
			</TableCellText>
		),
		(data: ActivityProposalDetails) => (
			<span>
				{data?.activity?.activity_code &&
				data?.activity?.activity_code?.length > 0
					? data?.activity?.activity_code
							.map((code) => code.actv_code_name)
							.join(", ")
					: ""}
			</span>
		),
		(data: ActivityProposalDetails) => (
			<span>{formatDateWithMonthName(data.created_at ?? "")}</span>
		),
		(data: ActivityProposalDetails) => (
			<IconButton onClick={() => handleToggleDetailsOpen(data.id)}>
				{expandedRowId !== data.id ? (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Show Changes
						</span>
						<Svgs.HideIcon />
					</>
				) : (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Hide Changes
						</span>
						<Svgs.ShowIcon />
					</>
				)}
			</IconButton>
		),
		(data: ActivityProposalDetails) =>
			data.status === "approved" ? (
				<span style={{ color: AppColors.primaryGreen }}>Approved</span>
			) : data.status === "rejected" ? (
				<span style={{ color: AppColors.error }}>Rejected</span>
			) : null,
	]

	const statusOptions = useMemo(() => {
		return proposedStatusArray.map((s) => ({
			id: s.status_code,
			label: s.status,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	useEffect(() => {
		if (location?.state?.projectData) {
			setProjectId(location?.state?.projectData?.id)
			setSessionId(location?.state?.projectData?.active_session)
		}
	}, [location])

	const handleBack = () => {
		navigate(-1)
	}

	return (
		<div className="activities-list-container">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<DetailNavBar
					title={"Previous Proposals"}
					showBackButton={true}
					onBackPress={handleBack}
				/>
			</div>
			<div className="search-and-filters-container">
				<AppAutocompleteFilter
					disabled={isFetchingActivityCodes}
					label={"Activity Codes"}
					placeholder={"Search by Activity Code"}
					options={activityCodes}
					value={selectedActivityCode}
					onChange={setSelectedActivityCode}
				/>
				<AppDropdownFilter
					placeholder={t("proposed_status")}
					value={statusFilter}
					menuItems={statusOptions}
					onChange={handleStatusFilterChange}
				/>
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={previousProposals || []}
				isFetching={isFetchingPreviousProposals}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				expandedRowId={expandedRowId}
				sessionId={location?.state?.project?.active_session}
				screenProp="previousTeamProposal"
				totalPageCount={totalCount}
			/>
		</div>
	)
}

export default TeamPreviousProposals

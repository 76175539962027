import React, { useState } from "react"
import { Menu, MenuItem } from "@mui/material"
import AppButton from "../AppButton/AppButton"
import { CloseOutlined, ExpandMoreOutlined } from "@mui/icons-material"
import "./AppDropdownFilter.scss"

type TMenuItem = {
	id: any
	label: any
}

interface AppDropdownFilterProps {
	placeholder: string
	value?: any
	menuItems: TMenuItem[]
	onChange: (menu?: TMenuItem) => any
	customStyle?: React.CSSProperties
	disabled?: boolean
}

const AppDropdownFilter = ({
	placeholder,
	value,
	menuItems = [],
	onChange,
	customStyle={},
	disabled=false,
}: AppDropdownFilterProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const closeMenu = () => {
		setAnchorEl(null)
	}

	const handleChange = (_value: TMenuItem) => () => {
		onChange(_value)
		closeMenu()
	}

	const handleClear = () => {
		onChange()
	}

	const renderDropdownButton = () => {
		if (value) {
			const selectedValue = menuItems.find((val) => val.id === value)
			return (
				<AppButton onClick={handleClear} endIcon={<CloseOutlined />}>
					{selectedValue?.label || value}
				</AppButton>
			)
		} else {
			return (
				<AppButton
					className={`placeholder-button`}
					onClick={openMenu}
					endIcon={<ExpandMoreOutlined />}
					style={customStyle}
					disabled={disabled}
				>
					{placeholder}
				</AppButton>
			)
		}
	}

	return (
		<div className="app-dropdown-filter-wrapper">
			{renderDropdownButton()}
			<Menu anchorEl={anchorEl} open={open} onClose={closeMenu}
			sx={
				{
					"& .MuiMenu-paper": {
						maxHeight: "200px",
					},
				}
			}
			>
				{menuItems.map((m) => (
					<MenuItem key={m.id}
					onClick={handleChange(m)}
					sx={{
						whiteSpace: "normal",
						wordBreak: "break-word",
						maxWidth: "220px",

					}}
					>
						{m.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}

export default AppDropdownFilter

import { Modal, Typography } from "@mui/material"
import AppFormField from "../../components/AppFormField/AppFormField"
import AppButton from "../../components/AppButton/AppButton"
import { useFormik } from "formik"
import {
	beginSessionValidationSchema,
	initialBeginSessionValue,
} from "./Validation"
import { useBeginSessionMutation, useGetProjectMembersQuery } from "../../api/network/projectApiService"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useMemo } from "react"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"

export default function BeginSession(props: any) {
	const { open, onClose, project } = props
	const [beginSessionTrigger] = useBeginSessionMutation()

	const {
		data: { results: projectMembers = [] } = {},
		isFetching: isFetchingPrjMembers,
	} = useGetProjectMembersQuery({
		id: project,
		page_size: 1000,
		page: 1,
	})

	const inviteViewers = useMemo(() => {
		const viewersList = projectMembers.filter((member) => member.role_name === "Viewer");
		return viewersList.map((member) => ({
			id: member.user_id,
			label: member.user_name || "",
		}));
	}, [projectMembers]);


	const handleBeginSession = async (values: any) => {
		try {
			const payload = {
				session_name: values.session_name,
				description: values.description,
				viewers: values.viewers,
				project: project
			}
			const response = await beginSessionTrigger(payload);
			toast.success("Session started successfully!");
			onClose();
		} catch (error) {
			console.log(error);
			toast.error("Error in starting session");
		}
	}

	const handleViewersChange = (field: string, value: any) => {
		const selectedViewerIds = value ? value.map((v: any) => v.id) : [];
		setFieldValue("viewers", selectedViewerIds);
	}


	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		// resetForm,
	} = useFormik({
		initialValues: {
			...initialBeginSessionValue,
		},
		onSubmit: handleBeginSession,
		validationSchema: beginSessionValidationSchema,
	})

	return (
		<Modal open={open} onClose={onClose}>
			<form onSubmit={handleSubmit} className="add-member-modal">
				<div className="modal-heading">
					<Typography className="title">{"Begin Session"}</Typography>
					<Typography className="subtitle">
						{"Please enter session name and description"}
					</Typography>
				</div>
				<div className="modal-fields">
					<AppFormField
						label={"Session Name"}
						name="session_name"
						placeholder={"Enter session name"}
						value={values.session_name}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.session_name}
						errorMsg={errors.session_name}
					/>
					<AppFormField
						label={"Description"}
						name="description"
						placeholder={"Enter description"}
						value={values.description}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.description}
						errorMsg={errors.description}
					/>
					<AppAutocompleteFormField
						label={"Invite Viewers"}
						showLoader={isFetchingPrjMembers}
						name={"viewers"}
						placeholder={"Select Invited Viewers"}
						value={values.viewers}
						options={inviteViewers}
						multiple={true}
						onChange={handleViewersChange}
						onBlur={handleBlur}
						touched={touched.viewers}
					// errorMsg={errors.viewers}
					/>
				</div>
				<div className="modal-action-btns">
					<AppButton
						// disabled={isAddingTeamMember}
						btnSize="medium"
						type="submit"
						style={{
							fontWeight: "700",
						}}
					>
						{"Start Session"}
					</AppButton>
					<AppButton
						btnSize="medium"
						variant="text"
						color="error"
						onClick={onClose}
						style={{
							fontWeight: "700",
						}}
					>
						{"Cancel"}
					</AppButton>
				</div>
			</form>
		</Modal>
	)
}

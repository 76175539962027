import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Timeline, {
	DateHeader,
	OnItemDragObjectBase,
	TimelineHeaders,
} from "react-calendar-timeline"
import moment from "moment"
import "react-calendar-timeline/lib/Timeline.css"
import "./Swimlane.scss"
import { Item, Lines, SwimlaneSection } from "./SwimlaneData"
import { Box, Button, Grid2, Typography } from "@mui/material"
import {
	ProjectAPIs,
	projectApiTags,
	useGetSwimlaneBasedActivitiesQuery,
	useGetSwimlaneChangesQuery,
	useLazyGetProjectCalendarQuery,
	useLazyGetSubSwimlaneActivitiesQuery,
	useLazyGetSwimlanesListByActivityCodeQuery,
	useUpdateActivityMutation,
	useUpdateSwimlaneStateMutation,
} from "../../api/network/projectApiService"
import {
	getEmptyDataGroups,
	getGroupsFromActivties,
	getSwimlaneItemsFromActivity,
} from "./SwimlaneUtils"
import { RootReduxState } from "../../api/store/store"
import { useSelector } from "react-redux"
import AppLoader from "../../components/AppLoader/AppLoader"
import AppAutocompleteFilter from "../../components/AppDropdownFilter/AppAutocompleteFilter"
import ActivityCard from "./ActivityCard"
import { format as dateFnsFormat, set } from "date-fns"
import { ProjectUserRole } from "../../api/types/Auth"
import { ProjectDetails } from "../../api/types/Project"
import LineConnect from "../../components/LineConnect/LineConnect"
import { Xwrapper } from "react-xarrows"
import { updateSwimlaneNav } from "../../api/slices/commonSlice"
import { useDispatch } from "react-redux"
import { CustomSwitch } from "../../components/AppSwitch/CustomSwitch"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import { ZoomIn, ZoomOut } from "@mui/icons-material"

const CustomItemRenderer = ({
	item,
	itemContext,
	getItemProps,
	getResizeProps,
}: any) => {
	const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
		leftStyle: {
			cursor: "w-resize",
			left: "-3px",
		},
		rightStyle: {
			cursor: "e-resize",
			right: "-3px",
		},
	})

	return (
		<div
			id={`${item.id}`}
			{...getItemProps({
				style: {
					background: itemContext?.selected ? "#eeeeee" : "white",
					borderColor: itemContext?.selected ? "#777777" : "white",
					color: "black",
					cursor: itemContext?.selected && itemContext?.canMove ? "move" : "pointer",
				},
			})}
		>
			{itemContext.useResizeHandle && itemContext?.canResizeLeft && itemContext?.selected ? <div {...leftResizeProps} /> : ""}
			{/* big card or small card condition will be implemented here*/}
			<ActivityCard
				{...item}
				start_time={
					new Date(moment(item.actual_start_date).format("YYYY-MM-DD"))
				}
				end_time={
					new Date(moment(item.actual_finish_date).format("YYYY-MM-DD"))
				}
				activityItem={item}
			/>

			{itemContext.useResizeHandle && itemContext?.canResizeRight && itemContext?.selected ? <div {...rightResizeProps} /> : ""}
		</div>
	)
}

interface SwimlaneCalenderProps {
	session: any
	projectId: number | undefined
	swimlaneWbsListShowStatus: boolean
	swimlaneActivityListShowStatus: boolean
	editable: boolean
	projectData: ProjectDetails | undefined
	viewerReload?: number | null
}

const SwimlaneCalender: React.FC<SwimlaneCalenderProps> = (props) => {
	const { projectId, session, editable, projectData } = props
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [renderKey, setRenderKey] = useState<{ [key: string]: number }>({}) // Ids for relation lines
	const [sections, setSections] = useState<
		{ [key: string]: SwimlaneSection } | undefined
	>({})
	const [cards, setCards] = useState<{ [key: string]: Item[] }>({}) // Sate for activity cards
	const [lines, setLines] = useState<{ [key: string]: Lines[] }>({}) // State for relation lines
	const [activityFilter, setActivityFilter] = useState<string>()
	const [swimlaneActivityList, setSwimlaneActivityList] = useState<any[]>([])
	const [updatingSubActivities, setUpdatingSubActivities] =
		useState<boolean>(false)
	const [showRelations, setShowRelations] = useState<boolean>(false)
	const [relationToggleEnabled, setRelationToggleEnabled] =
		useState<boolean>(false)
	const changeTimestampRef = useRef<number | null>(null)

	const DAY_BUFFER = 7
	const minTime = moment(moment.now()).subtract(DAY_BUFFER, "d").startOf("day").valueOf()
	const maxTime = moment(moment.now()).add(DAY_BUFFER, "d").endOf("day").valueOf()
	const minZoom = 7 * 86400 * 1000 // Min zoom is 7 days
	const maxZoom = 15 * 86400 * 1000 // Max zoom is 15 days
	const [dateRange, setDateRange] = useState({
		start: minTime,
		end: maxTime,
	});
	const [editing, setEditing] = useState(false)
	const isViewer = projectData?.current_user_role === ProjectUserRole.Viewer

	const swimlaneNavState = useSelector(
		(state: RootReduxState) => state.commonSliceData,
	)
	const [updateActivityApiTrigger, { isLoading: isUpdatingActivity }] =
		useUpdateActivityMutation()
	const [
		getSwimlanesByActivity,
		{ data: activityData, isFetching: isFetchingActivity },
	] = useLazyGetSwimlanesListByActivityCodeQuery()
	const [getSubSwimlaneActivities] = useLazyGetSubSwimlaneActivitiesQuery()
	const [
		updateStateApiTrigger,
		{ isLoading: isUpdatingState, isUninitialized },
	] = useUpdateSwimlaneStateMutation()
	const [getProjectCalendar, { data: projectCalendarData }] =
		useLazyGetProjectCalendarQuery()

	useEffect(() => {
		if (projectId) {
			getProjectCalendar(projectId?.toString())
		}
	}, [projectId])

	const holidays = useMemo(() => {
		const holidays: any[] = []
		if (projectCalendarData && projectCalendarData?.holidays) {
			projectCalendarData?.holidays?.map((holidayArr: any) => {
				holidays.push(...holidayArr)
			})
		}
		return holidays?.map((holdiya) => moment(holdiya).format("YYYY-MM-DD"))
	}, [projectCalendarData])

	const {
		data: allData,
		isFetching,
		isUninitialized: isActivityfetchUnitilized,
		refetch,
	} = useGetSwimlaneBasedActivitiesQuery(
		{
			project: projectId ?? 0,
			...((props?.swimlaneActivityListShowStatus &&
				swimlaneNavState?.selected_activity_code) ||
			activityFilter
				? {
						activity_code: (swimlaneNavState?.selected_activity_code ||
							activityFilter) as string,
					}
				: 0),
			...(props?.swimlaneWbsListShowStatus && swimlaneNavState?.selected_wbs
				? { wbs: swimlaneNavState?.selected_wbs }
				: 0),
			...(session ? { session: session } : 0),
			is_relations_required: relationToggleEnabled,
		},
		{
			skip:
				!projectId ||
				editing ||
				(!activityFilter &&
					!swimlaneNavState?.selected_activity_code &&
					!swimlaneNavState?.selected_wbs),
		},
	)

	const {
		data: changeData,
		isFetching: isFetchingChanges,
		error: changeDataError,
	} = useGetSwimlaneChangesQuery(
		{
			project: projectId ?? 0,
			...((props?.swimlaneActivityListShowStatus &&
				swimlaneNavState?.selected_activity_code) ||
			activityFilter
				? {
						activity_code: (swimlaneNavState?.selected_activity_code ||
							activityFilter) as string,
					}
				: 0),
			...(props?.swimlaneWbsListShowStatus && swimlaneNavState?.selected_wbs
				? { wbs: swimlaneNavState?.selected_wbs }
				: 0),
			session: session,
			...(changeTimestampRef.current
				? { timestamp: changeTimestampRef.current }
				: 0),
		},
		{
			skip:
				isFetching ||
				isUpdatingActivity ||
				updatingSubActivities ||
				!projectId ||
				!session ||
				(!swimlaneNavState?.selected_wbs &&
					!swimlaneNavState?.selected_activity_code &&
					!activityFilter),
			pollingInterval: session ? 1000 * 5 : 0,
			skipPollingIfUnfocused: true,
			refetchOnReconnect: true,
		},
	)

	const updateActivityList = useCallback(() => {
		if (changeData) {
			setCards(prev => {
				const newCards: { [key: string]: Item[] } = {}
				Object.keys(prev).forEach((sectionId) => {
					newCards[sectionId] = prev[sectionId].map((card) => {
						const updatedCard = changeData?.updated_activities?.find(
							(activity) => activity.id === card.id,
						)
						if (updatedCard) {
							return {
								...card,
								...updatedCard,
								start_time: updatedCard.actual_start_date
									? new Date(
											moment(
												updatedCard.actual_start_date,
												"YYYY-MM-DDTHH:mm:ssZ",
											).format("YYYY-MM-DDT00:00:00Z"),
										)
									: new Date(
											moment(
												updatedCard.planned_start_date,
												"YYYY-MM-DDTHH:mm:ssZ",
											).format("YYYY-MM-DDT00:00:00Z"),
										),
								end_time: updatedCard.actual_finish_date
									? new Date(
											moment(
												updatedCard.actual_finish_date,
												"YYYY-MM-DDTHH:mm:ssZ",
											).format("YYYY-MM-DDT24:00:00Z"),
										)
									: new Date(
											moment(
												updatedCard.planned_finish_date,
												"YYYY-MM-DDTHH:mm:ssZ",
											).format("YYYY-MM-DDT24:00:00Z"),
										),
							}
						}
						return card
					})
				})
				return newCards;
			})
		}
	}, [changeData])

	useEffect(() => {
		if (changeData) {
			changeTimestampRef.current = changeData.current_timestamp
			if (
				allData &&
				changeData?.is_updated &&
				!isFetching &&
				!isActivityfetchUnitilized &&
				projectData?.current_user_role !== ProjectUserRole.SuperIntendent
			) {
				updateActivityList()
			}
		}
	}, [
		allData,
		changeData,
		isActivityfetchUnitilized,
		isFetching,
		projectData?.current_user_role,
		updateActivityList,
	])

	const handleViewerBack = useCallback(() => {
		navigate(routeUrls.dashboard)
	}, [navigate])

	useEffect(() => {
		if (
			changeDataError &&
			"data" in changeDataError &&
			(changeDataError.data as { error: string })?.error ===
				"Session is not active."
		) {
			if (isViewer) {
				handleViewerBack()
			} else {
				ProjectAPIs.util.invalidateTags([projectApiTags.END_SESSION])
			}
			dispatch(ProjectAPIs.util.invalidateTags([projectApiTags.END_SESSION]))
		}
	}, [changeDataError, handleViewerBack, isViewer])

	/**
	 * Method to fetch activities for sub swimlanes.
	 * This method is also responsible for updating the state of sections, cards, lines and renderKeys.
	 * @param subSwimlane sub section of swimlane
	 * @returns
	 */
	const fetchSubActivities = async (subSwimlane: {
		name: string
		code: string
		activities: []
	}) => {
		// if (!session) {
		// 	return
		// }
		setUpdatingSubActivities(true)
		getSubSwimlaneActivities({
			project: projectId ?? 0,
			wbs: subSwimlane.code,
			...(session ? { session: session } : 0),
			is_relations_required: relationToggleEnabled,
		})
			.unwrap()
			.then((res: any) => {
				const data = {
					name: subSwimlane.name,
					groups: getGroupsFromActivties(res),
				}
				Promise.all([
					setSections(
						(prevState: { [key: string]: SwimlaneSection } | undefined) => {
							if (prevState && prevState[subSwimlane.code] !== undefined) {
								return {
									...prevState,
									[subSwimlane.code]: data,
								}
							} else {
								return prevState
							}
						},
					),
				]).then(() => {
					// if (!sections || sections[subSwimlane.code] === undefined) {
					// 	// If main wbs is changed, then skip updating cards and lines(This can be old data from prev queries)
					// 	return
					// }
					// Update cards to the section
					setCards((prevState) => {
						return {
							...prevState,
							[subSwimlane.code]: getSwimlaneItemsFromActivity(
								res,
								data.groups,
								editable,
							),
						}
					})
					// Update lines
					setLines((prevState) => {
						return {
							...prevState,
							[subSwimlane.code]: getLinesFromCards(
								getSwimlaneItemsFromActivity(res, data.groups, editable),
							),
						}
					})
					// Update render keys
					setRenderKey((prevState) => {
						if (prevState && prevState[subSwimlane.code]) {
							return {
								...prevState,
								[subSwimlane.code]: Math.random(),
							}
						} else {
							return prevState
						}
					})
				})
				setUpdatingSubActivities(false)
			})
			.catch((err) => {
				setUpdatingSubActivities(false)
			})
	}

	/**
	 * Hook to create sections and cards(Only for main swimlane) when main wbs api is called
	 */
	useEffect(() => {
		if (allData) {
			const newSections: { [key: string]: SwimlaneSection } = {}
			const newCards: { [key: string]: Item[] } = {}
			const newLines: { [key: string]: Lines[] } = {}
			const renderKeys: { [key: string]: number } = {}
			if (allData?.main_swimlane && allData?.main_swimlane?.length > 0) {
				newSections["0"] = {
					name: "Main Swimlane",
					groups: getGroupsFromActivties(allData.main_swimlane),
				}
				newCards["0"] = getSwimlaneItemsFromActivity(
					allData.main_swimlane,
					newSections["0"].groups,
					editable,
				)
				// Add lines to state for each card array
				newLines["0"] = getLinesFromCards(newCards["0"])
				renderKeys["0"] = Math.random()
			}
			if (allData.sub_swimlanes && allData?.sub_swimlanes?.length > 0) {
				allData.sub_swimlanes?.forEach((subSwimlane: any) => {
					newSections[subSwimlane.code] = {
						name: subSwimlane.name,
						// groups: getGroupsFromActivties(subSwimlane.activities),
						groups: [],
					}
					renderKeys[subSwimlane.code] = Math.random()
				})
			}
			if (
				allData?.main_swimlane?.length === 0 &&
				allData?.sub_swimlanes?.length === 0
			) {
				// Add empty section if no activities are present
				newSections["0"] = {
					name: "Main Swimlane",
					groups: getEmptyDataGroups(),
				}
				renderKeys["0"] = Math.random()
			}
			// Update state
			setSections((prevUpdate) => newSections)
			setCards((prevState) => newCards)
			setLines((prevState) => newLines)
			setRenderKey((prevState) => renderKeys)
			// Iterate all subswimlanes and fetch activities
			allData.sub_swimlanes?.forEach((subSwimlane: any) =>
				fetchSubActivities(subSwimlane),
			)
		}
	}, [allData, projectId, swimlaneNavState, editable])

	/**
	 * Method to update the planned start and end date of an activity
	 * @param activityId Id of the activity to be updated
	 * @param startDate Start date of the activity
	 * @param endDate End date of the activity
	 */
	const updateActivityPlannedDates = (
		activityId: string,
		startDate: number,
		endDate: number,
	) => {
		const payload: { id: string; [key: string]: any } = {
			id: activityId,
		}
		payload["session"] = session
		payload["planned_start_date"] = dateFnsFormat(
			new Date(startDate),
			"yyyy-MM-dd HH:mm:ss",
		)
		payload["planned_finish_date"] = dateFnsFormat(
			new Date(endDate),
			"yyyy-MM-dd HH:mm:ss",
		)

		updateActivityApiTrigger(payload)
			.unwrap()
			.then((res) => {
				setEditing(false)
				// toast.success("Activity updated successfully")
				// window.location.reload()
			})
			.catch((err) => {
				setEditing(false)
			})
	}

	const onItemDrag = (itemDragObject: OnItemDragObjectBase) => {
		setEditing(true)
	}

	const onItemResize = (id: number, time: number, edge: "left" | "right") => {
		// Retrieve only date part from time(Reduce 1 day if planned end date is changed, to accomodate edge settings of calendar)
		const updatedDate = moment(time)
			.subtract(edge === "right" ? 1 : 0, "d")
			.format("YYYY-MM-DD")
		// Update card start or end time based on edge
		setCards((prevCards) => {
			const newCards = { ...prevCards }
			Object.keys(newCards).forEach((sectionId) => {
				newCards[sectionId] = newCards[sectionId].map((card: Item) => {
					if (card.id === id) {
						// Get cards planned time
						const plannedTime = moment(
							edge === "left"
								? card.planned_start_date
								: card.planned_finish_date,
							"YYYY-MM-DDTHH:mm:ssZ",
						)
							.format("HH:mm:ss")
							.toString()
						updateActivityPlannedDates(
							id.toString(),
							edge === "left"
								? moment(`${updatedDate}T${plannedTime}`).utc().valueOf()
								: card.start_time.getTime(),
							edge === "left"
								? moment(card.end_time).subtract(1, "d").utc().valueOf()
								: moment(`${updatedDate}T${plannedTime}`).utc().valueOf(),
						)
						return {
							...card,
							[edge === "left" ? "start_time" : "end_time"]: new Date(time),
							[edge === "left" ? "planned_start_date" : "planned_finish_date"]:
								moment(time).format("YYYY-MM-DDTHH:mm:ssZ"),
						}
					} else {
						return card
					}
				})
			})
			return newCards
		})
	}

	const onItemMove = (id: number, time: number) => {
		// Find actvity in each section and update the start and end time
		setCards((prevCards) => {
			const newCards = { ...prevCards }
			Object.keys(newCards).forEach((sectionId) => {
				newCards[sectionId] = newCards[sectionId].map((card) => {
					if (card.id === id) {
						updateActivityPlannedDates(
							id.toString(),
							time,
							time +
								card.end_time.getTime() -
								card.start_time.getTime() -
								1 * 24 * 60 * 60 * 1000,
						) // Subtract 1 day to accomodate calendar library UI changes
						return {
							...card,
							start_time: new Date(time),
							end_time: new Date(
								time + card.end_time.getTime() - card.start_time.getTime(),
							),
							planned_start_date: moment(time).format("YYYY-MM-DDTHH:mm:ssZ"),
							planned_finish_date: moment(
								time +
									card.end_time.getTime() -
									card.start_time.getTime() -
									1 * 24 * 60 * 60 * 1000,
							).format("YYYY-MM-DDTHH:mm:ssZ"), // Subtract 1 day to accomodate calendar library UI changes
						}
					} else {
						return card
					}
				})
			})
			return newCards
		})
	}

	const handleBack = useCallback(() => {
		setDateRange(prev => {
			const daysToMove = moment(prev.end).diff(moment(prev.start), "days");
			return {
				start: moment(prev.start).subtract(daysToMove, "days").valueOf(),
				end: moment(prev.end).subtract(daysToMove, "days").valueOf(),
			}
		});
	}, [])

	// Move timeline forward by 15 days
	const handleNext = useCallback(() => {
		setDateRange(prev => {
			const daysToMove = moment(prev.end).diff(moment(prev.start), "days");
			return {
				start: moment(prev.start).add(daysToMove, "days").valueOf(),
				end: moment(prev.end).add(daysToMove, "days").valueOf(),
			}
		})
	}, [])

	const handleZoomIn = useCallback(() => {
		setDateRange(prev => ({
			start: prev.start,
			end: moment(prev.end).subtract(1, "days").valueOf(),
		}))
	}, [])

	const handleZoomOut = useCallback(() => {
		setDateRange(prev => ({
			start: prev.start,
			end: moment(prev.end).add(1, "days").valueOf(),
		}))
	}, [])

	useEffect(() => {
		if (
			props?.swimlaneActivityListShowStatus &&
			props?.swimlaneWbsListShowStatus
		) {
			getSwimlanesByActivity({
				page: 1,
				page_size: 500,
				project: projectId,
			})
		}
	}, [
		props?.swimlaneActivityListShowStatus,
		props?.swimlaneWbsListShowStatus,
		projectId,
		getSwimlanesByActivity,
	])

	useEffect(() => {
		if (activityData?.results) {
			const activitiesList = activityData.results.map((activity: any) => ({
				id: activity.id,
				label: activity.actv_code_name,
				name: activity.actv_code_name,
			}))
			setSwimlaneActivityList(activitiesList)
		}
	}, [activityData])

	const getLinesFromCards = (cards: Item[]): Lines[] => {
		const cardLines: Lines[] = []
		cards?.forEach((card) => {
			card.lines.forEach((line) => {
				cardLines.push(line)
			})
		})
		return cardLines
	}

	useEffect(() => {
		if (
			!editable ||
			!projectId ||
			(!swimlaneNavState?.selected_wbs &&
				!swimlaneNavState?.selected_activity_code)
		) {
			return
		}
		updateStateApiTrigger({
			wbs_state: swimlaneNavState?.selected_wbs,
			activity_code_state: swimlaneNavState?.selected_activity_code,
			id: projectId?.toString() || "",
			start_date_state: moment(dateRange.start).format("YYYY-MM-DD"),
		})
			.unwrap()
			.then((res) => {
				// State updated for viewing
			})
			.catch((err) => {
				console.log("err", err)
			})
	}, [
		swimlaneNavState,
		editable,
		projectId,
		dateRange.start,
		updateStateApiTrigger,
	])

	return (
		<React.Fragment>
			<Xwrapper>
				{isFetching && !editing && !props?.viewerReload && <AppLoader open />}
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					flexDirection="column"
				>
					<Box position="sticky" top={0} zIndex={"350"}>
						<Grid2
							container
							alignItems="center"
							display="flex"
							justifyContent="space-between"
						>
							{props?.swimlaneActivityListShowStatus &&
								props?.swimlaneWbsListShowStatus && (
									<Box>
										<AppAutocompleteFilter
											disabled={isViewer}
											label={"Activities"}
											placeholder={"search_here"}
											options={swimlaneActivityList}
											value={activityFilter}
											onChange={(value: any) => {
												setActivityFilter(value)
												dispatch(
													updateSwimlaneNav({
														selected_wbs: swimlaneNavState?.selected_wbs,
														navType: "both",
														projectId: projectId ?? 0,
														selected_activity_code: value,
													}),
												)
											}}
										/>
									</Box>
								)}
							<Grid2 spacing={2} container alignItems="center" ml="auto">
								<Grid2 container>
									<Typography color="black">Show Relations</Typography>
									<CustomSwitch
										id="showRelations"
										checked={relationToggleEnabled}
										disabled={relationToggleEnabled && !showRelations} // Disable switch if relations are toggled off programatically to avoid user interaction
										onChange={(e) => {
											setShowRelations(e.target.checked)
											setRelationToggleEnabled(e.target.checked)
										}}
									/>
								</Grid2>
								<Box mb={1} mr={2}>
									<Button
										variant="contained"
										// disabled={isViewer}
										onClick={handleBack}
										style={{ marginRight: "14px" }}
									>
										Back
									</Button>
									<Button
										variant="contained"
										// disabled={isViewer}
										onClick={handleNext}
										style={{ marginRight: "14px" }}
									>
										Next
									</Button>
									<Button
										variant="outlined"
										disabled={dateRange.end - dateRange.start < 6 * 86400 * 1000}
										onClick={handleZoomIn}
									>
										<ZoomIn/>
									</Button>
									<Button
										variant="outlined"
										disabled={dateRange.end - dateRange.start > 14 * 86400 * 1000}
										onClick={handleZoomOut}
									>
										<ZoomOut/>
									</Button>
								</Box>
							</Grid2>
						</Grid2>
					</Box>
					<div className="swimlane-calender-container">
						<Timeline
							key={0}
							groups={[{ id: 0, title: "Main Swimlane" }]}
							items={[]}
							itemTouchSendsClick={false}
							stackItems
							canMove={editable}
							canChangeGroup={false}
							sidebarWidth={0}
							rightSidebarWidth={0}
							minZoom={minZoom}
							maxZoom={maxZoom}
							dragSnap={1000 * 60 * 60 * 24} // 1 day
							useResizeHandle={true}
							visibleTimeStart={dateRange.start}
							visibleTimeEnd={dateRange.end}
						>
							<TimelineHeaders>
								<DateHeader
									style={{ backgroundColor: "#215B90", color: "white" }}
									unit="month"
									className="sticky"
								/>
								<DateHeader
									unit="day"
									style={{
										color: "white",
										minWidth: "50px",
									}}
									labelFormat="DD"
								/>
							</TimelineHeaders>
						</Timeline>
						<div className="timeline-scrollable">
							{sections &&
								Object.keys(sections).map((sectionId) => {
									const section = sections[sectionId]
									return (
										<div key={sectionId}>
											<div
												className="expand"
											>
												<Typography color="white">{section.name}</Typography>
											</div>
											<Timeline
												key={sectionId}
												groups={section.groups}
												items={
													cards && cards[sectionId]?.length > 0
														? cards[sectionId]
														: []
												}
												itemTouchSendsClick={false}
												stackItems
												itemHeightRatio={1}
												itemRenderer={CustomItemRenderer}
												useResizeHandle={true}
												canMove={editable}
												canChangeGroup={false}
												sidebarWidth={0}
												rightSidebarWidth={0}
												minZoom={minZoom}
												maxZoom={maxZoom}
												visibleTimeStart={dateRange.start}
												visibleTimeEnd={dateRange.end}
												buffer={1}
												onItemMove={onItemMove}
												onItemResize={onItemResize}
												onItemDrag={onItemDrag}
												dragSnap={86400 * 1000} // 1 day
											>
												<TimelineHeaders>
													<></>
												</TimelineHeaders>
											</Timeline>
											{showRelations && (
												<div
													key={renderKey[sectionId]}
													style={{
														zIndex: -10,
														overflow: "hidden",
													}}
												>
													<LineConnect
														lines={
															lines && lines[sectionId] ? lines[sectionId] : []
														}
													/>
												</div>
											)}
										</div>
									)
								})}
						</div>
					</div>
				</Box>
			</Xwrapper>
		</React.Fragment>
	)
}

export default SwimlaneCalender

import { FormikErrors, FormikTouched } from "formik"
import * as Yup from "yup"
import {
	ActivityRelationType,
	PredecessorDetail,
	PredecessorDetailCreate,
	SuccessorDetail,
} from "../../api/types/Project"

export type CalendarDaysType = {
	Monday?: boolean
	Tuesday?: boolean
	Wednesday?: boolean
	Thursday?: boolean
	Friday?: boolean
	Saturday?: boolean
	Sunday?: boolean
}

export type CalendarDaysTypeKeys = keyof CalendarDaysType

export interface IActivity {
	id: number
	activity_id: string
	activity_type: string
	activity_status: string
	activity_name: string
	wbs_code: string
	wbs_name: string
	planned_start_date: string
	planned_finish_date: string
	actual_start_date?: string
	actual_finish_date?: string
	original_duration: number
	assignee: number[]
	activity_codes?: number[]
	predecessors: PredecessorDetail[]
	successors: SuccessorDetail[]
	calendar_working_days: CalendarDaysTypeKeys[]
	trade_responsible?: any
	activity_notes?: string
	activity_resources?: string
	activity_cost?: number
	at_completion_duration?: number
	// calendar: any
	notes?: string
}

export type DeleteProposalPayload = {
	session: number
}

export type ICreateActivity = Omit<
	IActivity,
	"id" | "activity_status" | "activity_id" /*  | "predecessors" */
> /*  & {
	predecessors: PredecessorDetailCreate[]
} */

export const AddActivityInitialValue: ICreateActivity = {
	// activity_id: "",
	activity_type: "Task Dependent",
	activity_name: "",
	wbs_code: "",
	wbs_name: "",
	planned_start_date: "",
	planned_finish_date: "",
	actual_start_date: "",
	actual_finish_date: "",
	original_duration: 0,
	assignee: [],
	predecessors: [],
	successors: [],
	calendar_working_days: [],
	trade_responsible: "",
	activity_codes: [],
	activity_notes: "",
	activity_resources: "",
	activity_cost: 0,
	at_completion_duration: 0,
	notes: "",
}

const predecessorValidationSchema = Yup.object().shape({
	// predecessor: Yup.number().required("errors.predecessor_id_required"),
	relationship_type: Yup.string()
		.oneOf([
			ActivityRelationType.FF,
			ActivityRelationType.FS,
			ActivityRelationType.SS,
			ActivityRelationType.SF,
		])
		.required("errors.relationship_type_required"),
	lag_days: Yup.number(),
})

export const AddActivityValidationSchema = Yup.object().shape({
	activity_type: Yup.string().required("errors.activity_type_required"),
	activity_name: Yup.string().required("errors.activity_name_required"),
	wbs_code: Yup.string().required("errors.wbs_code_required"),
	wbs_name: Yup.string().required("errors.wbs_name_required"),
	planned_start_date: Yup.date().required("errors.planned_start_date_required"),
	planned_finish_date: Yup.date()
		.required("errors.planned_finish_date_required")
		.min(
			Yup.ref("planned_start_date"),
			"errors.planned_finish_date_less_than_start",
		),
	// original_duration: Yup.number().moreThan(
	// 	0,
	// 	"errors.original_duration_required",
	// ),
	// calendar_working_days: Yup.array().min(
	// 	1,
	// 	"errors.calendar_working_days_required",
	// ),
	predecessors: Yup.array().test(
		"is-valid",
		"errors.predecessors_invalid",
		(value) => {
			return value && value.length > 0
				? value.every((p) => predecessorValidationSchema.isValidSync(p))
				: true
		},
	),
	// activity_cost: Yup.number(),
})

export interface IAddActivitySectionFormProps {
	values: ICreateActivity
	errors: FormikErrors<ICreateActivity>
	touched: FormikTouched<ICreateActivity>
	handleChange?: (e: React.ChangeEvent<any>) => void
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean,
	) => Promise<void> | Promise<FormikErrors<ICreateActivity>>
	handleBlur: (e: React.FocusEvent<any, Element>) => void
	disabled: boolean
	mode?: string
}

import { FormikErrors, FormikTouched } from "formik"
import * as Yup from "yup"
import {
	PredecessorDetail,
	SubActivityPredecessor,
	SubActivitySuccessor,
} from "../../api/types/Project"

export interface ISubActivity {
	activity: number
	sub_activity_status: string
	sub_activity_name: string
	description: string
	planned_start_date: string
	planned_end_date: string
	actual_start_date: string
	actual_end_date: string
	actual_duration: number
	planned_duration: number
	trade?: any
	activity_codes?: any[]
	assignee: number[]
	notes: string
	predecessors: SubActivityPredecessor[]
	successors: SubActivitySuccessor[]
	dependencies_successors: PredecessorDetail[]
	dependencies: PredecessorDetail[]
}

export const AddSubActivityInitialValue: ISubActivity = {
	sub_activity_name: "",
	description: "",
	planned_start_date: "",
	planned_end_date: "",
	actual_start_date: "",
	actual_end_date: "",
	actual_duration: 0,
	planned_duration: 0,
	trade: "",
	assignee: [],
	notes: "",
	activity_codes: [],
	dependencies: [],
	predecessors: [],
	successors: [],
	activity: 0,
	sub_activity_status: "",
	dependencies_successors: [],
}

export type UpdateSubActivityPayload = {
	id: string
	predecessors?: []
	activity?: number
	successors?: []
	assignee?: any[]
	session: number
	sub_activity_name?: string
	actual_duration?: number
	planned_duration?: number
	planned_start_date?: string
	planned_end_date?: string
	actual_start_date?: string
	actual_end_date?: string
	description?: string
	trade?: any
	notes?: string
}

export const AddSubActivityValidationSchema = Yup.object().shape({
	sub_activity_name: Yup.string().required("sub_activity_name_required"),
	description: Yup.string().required("description_required"),
	planned_start_date: Yup.string().required("planned_start_date_required"),
	planned_end_date: Yup.string().required("planned_end_date_required"),
	activity: Yup.number().required("activity_required"),
	// trade: Yup.string().required("trade_required"),
	// notes: Yup.string().required("notes_required"),
	// dependencies: Yup.array().test(
	// 	"is-valid",
	// 	"errors.predecessors_invalid",
	// 	(value:any) => {
	// 		return value && value.length > 0
	// 			? value.every((p:any) => AddSubActivityValidationSchema.isValidSync(p))
	// 			: true
	// 	},
	//),
	// sub_activity_status: Yup.string().required("sub_activity_status_required"),
})

export interface IAddSubActivitySectionFormProps {
	values: ISubActivity
	errors: FormikErrors<ISubActivity>
	touched: FormikTouched<ISubActivity>
	handleChange?: (e: React.ChangeEvent<any>) => void
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean,
	) => Promise<void> | Promise<FormikErrors<ISubActivity>>
	handleBlur: (e: React.FocusEvent<any, Element>) => void
	disabled: boolean
	activities: any[]
}

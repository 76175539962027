import { Box, Grid2 } from "@mui/material"
import "./AddSubActivity.scss"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import AppButton from "../../components/AppButton/AppButton"
import { useTranslation } from "react-i18next"
import { AddOutlined } from "@mui/icons-material"
import Activity from "./Activity"
import SubActivityDetails from "./SubActivityDetails"
import SubActivityDuration from "./SubActivityDuration"
import SubActivityTrade from "./SubActivityTrade"
import {
	AddSubActivityInitialValue,
	AddSubActivityValidationSchema,
	ISubActivity,
} from "./validation"
import SubActivityOtherDetails from "./SubActivityOtherDetails"
import { useFormik } from "formik"
import {
	useCreateSubActivityMutation,
	useGetAllActivitiesQuery,
} from "../../api/network/projectApiService"
import { toast } from "react-toastify"
import { format as dateFnsFormat, sub } from "date-fns"
import ActivityRelation from "../Activity/ActivityRelation"
import SubActivityRelation from "./SubActivityRelation"
import SubActivityRelationSuccessors from "./SubActivityRelationSuccessors"
import { useLocation, useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import { useEffect, useState } from "react"

export default function AddSubActivity() {
	const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const [createSubActivityTrigger] = useCreateSubActivityMutation()
	const [assigneeName, setAssigneeName] = useState("")
	const location = useLocation()
	const activityId = location?.state?.activityId.toString()
	const projectId = location?.state?.projectId.toString()
	const session = location?.state?.session
	const assignee = [
		...(location?.state?.assigneeOptions ?? []).map((a: any) => a?.id),
	]

	useEffect(() => {
		const names = location?.state?.assigneeOptions?.map((a: any) => a?.name) ?? [];
		setAssigneeName(names.join(', '));
	}, [])

	const { data } = useGetAllActivitiesQuery({
		page: 1,
		page_size: 200,
		project: parseInt(location?.state?.projectId),
	})

	const nav = useNavigate()
	const handleAddSubActivity = (_value: ISubActivity) => {
		const { dependencies, dependencies_successors, actual_start_date, actual_end_date, ...restValue } = _value
		const payload = {
			...restValue,
			activity: activityId ? parseInt(activityId) : null,
			planned_start_date: dateFnsFormat(
				new Date(_value.planned_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			),
			planned_end_date: dateFnsFormat(
				new Date(_value.planned_end_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			),
			trade: _value?.trade?.label?.toString(),
			assignee: assignee,
			session: session,
			sub_activity_status: "planned",
			predecessors: _value.dependencies.map((p) => ({
				predecessor: p.id,
				relationship_type: p.relationship_type,
				lag_days: p.lag_days,
			})),
			successors: _value.dependencies_successors.map((p) => ({
				successor: p.id,
				relationship_type: p.relationship_type,
				lag_days: p.lag_days,
			})),
			...(_value.actual_start_date ? {
				actual_start_date: dateFnsFormat(
					new Date(_value.actual_start_date ?? ""),
					"yyyy-MM-dd hh:mm:ss"
				)
			} : ""),
			...(_value.actual_end_date ? {
				actual_end_date: dateFnsFormat(
					new Date(_value.actual_end_date ?? ""),
					"yyyy-MM-dd hh:mm:ss"
				)
			} : ""),
		}

		createSubActivityTrigger(payload)
			.unwrap()
			.then((response) => {
				console.log("response", response)
				toast.success("Sub Activity Added Successfully")
				resetForm()
				nav(-1)
			})
			.catch((error) => {
				console.log("error", error)
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: {
			...AddSubActivityInitialValue,
		},
		onSubmit: handleAddSubActivity,
		validationSchema: AddSubActivityValidationSchema,
	})

	return (
		<Box className="sub-activity-form-container">
			<form onSubmit={handleSubmit}>
				<DetailNavBar
					title={t("add_sub_activity")}
					showBackButton={true}
					onBackPress={() => {
						nav(
							routeUrls.activity.activityDetails(location?.state?.activityId),
							{
								state: {
									session: location?.state?.session,
									projectId: location?.state?.projectId,
								},
							},
						)
					}}
					rightView={
						<Grid2 display={"flex"} gap={2}>
							<AppButton
								btnSize="medium"
								variant="outlined"
								color="error"
								// disabled={isSavingActivity}
								onClick={() =>
									nav(
										routeUrls.activity.activityDetails(
											location?.state?.activityId,
										),
										{
											state: {
												session: location?.state?.session,
												projectId: location?.state?.projectId,
											},
										},
									)
								}
							>
								{t("cancel")}
							</AppButton>
							<AppButton
								btnSize="medium"
								type="submit"
							// disabled={isSavingActivity}
							// endIcon={<AddOutlined />}
							>
								{"Create"}
							</AppButton>
						</Grid2>
					}
				/>
				<Activity
					values={activityId || values}
					errors={errors}
					activities={data?.results ?? []}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={true}
				/>
				<SubActivityDetails
					values={values}
					errors={errors}
					assigneeOptions={assigneeName}
					activities={data?.results ?? []}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
					mode="add"
				/>
				<SubActivityDuration
					values={values}
					errors={errors}
					touched={touched}
					activities={data?.results ?? []}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
					mode="add"
				/>
				<SubActivityRelation
					values={values}
					activities={data?.results ?? []}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
				<SubActivityRelationSuccessors
					values={values}
					activities={data?.results ?? []}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
				<SubActivityOtherDetails
					values={values}
					errors={errors}
					activities={data?.results ?? []}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
			</form>
		</Box>
	)
}

import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import "./ActivitiesList.scss"
import { useTranslation } from "react-i18next"
import { useMemo, useState, useEffect } from "react"
import {
	useGetActivityProposalsQuery,
	useCreateProposalMutation,
	useGetSwimlanesListByActivityCodeQuery,
	useDeleteProposalByIdMutation,
} from "../../api/network/projectApiService"
import { Tooltip, Grid2, Box, IconButton, Typography } from "@mui/material"
import TableCellText from "../../components/DisplayTable/TableCellText"
import AppAutocompleteFilter from "../../components/AppDropdownFilter/AppAutocompleteFilter"
import {
	activityStatusArray,
	getActivityStatusColor,
	getTotalPages,
} from "../../utils"
import useAppPagination from "../../hooks/useAppPagination"
import { Svgs } from "../../assets/svgs"
import { toast } from "react-toastify"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"
import AppButton from "../../components/AppButton/AppButton"
import { ActivityDetail, ProposalDraft } from "../../api/types/Project"
import { routeUrls } from "../../routes/routeUrls"
import { useLocation, useNavigate } from "react-router-dom"
import { DeleteProposalPayload } from "./validation"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"
import { useDispatch } from "react-redux"
import { updateTeamProposalDrafts } from "../../api/slices/ProposalSlice"
import { AppColors } from "../../theme"

const tableHeaders = [
	{ name: "Task Code", sortable: false },
	{ name: "Activity Name", sortable: false },
	{ name: "Activity Codes", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "View Changes", sortable: false },
	{ name: "Action", sortable: false },
]

const TeamActivitiesList = () => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { t: tUsrList } = useTranslation("translation", {
		keyPrefix: "usersList",
	})
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const [statusFilter, setStatusFilter] = useState<string>()
	const [expandedRowId, setExpandedRowId] = useState<number>(0)
	const [activityCodes, setActivityCodes] = useState<
		{ id: string; label: string }[]
	>([])
	const [selectedActivityCode, setSelectedActivityCode] = useState<string>()
	const [createProposalTrigger] = useCreateProposalMutation()
	const [deleteProposalTrigger] = useDeleteProposalByIdMutation()

	const proposalListState = useSelector(
		(state: RootReduxState) => state.proposalSliceData,
	)

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const {
		data: { results: activitiesData = [], count: totalCount } = {},
		isFetching: isFetchingActivityProposals,
	} = useGetActivityProposalsQuery(
		{
			sessionId: location?.state?.project?.active_session,
			projectId: location?.state?.project?.id || location?.state?.projectId,
			page: currentPage,
			page_size: pageRowsCount,
			status: statusFilter,
			act_code_id: selectedActivityCode || "",
		},
		{
			skip:
				!location?.state?.project?.active_session ||
				(!location?.state?.project?.id && !location?.state?.projectId),
		},
	)

	const handleToggleDetailsOpen = (id: number) => {
		if (expandedRowId === id) {
			setExpandedRowId(0)
			return
		} else {
			setExpandedRowId((prev) => id)
		}
	}

	const getDraftProposalById = (id: any) => {
		return proposalListState.draftActivityProposal.find(
			(proposal) => proposal.activity === id,
		)
	}

	const validateProposalPayload = (
		proposalPayload: ProposalDraft | undefined,
		activityDetail: ActivityDetail,
	) => {
		if (!proposalPayload) {
			toast.error("Proposal payload is missing")
			return false
		}
		if (
			proposalPayload.proposed_planned_start_date &&
			proposalPayload.proposed_planned_finish_date
		) {
			const startDate = proposalPayload.proposed_planned_start_date
			const finishDate = proposalPayload.proposed_planned_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed Finish date cannot be earlier than proposed planned start date",
				)
				return false
			}
		}
		if (proposalPayload.proposed_planned_start_date) {
			const startDate = proposalPayload.proposed_planned_start_date
			const finishDate = activityDetail.planned_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed start date cannot be after the planned finish date",
				)
				return false
			}
		}
		if (proposalPayload.proposed_planned_finish_date) {
			const startDate = activityDetail.planned_start_date
			const finishDate = proposalPayload.proposed_planned_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed Finish date cannot be earlier than the planned start date",
				)
				return false
			}
		}
		if (
			proposalPayload.proposed_actual_start_date &&
			proposalPayload.proposed_actual_finish_date
		) {
			const startDate = proposalPayload.proposed_actual_start_date
			const finishDate = proposalPayload.proposed_actual_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed actual finish date cannot be earlier than proposed actual start date",
				)
				return false
			}
		}
		if (
			proposalPayload.proposed_actual_start_date &&
			activityDetail.actual_finish_date
		) {
			const startDate = proposalPayload.proposed_actual_start_date
			const finishDate = activityDetail.actual_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed actual start date cannot be after the actual finish date",
				)
				return false
			}
		}
		if (
			proposalPayload.proposed_actual_finish_date &&
			activityDetail.actual_start_date
		) {
			const startDate = activityDetail.actual_start_date
			const finishDate = proposalPayload.proposed_actual_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed actual finish date cannot be earlier than the actual start date",
				)
				return false
			}
		}
		return true
	}

	const handleCreateProposal = (id: number, activityDetail: ActivityDetail) => {
		const proposalPayload: ProposalDraft | undefined = getDraftProposalById(id)
		console.log("Create Proposal Payload : ", proposalPayload)
		if (!proposalPayload) {
			toast.error("Error In Creating Proposal")
			return
		}
		if (!validateProposalPayload(proposalPayload, activityDetail)) {
			return
		}
		createProposalTrigger(proposalPayload)
			.unwrap()
			.then((res) => {
				// remove proposal from drafts
				const draftProposalArray =
					proposalListState.draftActivityProposal.filter(
						(proposal) => proposal.activity !== id,
					)
				dispatch(updateTeamProposalDrafts([...draftProposalArray]))
				toast.success("Proposal Created Successfully")
				setExpandedRowId(0)
			})
			.catch((err) => {
				console.log("Activity error", err)
				toast.error("Error In Creating Proposal")
				setExpandedRowId(0)
			})
	}

	const handleDeleteProposal = (id: number) => {
		const proposalId = id.toString()
		const proposalPayload: DeleteProposalPayload = {
			session: location?.state?.project?.active_session,
		}
		deleteProposalTrigger({ id: proposalId, payload: proposalPayload })
			.unwrap()
			.then((res) => {
				console.log("Proposal deleted", res)
				toast.success("Proposal Deleted Successfully")
				setExpandedRowId(0)
			})
			.catch((err) => {
				console.log("Activity error", err)
				toast.error("Error In Deleting Proposal")
				setExpandedRowId(0)
			})
	}

	const { data: activityCodeData, isFetching: isFetchingActivityCodes } =
		useGetSwimlanesListByActivityCodeQuery({
			page: 1,
			page_size: 500,
			project: location?.state?.project?.id || location?.state?.projectId,
		})

	useEffect(() => {
		if (activityCodeData && activityCodeData?.results) {
			setActivityCodes(
				activityCodeData.results.map((actCode: any) => ({
					id: actCode.id,
					label: actCode.actv_code_name,
				})),
			)
		}
	}, [activityCodeData])

	const tableColumns = [
		(data: ActivityDetail) => <span>{data?.task_code}</span>,
		(data: ActivityDetail) => <span>{data?.activity_name}</span>,
		(data: ActivityDetail) => (
			<span>
				{data?.activity_codes && data?.activity_codes?.length > 0
					? data.activity_codes[0]?.actv_code_name
					: ""}
			</span>
		),
		(data: ActivityDetail) => (
			<TableCellText
				sx={{ color: getActivityStatusColor(data.activity_status) }}
			>
				{data.activity_status}
			</TableCellText>
		),
		(data: ActivityDetail) => (
			<IconButton onClick={() => handleToggleDetailsOpen(data.id)}>
				{expandedRowId !== data.id ? (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Show Dates
						</span>
						<Svgs.HideIcon />
					</>
				) : (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Hide Dates
						</span>
						<Svgs.ShowIcon />
					</>
				)}
			</IconButton>
		),
		(data: ActivityDetail) => (
			<Grid2 sx={{ marginTop: "2px" }}>
				<Tooltip title="Submit for review" arrow>
					<Box>
						{data.proposed_status === null && getDraftProposalById(data.id) ? (
							// <Svgs.ReviewIcon
							// 	style={{ cursor: "pointer" }}
							// 	onClick={() => handleCreateProposal(data.id)}
							// />
							<Typography
								color={AppColors.primary}
								onClick={() => handleCreateProposal(data.id, data)}
								style={{ cursor: "pointer" }}
							>
								Send
							</Typography>
						) : data.proposed_status === null ? (
							// <Svgs.ReviewIcon
							// 	style={{ cursor: "pointer", color: "grey" }}
							// 	onClick={() => handleCreateProposal(data.id)}
							// />
							<Typography color="grey">Send</Typography>
						) : data.proposed_status === "pending" ? (
							<Svgs.RejectIcon
								style={{ cursor: "pointer" }}
								onClick={() => handleDeleteProposal(data.proposal_id)}
							/>
						) : null}
					</Box>
				</Tooltip>
			</Grid2>
		),
	]

	const statusOptions = useMemo(() => {
		return activityStatusArray
			.filter((s) => s.status_code !== "completed") // Completed Exclude
			.map((s) => ({ id: s.status_code, label: s.status }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	const handleProposalLogsNavigation = () => {
		navigate("/proposal-logs", {
			state: {
				project: location?.state?.project,
				sessionId: location?.state?.project?.active_session,
			},
		})
	}

	const handleClickNavigation = () => {
		navigate(routeUrls.activity.teamPreviousProposals, {
			state: { projectData: location?.state?.project },
		})
	}

	const handleBack = () => {
		const projectId = location?.state?.project?.id || location?.state?.projectId
		if (projectId) {
			navigate(routeUrls.projects.swimlane(projectId.toString()))
		}
	}

	return (
		<div className="activities-list-container">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<DetailNavBar
					title={"Activity Team Tracker"}
					showBackButton={true}
					onBackPress={handleBack}
				/>
				<div style={{ display: "flex", marginLeft: "auto", gap: "10px" }}>
					<AppButton btnSize="medium" onClick={handleClickNavigation}>
						{"View Previous Proposals"}
					</AppButton>
					<AppButton
						btnSize="medium"
						color="success"
						style={{ marginRight: "20px", color: "white", padding: "12px" }}
						onClick={handleProposalLogsNavigation}
					>
						{"View Proposal Logs"}
					</AppButton>
				</div>
			</div>
			<div className="search-and-filters-container">
				<AppAutocompleteFilter
					disabled={isFetchingActivityCodes}
					label={"Activity Codes"}
					placeholder={"Search by Activity Code"}
					options={activityCodes}
					value={selectedActivityCode}
					onChange={setSelectedActivityCode}
				/>
				<AppDropdownFilter
					placeholder={tUsrList("status")}
					value={statusFilter}
					menuItems={statusOptions}
					onChange={handleStatusFilterChange}
				/>
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={activitiesData || []}
				isFetching={isFetchingActivityProposals}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				expandedRowId={expandedRowId}
				sessionId={location?.state?.project?.active_session}
				screenProp="teamScreen"
				totalPageCount={totalCount}
			/>
		</div>
	)
}

export default TeamActivitiesList

import { Box, Grid2 } from "@mui/material"
import { useMemo } from "react"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"
import { useTranslation } from "react-i18next"
import { IAddActivitySectionFormProps } from "./validation"
import { ActivityCode } from "../../api/types/Project"
import { useGetProjectTradesQuery } from "../../api/network/projectApiService"

interface IActivityTradeFormProps
	extends IAddActivitySectionFormProps {
	projectId: number
}

export default function ActivityTrade({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	disabled,
	handleBlur,
	projectId,
	mode
}: IActivityTradeFormProps) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { data: { results: activityCodeList = [] } = {},
		isFetching } = useGetProjectTradesQuery(projectId)

	const handleActivityCodeChange = (field: string, value: any) => {
		setFieldValue("activity_codes", value ? value.map((v: any) => v.id) : [])
	}

	const activityCodes = useMemo(() => {
		return activityCodeList?.map((trade: ActivityCode) => ({
			id: trade.id,
			value: trade.id,
			label: trade.actv_code_name,
		})) || []
	}, [activityCodeList])

	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 display={"flex"} justifyContent={"space-between"}>
				<Grid2 className="title">{t("type")}</Grid2>
			</Grid2>
			<Grid2>
				<AppAutocompleteFormField
					label={t("activity_code")}
					name={"activity_codes"}
					showLoader={isFetching}
					placeholder={t("select_type")}
					value={values.activity_codes}
					disabled={disabled}
					multiple={true}
					options={activityCodes || []}
					onChange={handleActivityCodeChange}
					onBlur={handleBlur} />
			</Grid2>
		</Box>
	)
}

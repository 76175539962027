import React, { useEffect, useState } from "react"
import "./ProposalLogs.scss"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import { useNavigate, useLocation } from "react-router-dom"
import { getTotalPages } from "../../utils"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { Tooltip, IconButton } from "@mui/material"
import { Svgs } from "../../assets/svgs"
import { useTranslation } from "react-i18next"
import {
	useGetProposalLogsQuery,
	useGetSwimlanesListByActivityCodeQuery,
} from "../../api/network/projectApiService"
import useAppPagination from "../../hooks/useAppPagination"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import { ActivityProposalLogs } from "../../api/types/Project"

const tableHeaders = [
	{ name: "Log ID", sortable: false },
	{ name: "User Name", sortable: false },
	{ name: "Action", sortable: false },
	{ name: "Created At", sortable: false },
	{ name: "Proposal ID", sortable: false },
	{ name: "View Changes", sortable: false },
]

const ProposalLogs = () => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const [activityCodes, setActivityCodes] = useState<{ id: string; label: string }[]>([])
	const [selectedActivityCode, setSelectedActivityCode] = useState<string>()
	const [expandedRowId, setExpandedRowId] = useState<number>(0)
	const location = useLocation()
	const navigate = useNavigate()

	const onBackClick = () => { navigate(-1) }

	const handleToggleDetailsOpen = (id: number) => {
		setExpandedRowId((prev) => (prev === id ? 0 : id))
	}


	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const {
		data: { results: proposalsLogsData = [], count: totalCount } = {},
		isFetching: isFetchingAllProposals,
	} = useGetProposalLogsQuery({
		sessionId: location?.state?.sessionId,
		page: currentPage,
		page_size: pageRowsCount,
		act_code_id: selectedActivityCode || "",
	})

	const { data: activityCodeData, isFetching: isFetchingActivityCodes } =
		useGetSwimlanesListByActivityCodeQuery({
			page: 1,
			page_size: 500,
			project: location?.state?.project
		})

	useEffect(() => {
		if (activityCodeData && activityCodeData?.results) {
			setActivityCodes(
				activityCodeData.results.map((actCode: any) => ({
					id: actCode.id,
					label: actCode.actv_code_name,
				})),
			)
		}
	}, [activityCodeData])

	const tableColumns = [
		(data: ActivityProposalLogs) => (
			<span>{data?.id}</span>
		),
		(data: ActivityProposalLogs) => (
			<Tooltip title={data?.user.email} arrow>
				<span>{data?.user.name}</span>
			</Tooltip>
		),
		(data: ActivityProposalLogs) => (
			<span>{data?.action}</span>
		),
		(data: ActivityProposalLogs) => (
			<span>{formatDateWithMonthName(data.created_at ?? "")}</span>
		),
		(data: ActivityProposalLogs) => (
			<span>{data?.activity_proposal}</span>
		),
		(data: ActivityProposalLogs) => (
			<IconButton onClick={() => handleToggleDetailsOpen(data.id)}>
				{expandedRowId !== data.id ? (
					<>
						<span style={{ fontSize: "12px", color: "black", marginRight: "15px" }}>
							Show Changes
						</span>
						<Svgs.HideIcon />
					</>
				) : (
					<>
						<span style={{ fontSize: "12px", color: "black", marginRight: "15px" }}>
							Hide Changes
						</span>
						<Svgs.ShowIcon />
					</>
				)}
			</IconButton>
		)
	]


	return (
		<div className="logs-list-container">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<DetailNavBar
					title={t("proposal_logs")}
					showBackButton={true}
					onBackPress={onBackClick}
				/>
			</div>
			<div
				className="search-and-filters-container"
				style={{ gap: "20px", marginLeft: "65px" }}>
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={proposalsLogsData || []}
				isFetching={isFetchingAllProposals}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				expandedRowId={expandedRowId}
				screenProp="proposalLogs"
				sessionId={location?.state?.project?.active_session}
				totalPageCount={totalCount}
				customStyle={{ marginLeft: "30px", marginRight: "30px", height: "440px" }}
				paginationStyle={{ marginTop: "5px" }}
			/>
		</div>
	)
}
export default ProposalLogs

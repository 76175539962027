import { Modal, Typography } from "@mui/material"
import {
	useGetAllSubActivitiesByActivityQuery,
	useGetSessionIdQuery,
} from "../../api/network/projectApiService"
import SubActivityListModalView from "./SubActivityListModalView"
import { useTranslation } from "react-i18next"
import useUserRole from "../../hooks/useUserRole"
import AppButton from "../../components/AppButton/AppButton"
import { AddOutlined, Close } from "@mui/icons-material"
import { Grid2 } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import { routeUrls } from "../../routes/routeUrls"
import { UserRole } from "../../api/types/Auth"
import { useEffect } from "react"
import AppLoader from "../../components/AppLoader/AppLoader"

export default function SubActivityModal(props: any) {
	const { open, onClose, editable, activityDetails } = props
	const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const navigate = useNavigate()
	const appUserRole = useUserRole()

	const {
		data: subActivities,
		isFetching,
		refetch,
	} = useGetAllSubActivitiesByActivityQuery(activityDetails?.id)
	const { data, isFetching: isSessionFetching } = useGetSessionIdQuery(
		activityDetails?.project, { skip: !activityDetails?.project }
	)

	useEffect(() => {
		refetch()
	}, [refetch])

	if (isFetching || isSessionFetching) {
		return <AppLoader open />
	}

	const handleAddSubActivity = () => {
		navigate(routeUrls.subActivity.addSubActivity, {
			state: {
				activityId: activityDetails.id,
				session: data?.id,
				assigneeOptions: activityDetails?.assignee,
				projectId: activityDetails?.project,
			},
		})
	}

	return (
		<Modal open={open}>
			<div className="add-member-modal" style={{ width: "80%", height: "80%" }}>
				<div className="modal-heading">
					<Box sx={{ padding: "0 16px", width: "100%" }}>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<Box onClick={onClose} sx={{ padding: 1 }}>
								<Close />
							</Box>
						</div>
						<Grid2
							container
							alignItems="center"
							sx={{ display: "flex", position: "relative" }}
						>
							<Grid2
								sx={{
									textAlign: "center",
									position: "absolute",
									left: "50%",
									transform: "translateX(-50%)",
								}}
							>
								<Typography className="title">
									{"Sub Activities List"}
								</Typography>
							</Grid2>
							<Grid2 sx={{ marginLeft: "auto" }}>
								{(appUserRole === UserRole.SuperAdmin ||
									appUserRole === UserRole.Superintendent ||
									appUserRole === UserRole.CompanyAdmin) && (
									<AppButton
										className="add-button"
										btnSize="medium"
										startIcon={<AddOutlined />}
										onClick={handleAddSubActivity}
									>
										{"Add Sub Activity"}
									</AppButton>
								)}
							</Grid2>
						</Grid2>
					</Box>
				</div>
				<SubActivityListModalView
					subActivities={subActivities?.results || []}
					activitiesDetails={activityDetails}
					session={data?.id}
					editable={editable}
				/>
			</div>
		</Modal>
	)
}

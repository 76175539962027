import React, { useState } from "react"
import { Box, Button, Grid2, Modal, TextField, Typography } from "@mui/material"
import AppButton from "../../components/AppButton/AppButton"
import "./Company.scss"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useFormik } from "formik"
import {
	addCompanyFormInitialValues,
	addCompanyValidationSchema,
	IAddCompanydFields,
} from "./validations"
import { useTranslation } from "react-i18next"
import { AppFileField } from "../../components/AppFileField/AppFileField"
import "./Company.scss"
import { useAddCompanyMutation } from "../../api/network/companyApiService"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"

const AddCompany: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("translation", { keyPrefix: "company" })
	const [logoTouched, setLogoTouched] = useState(false); // Track logo field touch
	const { t: tAdd } = useTranslation("translation", {
		keyPrefix: "company.addCompany",
	})
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "company.errors",
	})
	const [addCompanyApiTrigger] = useAddCompanyMutation()

	const handleSave = (values: IAddCompanydFields) => {
		// create form data
		const formData = new FormData()
		formData.append("company_id", values.companyId)
		formData.append("name", values.companyName)
		formData.append("address", values.companyAddress)
		formData.append("email", values.email)
		formData.append("phone", values.phoneNumber)
		formData.append("logo", values.logo as File)

		// Api call to create company
		addCompanyApiTrigger(formData)
			.unwrap()
			.then((response) => {
				if (response) {
					resetForm()
					toast.success("Company added successfully")
				}
			})
			.catch((error) => {
				// toast.error("Error while adding company")
				console.log("error", error)
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		resetForm,
		isSubmitting,
	} = useFormik({
		initialValues: addCompanyFormInitialValues,
		onSubmit: handleSave,
		validationSchema: addCompanyValidationSchema(tErrors),
		validateOnChange: false,  // Validation won't run on every change
		validateOnBlur: true,  // Validate on blur
	});

	const onCancelClick = () => navigate(routeUrls.dashboardChild.company)

	return (
		<div className="add-company-container">
			<Box sx={{ padding: 4 }}>
				<Box flexDirection="row" display="flex" justifyContent="space-between">
					<Typography className="title">Add Company</Typography>
					<Box>
						<AppButton
							btnSize="medium"
							variant="outlined"
							color="error"
							onClick={onCancelClick}
						>
							Cancel
						</AppButton>
						<AppButton
							variant="contained"
							color="primary"
							sx={{ marginLeft: 1 }}
							onClick={() => handleSubmit()}
						>
							{tAdd("add_company")}
						</AppButton>
					</Box>
				</Box>
				<Grid2
					sx={{
						bgcolor: "white",
						border: "2px solid #fff",
						borderRadius: 8,
						boxShadow: 24,
						padding: 4,
						marginTop: 4,
					}}
					container
					spacing={2}
				>
					<Grid2 size={12}>
						<Typography className="title">{tAdd("details")}</Typography>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("id")}
							name="companyId"
							placeholder={tAdd("add_id")}
							isRequired
							value={values.companyId}
							onChange={(e) => {
								// Add to values, if length is less than 3 and input is only character
								if (
									e.target.value.length <= 3 &&
									/^[a-zA-Z]*$/.test(e.target.value)
								) {
									setFieldValue("companyId", e.target.value.toUpperCase())
								}
							}}
							onBlur={handleBlur}
							touched={touched.companyId}
							errorMsg={errors.companyId ? t(errors.companyId) : ""}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("name")}
							name="companyName"
							placeholder={tAdd("add_name")}
							isRequired
							value={values.companyName}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.companyName}
							errorMsg={errors.companyName ? t(errors.companyName) : ""}
						/>
					</Grid2>
					<Grid2 size={12}>
						<AppFormField
							label={t("address")}
							name="companyAddress"
							placeholder={tAdd("add_address")}
							// isRequired
							value={values.companyAddress}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.companyAddress}
						// errorMsg={errors.companyAddress ? t(errors.companyAddress) : ""}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("email")}
							name="email"
							placeholder={tAdd("add_email")}
							isRequired
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.email}
							errorMsg={errors.email ? t(errors.email) : ""}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("phone_number")}
							name="phoneNumber"
							placeholder={tAdd("add_phone_number")}
							isRequired
							value={values.phoneNumber}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.phoneNumber}
							errorMsg={errors.phoneNumber ? errors.phoneNumber : ""}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFileField
							label={t("logo")}
							id="logo"
							onChange={(file) => {
								setFieldValue("logo", file, true);
								setLogoTouched(true);  // Mark logo field as touched
							}}
							value={values.logo as File}
							fileName={values.logo instanceof File ? values?.logo?.name : ""}
							error={touched.logo || logoTouched ? errors.logo ? errors.logo : "" : ""}
						/>
					</Grid2>
				</Grid2>
			</Box>
		</div>
	)
}

export default AddCompany

import { Box } from "@mui/material"
import DisplayTable from "../../../components/DisplayTable/DisplayTable"
import { useGetAllInvitedUsersQuery } from "../../../api/network/userApiService"
import useAppPagination from "../../../hooks/useAppPagination"
import { formatDateWithMonthName } from "../../../utils/dateTime"
import { useMemo } from "react"
import { getTotalPages } from "../../../utils"

const tableHeaders = [
	{ name: "Name", sortable: false },
	{ name: "Email", sortable: false },
	{ name: "Company", sortable: false },
	{ name: "Invited Date", sortable: false },
	{ name: "Status", sortable: false },
]


export default function InvitedUserList(props: any) {

	console.log(props)

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const { data, isFetching, refetch } = useGetAllInvitedUsersQuery({
		page_size: pageRowsCount,
		page: currentPage,
	})
	const results = data?.results ?? []

	const tableColumns = useMemo(
		() => [
			"name",
			"email",
			"company_name",
			(data: any) => {
				return formatDateWithMonthName(data.invited_date)
			},
			"status",
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	return (
		<Box>
			<DisplayTable
				isFetching={isFetching}
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={results}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(data?.count, data?.page_size)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPageCount={data?.count}
				customStyle={{ height: "370px" }}
			/>
		</Box>
	)
}

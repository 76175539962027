import React from "react";
import { Box, Grid2 } from "@mui/material";
import { AppColors } from "../../theme";
import AppInput from "../../components/AppInput/AppInput";
import { useTranslation } from "react-i18next";
import { ActivityProposalLogs } from "../../api/types/Project";
import { formatToDateInput } from "../../utils/dateTime";
import AppDatePicker from "../../components/AppDatePicker/AppDatePicker"
import moment from "moment"

interface ProposalLogsDatesProps {
    disabled?: boolean;
    proposalLogData: ActivityProposalLogs;
    sessionId: number;
    screenProp?: string;
}

export default function ProposalLogsDates({ proposalLogData }: ProposalLogsDatesProps) {
    const { t } = useTranslation("translation", { keyPrefix: "activity" });
    const maxColumns = 4;
    const infoData = proposalLogData.info.slice(0, maxColumns);

    return (
        <Box>
            <Grid2 container spacing={1} gap={2} marginLeft={2}>
                {infoData.map((item, index) => (
                    <Grid2 width={"23%"} key={index}>
                        {item.from !== null ? (
                            <Grid2 sx={{ mb: 2 }}>
                                <AppDatePicker
                                    label={item.field}
                                    disabled={true}
                                    value={moment(item.from != null ? formatToDateInput(item.from) : "MM-DD-YYYY")}
                                    slotProps={{
                                        inputLabel: {
                                            style: {
                                                color:
                                                    item.field === "Planned Start Date" ||
                                                        item.field === "Planned Finish Date"
                                                        ? AppColors.secondary
                                                        : AppColors.brgOrange
                                            }
                                        },
                                        labelSize: "14px"
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        {item.proposed !== undefined ? (
                            <Grid2 sx={{ mb: 2 }}>
                                <AppDatePicker
                                    label={`Proposed ${item.field}`}
                                    disabled={true}
                                    value={moment(item.proposed != null ? formatToDateInput(item.proposed) : "MM-DD-YYYY")}
                                    slotProps={{
                                        inputLabel: {
                                            style: {
                                                color:
                                                    item.field === "Planned Start Date" ||
                                                        item.field === "Planned Finish Date"
                                                        ? AppColors.secondary
                                                        : AppColors.brgOrange
                                            }
                                        },
                                        labelSize: "14px"
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        <AppDatePicker
                            label={item.proposed !== undefined ? "Modified By Reviewer" : `Proposed ${item.field}`}
                            disabled={true}
                            value={moment(item.to != null ? formatToDateInput(item.to) : "MM-DD-YYYY")}
                            slotProps={{
                                inputLabel: {
                                    style: {
                                        color:
                                            item.field === "Planned Start Date" ||
                                                item.field === "Planned Finish Date"
                                                ? AppColors.secondary
                                                : AppColors.brgOrange
                                    }
                                },
                                labelSize: "14px"
                            }}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    );
}


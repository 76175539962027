import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import "./ActivitiesList.scss"
import { useTranslation } from "react-i18next"
import { useMemo, useState, useEffect } from "react"
import { Tooltip, IconButton } from "@mui/material"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { AppColors } from "../../theme"
import { RootReduxState } from "../../api/store/store"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import TableCellText from "../../components/DisplayTable/TableCellText"
import {
	proposedStatusArray,
	getActivityStatusColor,
	getTotalPages,
} from "../../utils"
import useAppPagination from "../../hooks/useAppPagination"
import { Svgs } from "../../assets/svgs"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"
import {
	useGetAllActivityProposalsQuery,
	useGetSwimlanesListByActivityCodeQuery,
	useUpdateAcceptProposalMutation,
	useUpdateRejectProposalMutation,
} from "../../api/network/projectApiService"
import {
	ActivityProposalDetails,
	ProposalDraft,
	UpdateProposal,
} from "../../api/types/Project"
import { useLocation, useNavigate } from "react-router-dom"
import AppAutocompleteFilter from "../../components/AppDropdownFilter/AppAutocompleteFilter"
import AppButton from "../../components/AppButton/AppButton"

const getTableHeaders = (isViewingActioned: boolean, isTeamMember: boolean) => {
	if (isViewingActioned || isTeamMember) {
		return [
			{ name: "Task Code", sortable: false },
			{ name: "Reporter", sortable: false },
			{ name: "Status", sortable: false },
			{ name: "Activity Code", sortable: false },
			{ name: "Created At", sortable: false },
			{ name: "View Changes", sortable: false },
			{ name: "Proposed Status", sortable: false },
		]
	}
	return [
		{ name: "Task Code", sortable: false },
		{ name: "Activity Name", sortable: false },
		{ name: "Reporter", sortable: false },
		{ name: "Status", sortable: false },
		{ name: "Activity Code", sortable: false },
		{ name: "View Changes", sortable: false },
		{ name: "Actions", sortable: false },
	]
}

const ReviewerActivitiesList = () => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const [updateAcceptProposalTrigger] = useUpdateAcceptProposalMutation()
	const [updateRejectProposalTrigger] = useUpdateRejectProposalMutation()

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const [statusFilter, setStatusFilter] = useState<string>()
	const [searchTerm, setSearchTerm] = useState("")
	const [projectId, setProjectId] = useState(null)
	const [screenProp, setScreenProp] = useState<string>("")
	const [sessionId, setSessionId] = useState(null)
	const [isViewingActioned, setIsViewingActioned] = useState(false)
	const [expandedRowId, setExpandedRowId] = useState<number>(0)
	const tableHeaders = getTableHeaders(isViewingActioned, false)
	const [activityCodes, setActivityCodes] = useState<
		{ id: string; label: string }[]
	>([])
	const [selectedActivityCode, setSelectedActivityCode] = useState<string>()

	const handleToggleDetailsOpen = (id: number) => {
		setExpandedRowId((prev) => (prev === id ? 0 : id))
	}

	const proposalListState = useSelector(
		(state: RootReduxState) => state.proposalSliceData,
	)

	const getDraftProposalById = (id: any) => {
		return proposalListState.draftActivityProposal.find(
			(proposal) => proposal.activity === id,
		)
	}

	const validatePayload = (
		acceptProposalPayload: UpdateProposal,
		proposalDetail: ActivityProposalDetails,
	) => {
		if (!acceptProposalPayload) {
			toast.error("Proposal payload is missing")
			return false
		}
		if (
			acceptProposalPayload.proposed_planned_start_date_by_SI &&
			acceptProposalPayload.proposed_planned_finish_date_by_SI
		) {
			const startDate = acceptProposalPayload.proposed_planned_start_date_by_SI
			const finishDate =
				acceptProposalPayload.proposed_planned_finish_date_by_SI
			if (finishDate < startDate) {
				toast.error(
					"Proposed Finish date cannot be earlier than proposed planned start date",
				)
				return false
			}
		}
		if (acceptProposalPayload.proposed_planned_start_date_by_SI) {
			const startDate = acceptProposalPayload.proposed_planned_start_date_by_SI
			const finishDate =
				proposalDetail.activity_current_dates.planned_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed start date cannot be after the planned finish date",
				)
				return false
			}
		}
		if (acceptProposalPayload.proposed_planned_finish_date_by_SI) {
			const startDate = proposalDetail.activity_current_dates.planned_start_date
			const finishDate =
				acceptProposalPayload.proposed_planned_finish_date_by_SI
			if (finishDate < startDate) {
				toast.error(
					"Proposed Finish date cannot be earlier than the planned start date",
				)
				return false
			}
		}
		if (
			acceptProposalPayload.proposed_actual_start_date_by_SI &&
			acceptProposalPayload.proposed_actual_finish_date_by_SI
		) {
			const startDate = acceptProposalPayload.proposed_actual_start_date_by_SI
			const finishDate = acceptProposalPayload.proposed_actual_finish_date_by_SI
			if (finishDate < startDate) {
				toast.error(
					"Proposed Actual Finish date cannot be earlier than proposed actual start date",
				)
				return false
			}
		}
		if (
			acceptProposalPayload.proposed_actual_start_date_by_SI &&
			proposalDetail.activity_current_dates.actual_finish_date
		) {
			const startDate = acceptProposalPayload.proposed_actual_start_date_by_SI
			const finishDate =
				proposalDetail?.activity_current_dates?.actual_finish_date
			if (finishDate < startDate) {
				toast.error(
					"Proposed actual start date cannot be after the actual finish date",
				)
				return false
			}
		}
		if (
			acceptProposalPayload.proposed_actual_finish_date_by_SI &&
			proposalDetail?.activity_current_dates?.actual_start_date
		) {
			const startDate =
				proposalDetail?.activity_current_dates?.actual_start_date
			const finishDate = acceptProposalPayload.proposed_actual_finish_date_by_SI
			if (finishDate < startDate) {
				toast.error(
					"Proposed actual finish date cannot be earlier than the actual start date",
				)
				return false
			}
		}
		return true
	}

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const {
		data: { results: proposalsData = [], count: totalCount } = {},
		isFetching: isFetchingAllProposals,
	} = useGetAllActivityProposalsQuery({
		sessionId: location?.state?.project?.active_session || sessionId,
		projectId:
			location?.state?.project?.id || location?.state?.projectId || projectId,
		page: currentPage,
		page_size: pageRowsCount,
		status: statusFilter
			? statusFilter
			: isViewingActioned
				? "rejected,approved"
				: "pending",
		proposed_by: searchTerm,
		act_code_id: selectedActivityCode || "",
	})

	const { data: activityCodeData, isFetching: isFetchingActivityCodes } =
		useGetSwimlanesListByActivityCodeQuery({
			page: 1,
			page_size: 500,
			project:
				location?.state?.project?.id || location?.state?.projectId || projectId,
		})

	const handleAcceptProposal = (data: ActivityProposalDetails) => {
		const proposalPayload: ProposalDraft | undefined = getDraftProposalById(
			data.id,
		)
		const acceptProposalPayload: UpdateProposal = {
			session: data.session,
			...(proposalPayload && proposalPayload?.proposed_planned_start_date
				? {
						proposed_planned_start_date_by_SI:
							proposalPayload?.proposed_planned_start_date,
					}
				: 0),
			...(proposalPayload && proposalPayload?.proposed_planned_finish_date
				? {
						proposed_planned_finish_date_by_SI:
							proposalPayload?.proposed_planned_finish_date,
					}
				: 0),
			...(proposalPayload && proposalPayload?.proposed_actual_start_date
				? {
						proposed_actual_start_date_by_SI:
							proposalPayload?.proposed_actual_start_date,
					}
				: 0),
			...(proposalPayload && proposalPayload?.proposed_actual_finish_date
				? {
						proposed_actual_finish_date_by_SI:
							proposalPayload?.proposed_actual_finish_date,
					}
				: 0),
		}
		if (!validatePayload(acceptProposalPayload, data)) {
			return
		}
		updateAcceptProposalTrigger({
			id: data.id.toString(),
			payload: acceptProposalPayload,
		})
			.unwrap()
			.then((res) => {
				console.log("Response from Accepted Proposal", res)
				toast.success("Proposal Accepted")
				setExpandedRowId(0)
			})
			.catch((err) => {
				console.error("Error accepting proposal: ", err)
				toast.error("Error In Proposal")
				setExpandedRowId(0)
			})
	}

	const handleRejectProposal = (data: ActivityProposalDetails) => {
		const rejectProposalPayload: UpdateProposal = {
			session: data.session,
		}
		updateRejectProposalTrigger({
			id: data.id.toString(),
			payload: rejectProposalPayload,
		})
			.unwrap()
			.then((res) => {
				console.log("Response from Rejected Proposal", res)
				toast.success("Proposal Rejected")
				setExpandedRowId(0)
			})
			.catch((err) => {
				console.error("Error rejecting proposal: ", err)
				toast.error("Error In Proposal")
				setExpandedRowId(0)
			})
	}

	useEffect(() => {
		if (activityCodeData && activityCodeData?.results) {
			setActivityCodes(
				activityCodeData.results.map((actCode: any) => ({
					id: actCode.id,
					label: actCode.actv_code_name,
				})),
			)
		}
	}, [activityCodeData])

	useEffect(() => {
		if (location?.state?.projectData) {
			setProjectId(location?.state?.projectData?.id)
			setSessionId(location?.state?.projectData?.active_session)
		}
	}, [location])

	const actionedProposalColumns = [
		(data: ActivityProposalDetails) => (
			<Tooltip title={data?.activity?.activity_name} arrow>
				<span>{data?.activity?.task_code}</span>
			</Tooltip>
		),
		(data: ActivityProposalDetails) => (
			<Tooltip title={data?.proposed_by.email} arrow>
				<span>{data?.proposed_by.name}</span>
			</Tooltip>
		),
		(data: ActivityProposalDetails) => (
			<TableCellText
				sx={{ color: getActivityStatusColor(data.activity.status) }}
			>
				{data?.activity?.status}
			</TableCellText>
		),
		(data: ActivityProposalDetails) => (
			<span>
				{data?.activity?.activity_code &&
				data?.activity?.activity_code?.length > 0
					? data?.activity?.activity_code
							.map((code) => code.actv_code_name)
							.join(", ")
					: ""}
			</span>
		),
		(data: ActivityProposalDetails) => (
			<span>{formatDateWithMonthName(data.created_at ?? "")}</span>
		),
		(data: ActivityProposalDetails) => (
			<IconButton onClick={() => handleToggleDetailsOpen(data.id)}>
				{expandedRowId !== data.id ? (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Show Changes
						</span>
						<Svgs.HideIcon />
					</>
				) : (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Hide Changes
						</span>
						<Svgs.ShowIcon />
					</>
				)}
			</IconButton>
		),
		(data: ActivityProposalDetails) =>
			data.status === "approved" ? (
				<span style={{ color: AppColors.primaryGreen }}>Approved</span>
			) : data.status === "rejected" ? (
				<span style={{ color: AppColors.error }}>Rejected</span>
			) : null,
	]

	const currentProposalColumns = [
		(data: ActivityProposalDetails) => <span>{data?.activity?.task_code}</span>,
		(data: ActivityProposalDetails) => (
			<span>{data?.activity?.activity_name}</span>
		),
		(data: ActivityProposalDetails) => (
			<Tooltip title={data?.proposed_by.email} arrow>
				<span>{data?.proposed_by.name}</span>
			</Tooltip>
		),
		(data: ActivityProposalDetails) => (
			<TableCellText
				sx={{ color: getActivityStatusColor(data.activity.status) }}
			>
				{data?.activity?.status}
			</TableCellText>
		),
		(data: ActivityProposalDetails) => (
			<span>
				{data?.activity?.activity_code &&
				data?.activity?.activity_code?.length > 0
					? data?.activity?.activity_code[0].actv_code_name
					: ""}
			</span>
		),
		(data: ActivityProposalDetails) => (
			<IconButton onClick={() => handleToggleDetailsOpen(data.id)}>
				{expandedRowId !== data.id ? (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Show Changes
						</span>
						<Svgs.HideIcon />
					</>
				) : (
					<>
						<span
							style={{ fontSize: "12px", color: "black", marginRight: "15px" }}
						>
							Hide Changes
						</span>
						<Svgs.ShowIcon />
					</>
				)}
			</IconButton>
		),
		(data: ActivityProposalDetails) => (
			<>
				<Svgs.AcceptIcon
					style={{ cursor: "pointer", marginRight: "20px" }}
					onClick={() => handleAcceptProposal(data)}
				/>
				<Svgs.RejectIcon
					style={{ cursor: "pointer" }}
					onClick={() => handleRejectProposal(data)}
				/>
			</>
		),
	]

	const tableColumns = [
		...(isViewingActioned ? actionedProposalColumns : currentProposalColumns),
	]

	const statusOptions = useMemo(() => {
		return proposedStatusArray.map((s) => ({
			id: s.status_code,
			label: s.status,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
		setIsViewingActioned(!!status)
	}

	const handleProposalLogsNavigation = () => {
		navigate("/proposal-logs", {
			state: {
				project: location?.state?.project,
				sessionId: location?.state?.project?.active_session,
			},
		})
	}

	const handleBack = () => {
		if (
			location?.state?.project?.id ||
			location?.state?.projectId ||
			projectId
		) {
			navigate(-1)
		}
	}

	return (
		<div className="activities-list-container">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<DetailNavBar
					title={t("activity_reviewer_screen")}
					showBackButton={true}
					onBackPress={handleBack}
				/>
				<AppButton
					btnSize="medium"
					style={{ marginRight: "20px" }}
					onClick={handleProposalLogsNavigation}
				>
					{t("view_proposal_logs")}
				</AppButton>
			</div>
			<div
				className="search-and-filters-container"
				style={{ gap: "20px", marginLeft: "65px" }}
			>
				<AppAutocompleteFilter
					disabled={isFetchingActivityCodes}
					label={t("activity_code")}
					placeholder={t("search_by_activity_code")}
					options={activityCodes}
					value={selectedActivityCode}
					onChange={setSelectedActivityCode}
				/>
				<AppDropdownFilter
					placeholder={t("proposed_status")}
					value={statusFilter}
					menuItems={statusOptions}
					onChange={handleStatusFilterChange}
				/>
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={proposalsData || []}
				isFetching={isFetchingAllProposals}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				sessionId={location?.state?.project?.active_session}
				expandedRowId={expandedRowId}
				screenProp="reviewerScreen"
				totalPageCount={totalCount}
			/>
		</div>
	)
}

export default ReviewerActivitiesList

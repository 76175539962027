import React from "react"
import Xarrow, { Xwrapper } from "react-xarrows"

type LineType = {
	id: string
	linkedTo?: string
	linkType?: string
}

type LineTypeProps = {
	lines: LineType[]
}

const LineConnect: React.FC<LineTypeProps> = ({ lines }) => {
	return (
		<div>
			{lines.map((line: LineType, index) =>
				line.linkedTo ? (
					<Xarrow
						key={`${line.id}-${line.linkedTo}`}
						start={line.id}
						end={line.linkedTo}
						color="#000000"
						strokeWidth={2}
						headSize={5}
						showTail
						showHead
						labels={{
							middle: (
								<div
									style={{
										fontSize: "10px",
										padding: 1,
										backgroundColor: "rgba(0,0,0,.2)",
										borderRadius: 2,
									}}
								>
									{line.linkType}
								</div>
							),
						}}
						path="smooth"
						// gridBreak="70%"
						zIndex={0}
					/>
				) : null,
			)}
		</div>
	)
}

export default LineConnect

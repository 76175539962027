import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { RESPONSE_CODE } from "../../constants/constants"
import { toast } from "react-toastify"
import { logoutUser } from "../../utils/authUtils"

let isToastAlreadyShown = false;

export const handleAuthError = (error: FetchBaseQueryError) => {
	console.error("error captured", error);

	const showToast = (message: string) => {
		if (!isToastAlreadyShown) {
			toast.error(message);
			isToastAlreadyShown = true;
			setTimeout(() => {
				isToastAlreadyShown = false;
			}, 3000);
		}
	};
	if (error.status === RESPONSE_CODE.BAD_REQUEST) {
		const data: any = error.data;
		if (data?.error) {
			showToast(data.error);
		}
	}
	else if (error.status === RESPONSE_CODE.UNAUTHORIZED) {
		const data: any = error.data;
		if (data?.detail) {
			showToast(data.detail);
		} else {
			showToast("Session expired.");
		}
		// Log out the user in case of an Unauthorized error
		logoutUser();
	}
};



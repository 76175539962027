import { Box, Grid2 } from "@mui/material"
import { AppColors } from "../../theme"
import "./AddActivity.scss"
import AppInput from "../../components/AppInput/AppInput"
import { useTranslation } from "react-i18next"
import { ProposalDraft, ActivityProposalDetails } from "../../api/types/Project"
import { formatToDate, formatToDateInput } from "../../utils/dateTime"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"
import { useDispatch } from "react-redux"
import { updateTeamProposalDrafts } from "../../api/slices/ProposalSlice"
import AppDatePicker from "../../components/AppDatePicker/AppDatePicker"
import moment from "moment"

interface ReviewerProposalDatesProps {
	disabled?: boolean
	proposalData: ActivityProposalDetails
	sessionId: number
	screenProp?: string
}

export default function ReviewProposalDates({
	disabled,
	proposalData,
	sessionId,
	screenProp,
}: ReviewerProposalDatesProps) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const dispatch = useDispatch()
	const proposalListState = useSelector(
		(state: RootReduxState) => state.proposalSliceData,
	)
	const isDisabled =
		proposalData?.status === "approved" || proposalData?.status === "rejected"
			? true
			: false

	const formatDateWithCurrentTime = (dateStr: string): string => {
		const formattedDate = dateStr.split("T")[0]
		const now = new Date()
		const hours = String(now.getHours()).padStart(2, "0")
		const minutes = String(now.getMinutes()).padStart(2, "0")
		const seconds = String(now.getSeconds()).padStart(2, "0")
		const finalDate = `${formattedDate} ${hours}:${minutes}:${seconds}`
		return finalDate
	}

	const availableProposal = proposalListState.draftActivityProposal.find(
		(proposal) => proposal.activity === proposalData.id,
	)

	const changeProposedDateByType = (
		type: string,
		proposal: ProposalDraft,
		date: string,
	) => {
		switch (type) {
			case "Proposed Start":
				proposal.proposed_planned_start_date = formatDateWithCurrentTime(date)
				break
			case "Proposed Finish":
				proposal.proposed_planned_finish_date = formatDateWithCurrentTime(date)
				break
			case "Actual Start":
				proposal.proposed_actual_start_date = formatDateWithCurrentTime(date)
				break
			case "Actual Finish":
				proposal.proposed_actual_finish_date = formatDateWithCurrentTime(date)
				break
		}
	}

	const onDateChange = (
		date: string,
		name:
			| "Proposed Start"
			| "Proposed Finish"
			| "Actual Start"
			| "Actual Finish",
	) => {
		if (availableProposal) {
			const updatedProposal = { ...availableProposal }
			changeProposedDateByType(name, updatedProposal, date)
			const proposalArray = [...proposalListState.draftActivityProposal]
			const index = proposalArray.findIndex(
				(proposal) => proposal.activity === proposalData.id,
			)
			proposalArray[index] = updatedProposal
			dispatch(updateTeamProposalDrafts(proposalArray))
		} else {
			const newProposal = {
				session: sessionId,
				activity: proposalData.id,
			}
			changeProposedDateByType(name, newProposal, date)
			dispatch(
				updateTeamProposalDrafts([
					...proposalListState.draftActivityProposal,
					newProposal,
				]),
			)
		}
	}

	return (
		<Box>
			<Grid2
				display={"flex"}
				justifyContent={"space-between"}
				gap={4}
				marginBottom={"20px"}
			>
				<Grid2 width={"50%"}>
					<AppDatePicker
						label={"Planned Start Date"}
						name={"planned_start_date"}
						disabled={true}
						value={moment(proposalData?.activity_current_dates.planned_start_date || " ")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.secondary } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
				<Grid2 width={"50%"}>
					<AppDatePicker
						label={"Planned Finish Date"}
						name={"planned_finish_date"}
						disabled={true}
						value={moment(proposalData?.activity_current_dates.planned_finish_date || " ")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.secondary } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
				<Grid2 width={"50%"}>
					<AppDatePicker
						label={"Actual Start Date"}
						name={"actual_start_date"}
						disabled={true}
						value={moment(proposalData?.activity_current_dates?.actual_start_date || " ")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.brgOrange } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
				<Grid2 width={"50%"}>
					<AppDatePicker
						label={"Actual Finish Date"}
						name={"actual_finish_date"}
						disabled={true}
						value={moment(proposalData?.activity_current_dates?.actual_finish_date || " ")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.brgOrange } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
			</Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={4}>
				<Grid2 width={"50%"}>
					<AppDatePicker
						type="date"
						label={"Proposed Planned Start Date"}
						name={"proposed_planned_start_date"}
						disabled={
							isDisabled ||
							proposalData?.activity_current_dates?.actual_finish_date !=
							null ||
							proposalData?.proposed_planned_start_date == null ||
							proposalData?.activity_current_dates?.actual_start_date != null
						}
						value={moment(
							availableProposal &&
								availableProposal?.proposed_planned_start_date
								? availableProposal?.proposed_planned_start_date
								: proposalData?.proposed_planned_start_date
									? proposalData?.proposed_planned_start_date
									: (proposalData?.activity_current_dates?.planned_start_date ??
										" "),
						)}
						onChange={(e: string) => onDateChange(e, "Proposed Start")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.secondary } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
				<Grid2 width={"50%"}>
					<AppDatePicker
						label={"Proposed Planned Finish Date"}
						name={"proposed_planned_finish_date"}
						disabled={
							isDisabled ||
							proposalData?.activity_current_dates?.actual_finish_date !=
							null ||
							proposalData?.proposed_planned_finish_date == null
						}
						value={moment(
							availableProposal &&
								availableProposal?.proposed_planned_finish_date
								? availableProposal?.proposed_planned_finish_date
								: proposalData?.proposed_planned_finish_date
									? proposalData?.proposed_planned_finish_date
									: (proposalData?.activity_current_dates
										?.planned_finish_date ?? " "),
						)}
						onChange={(e: string) => onDateChange(e, "Proposed Finish")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.secondary } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
				<Grid2 width={"50%"}>
					<AppDatePicker
						type="date"
						label={"Proposed Actual Start Date"}
						name={"proposed_actual_start_date"}
						disabled={
							isDisabled ||
							proposalData?.activity_current_dates?.actual_start_date != null ||
							proposalData?.activity_current_dates?.actual_finish_date !=
							null ||
							proposalData?.proposed_actual_start_date == null
						}
						value={moment(
							availableProposal && availableProposal?.proposed_actual_start_date
								? availableProposal?.proposed_actual_start_date
								: proposalData?.proposed_actual_start_date
									? proposalData?.proposed_actual_start_date
									: (proposalData?.activity_current_dates?.actual_start_date ??
										" "),
						)}
						onChange={(e: string) => onDateChange(e, "Actual Start")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.brgOrange } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
				<Grid2 width={"50%"}>
					<AppDatePicker
						label={"Proposed Actual Finish Date"}
						name={"proposed_actual_finish_date"}
						disabled={
							isDisabled ||
							proposalData?.activity_current_dates?.actual_finish_date !=
							null ||
							proposalData?.proposed_actual_finish_date == null
						}
						value={moment(
							availableProposal &&
								availableProposal?.proposed_actual_finish_date
								? availableProposal?.proposed_actual_finish_date
								: proposalData?.proposed_actual_finish_date
									? proposalData?.proposed_actual_finish_date
									: (proposalData?.activity_current_dates?.actual_finish_date ??
										" "),
						)}
						onChange={(e: string) => onDateChange(e, "Actual Finish")}
						slotProps={{
							input: { style: { height: "40px" } },
							inputLabel: { style: { color: AppColors.brgOrange } },
							labelSize: "14px"
						}}
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
}

/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Grid2, LinearProgress, Typography } from "@mui/material"
import { Drawer } from "../../components/AppSidebar/AppSidebar"
import SwimlaneCalender from "./SwimlaneCalender"
import SwimlaneLists from "./SwimlaneLists"
import { Svgs } from "../../assets/svgs"
import AppButton from "../../components/AppButton/AppButton"
import { useEffect, useMemo, useState } from "react"
import BeginSession from "../Session/BeginSession"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { useNavigate, useParams } from "react-router-dom"
import {
	downloadFile,
	useEndSessionMutation,
	useGetProjectDetailsQuery,
	useGetSessionIdQuery,
} from "../../api/network/projectApiService"
import { AddOutlined } from "@mui/icons-material"
import { routeUrls } from "../../routes/routeUrls"
import AppConfirmationModal from "../../components/AppConfirmationModal/AppConfirmationModal"
import { toast } from "react-toastify"
import { getProjectStatusColor } from "../../utils"
import useUserRole from "../../hooks/useUserRole"
import { ProjectUserRole, UserRole } from "../../api/types/Auth"

const Swimlane = () => {
	const [openBeginSessionModal, setOpenBeginSessionModal] = useState(false)
	const [endSessionConfirmation, setEndSessionConfirmation] = useState(false)
	const [editable, setEditable] = useState(false)
	const [hideAdministrativeAction, setHideAdministrativeAction] =
		useState(false)
	const [endSessionTrigger] = useEndSessionMutation()
	const param = useParams()
	const appUserRole = useUserRole()
	const projectId = param?.id
	const {
		data: projectData,
		isFetching,
		isError,
		error,
		refetch,
		isLoading,
	} = useGetProjectDetailsQuery(projectId?.toString() ?? "", {
		skip: !projectId,
	})

	const {
		data: sessionDetails,
		isLoading: isSessionLoading,
		refetch: refetchSession,
		error: sessionError,
	} = useGetSessionIdQuery(projectData?.id, {
		skip: !projectData?.id,
	})

	const navigate = useNavigate()

	const handleConfirmEndSession = () => {
		setEndSessionConfirmation(false)
		endSessionTrigger(projectData?.active_session)
			.unwrap()
			.then((res) => {
				toast.success("Session Ended Successfully")
				// Set swimlane as on editable
				setEditable(false)
				refetchSession()
			})
			.catch((err) => {
				toast.error("Failed to end session")
			})
	}
	const handleBeginSession = () => {
		setOpenBeginSessionModal(true)
		refetchSession()
	}

	const handleCloseSession = () => {
		setOpenBeginSessionModal(false)
		refetchSession()
	}

	const handleEndSession = () => {
		setEndSessionConfirmation(true)
	}

	const navigateToAddActivity = () => {
		navigate(routeUrls.activity.addActivity, {
			state: { project: projectData },
		})
	}

	const handleBackClick = () => {
		navigate(routeUrls.projects.viewAndEdit(projectId), {
			state: { projectId },
		})
	}

	const handleProposalNavigation = () => {
		if (projectData) {
			if (isAdmin) {
				navigate(routeUrls.activity.activityReview, {
					state: { project: projectData },
				})
			} else if (projectData.current_user_role === ProjectUserRole.TeamMember) {
				navigate(routeUrls.activity.teamMemberActivity, {
					state: { project: projectData },
				})
			}
		}
	}

	const handleLogoClick = () => {
		if (
			appUserRole === UserRole.SuperAdmin ||
			appUserRole === UserRole.CompanyAdmin
		) {
			navigate(routeUrls.dashboard)
		} else {
			navigate(routeUrls.dashboard)
		}
	}

	const isAdmin = useMemo(() => {
		if (
			appUserRole === UserRole.SuperAdmin ||
			appUserRole === UserRole.CompanyAdmin ||
			projectData?.current_user_role === ProjectUserRole.SuperIntendent
		) {
			return true
		}
	}, [appUserRole, projectData])

	useEffect(() => {
		if (projectData) {
			if (sessionDetails && !sessionError && appUserRole) {
				// Session is active and user is of admin or superintendant privelage
				if (
					appUserRole === UserRole.SuperAdmin ||
					appUserRole === UserRole.CompanyAdmin ||
					projectData.current_user_role === ProjectUserRole.SuperIntendent
				) {
					setEditable(true)
				}
			} else {
				// session is not active
				setEditable(false)
				const timeout = setTimeout(() => {
					refetchSession()
				}, 5000) // 5 seconds
				return () => clearTimeout(timeout)
			}
			if (
				projectData.current_user_role === ProjectUserRole.TeamMember ||
				projectData.current_user_role === ProjectUserRole.Viewer
			) {
				setHideAdministrativeAction(true)
			}
		}
	}, [appUserRole, projectData, sessionDetails, sessionError])

	const onDownloadFileClick = () => {
		if (projectData?.new_file) {
			console.log("Downloading file", projectData?.new_file)
			// // Get name of file from url (remove params and base url)
			// const fileName = projectData?.new_file.split("?").

			// return
			downloadFile(projectData?.new_file, `${projectData?.id?.toString()}.xer`)
		}
	}

	return (
		<>
			{endSessionConfirmation && (
				<AppConfirmationModal
					open={true}
					confirmationMessage={"Are you sure you want to end the session?"}
					onConfirm={handleConfirmEndSession}
					onClose={() => setEndSessionConfirmation(false)}
					closeButtonText={"Close"}
					confirmButtonText={"Ok"}
				/>
			)}
			{openBeginSessionModal && (
				<BeginSession
					project={param?.id || 1}
					open={openBeginSessionModal}
					onClose={handleCloseSession}
				/>
			)}
			<Box display={"flex"}>
				<Box>
					<Drawer variant="permanent" open={true}>
						<Box
							display="flex"
							flexDirection="column"
							padding="14px 12px 12px 12px"
						>
							<Box
								display="flex"
								flex={1}
								justifyContent="space-between"
								alignItems={"center"}
							>
								<Svgs.SidebarLogo
									onClick={handleLogoClick}
									style={{ cursor: "pointer" }}
								/>
							</Box>
							<Box display={"flex"} gap={2} mt={2}>
								<Typography>
									<Svgs.BackArrow
										// onClick={() => {
										// 	navigate(
										// 		routeUrls.projects.viewAndEdit(String(param?.id || 0)),
										// 	)
										// }}
										onClick={handleBackClick}
									/>
								</Typography>
								<Typography
									style={{
										color: "#fff",
										fontSize: "17px",
										fontWeight: "700",
										marginTop: "8px",
									}}
								>
									{projectData?.name}
								</Typography>
							</Box>
							<Box
								style={{
									color: "#fff",
									fontSize: "14px",
									fontWeight: "700",
								}}
							>
								<Grid2 mb={2}>
									{`Progress`}
									<span style={{ color: "#54AE2A" }}>{` (${80}%)`}</span>{" "}
								</Grid2>
								<LinearProgress
									variant="determinate"
									value={80}
									color="success"
								/>
							</Box>
							<Box ml={-1.5} mt={1}>
								<AppButton
									// onClick={handleProjectStatusMenuOpen}
									variant="text"
									// endIcon={<ExpandMoreOutlined />}
									btnSize="medium"
									sx={{
										color: getProjectStatusColor(projectData?.status || ""),
									}}
									startIcon={
										<Svgs.ProjectStatusLogo
											color={getProjectStatusColor(projectData?.status || "")}
										/>
									}
								>
									{projectData?.status}
								</AppButton>
							</Box>
						</Box>
						<SwimlaneLists
							swimlaneWbsListShowStatus={projectData?.wbs_code_swimlane_enabled}
							swimlaneActivityListShowStatus={
								projectData?.activity_code_swimlane_enabled
							}
							projectId={projectId ? parseInt(projectId) : null}
							clickable={
								projectData?.current_user_role !== ProjectUserRole.Viewer
							}
						/>
					</Drawer>
				</Box>

				<Box
					flex={1}
					padding={2}
					display="flex"
					flexDirection="column"
					style={{ overflowY: "hidden" }}
					height={"100vh"}
				>
					<Box padding={2} position="sticky" top={0}>
						<Grid2 display={"flex"} justifyContent={"space-between"} gap={1}>
							<Typography fontWeight={700} fontSize={26}>
								{!sessionError && sessionDetails?.session_name
									? sessionDetails?.session_name
									: ""}
							</Typography>
							{(!sessionDetails || sessionError) && isAdmin ? (
								<Box>
									<AppButton
										btnSize="medium"
										endIcon={<Svgs.UploadIcon />}
										style={{ marginRight: 20 }}
										disabled={appUserRole !== UserRole.SuperAdmin}
										onClick={onDownloadFileClick}
									>
										{"Export"}
									</AppButton>
									<AppButton
										btnSize="medium"
										variant="contained"
										color="success"
										style={{ color: "white" }}
										disabled={hideAdministrativeAction}
										endIcon={<Svgs.RightArrow />}
										onClick={handleBeginSession}
									>
										{"Begin Session"}
									</AppButton>
								</Box>
							) : (
								<Grid2 display={"flex"} justifyContent={"flex-end"} gap={1}>
									{isAdmin && (
										<AppButton
											btnSize="medium"
											endIcon={<AddOutlined />}
											disabled={hideAdministrativeAction}
											onClick={navigateToAddActivity}
										>
											{"Add Activity"}
										</AppButton>
									)}
									{sessionDetails && !sessionError && (
										<AppButton
											btnSize="medium"
											variant="contained"
											style={{
												color: "white",
												backgroundColor: "#F57F32",
											}}
											onClick={handleProposalNavigation}
										>
											{projectData?.current_user_role ===
											ProjectUserRole.TeamMember
												? "Request Proposal"
												: isAdmin
													? "Review Proposal"
													: ""}
										</AppButton>
									)}
									{isAdmin && sessionDetails && (
										<AppButton
											btnSize="medium"
											variant="contained"
											disabled={hideAdministrativeAction}
											onClick={handleEndSession}
										>
											{"Complete Session"}
										</AppButton>
									)}
								</Grid2>
							)}
						</Grid2>
						<Typography
							fontWeight={500}
							fontSize={18}
							color={sessionError || !sessionDetails ? "red" : "black"}
						>
							{isSessionLoading
								? "Loading..."
								: sessionError ||
									  sessionDetails === undefined ||
									  sessionDetails === null
									? "No Active Session"
									: sessionDetails.is_active
										? `${sessionDetails.session_id}` +
											(sessionDetails.created_at
												? ` ${formatDateWithMonthName(sessionDetails.created_at as string)}`
												: "") +
											(sessionDetails.end_date
												? ` - ${formatDateWithMonthName(sessionDetails.end_date as string)}`
												: "")
										: "No Active Session"}
						</Typography>
					</Box>
					<Box
						flex={1}
						// mb={-2}
						display={"flex"}
						flexDirection={"column"}
					>
						<SwimlaneCalender
							swimlaneWbsListShowStatus={projectData?.wbs_code_swimlane_enabled}
							swimlaneActivityListShowStatus={
								projectData?.activity_code_swimlane_enabled
							}
							session={
								sessionDetails && !sessionError ? sessionDetails?.id : null
							}
							projectId={projectData?.id}
							editable={editable}
							projectData={projectData}
						/>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Swimlane

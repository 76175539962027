import { Grid2, List, ListItem, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateSwimlaneNav } from "../../api/slices/commonSlice"
import { SwimlaneNavState } from "../../api/types/Project"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"
import { ProjectUserRole } from "../../api/types/Auth"
import { AppColors } from "../../theme"
import { ProjectAPIs } from "../../api/network/projectApiService"

interface SwimlaneItemProps {
	item: any
	selectedWbsCode: string | null
	setSelectedWbsCode: any
	clickable: boolean
	isViewer?: boolean
}

export default function SwimlaneItem(props: SwimlaneItemProps) {
	const { item, selectedWbsCode, setSelectedWbsCode, clickable } = props
	const [expanded, setExpanded] = useState(
		item.id?.toString() === selectedWbsCode?.toString() ||
			item?.level === 1 ||
			item?.level === 2 ||
			props?.isViewer
			? true
			: false,
	)
	const isSelected = selectedWbsCode?.toString() === item?.id?.toString()
	const swimlaneNavState: SwimlaneNavState = useSelector(
		(state: RootReduxState) => state.commonSliceData,
	)
	const dispatch = useDispatch()
	const toggleExpand = () => {
		if (clickable) {
			setExpanded(!expanded)
		}
	}

	const handleSelectWbsSwimlane = (id: string | null) => {
		dispatch(
			updateSwimlaneNav({
				selected_wbs: id,
				navType: "wbs",
				projectId: item?.projectId,
				selected_activity_code: swimlaneNavState?.selected_activity_code,
			}),
		)
	}

	const handleClick = () => {
		if (clickable) {
			setSelectedWbsCode(item.id?.toString())
			handleSelectWbsSwimlane(item?.id?.toString())
		}
	}

	return (
		<>
			<Grid2
				display="flex"
				justifyContent="space-between"
				gap={2}
				onClick={handleClick}
				sx={{
					backgroundColor: isSelected ? AppColors.secondary : "transparent", // Highlight selected item
					cursor: "pointer",
				}}
			>
				<Grid2 width="100%" display="flex" alignItems="center" gap={2}>
					<ListItem
						component="div"
						onClick={toggleExpand}
						sx={{ pl: Number(item.level) * 2 }}
					>
						<List
							sx={{
								width: "100%",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							<Typography
								color="#fff"
								fontSize={16}
								sx={{
									width: "100%",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
							>
								{item.wbs_name}
							</Typography>
						</List>
						{item.children?.length > 0 &&
							(expanded ? (
								<ExpandLessIcon sx={{ color: "#fff" }} />
							) : (
								<ExpandMoreIcon sx={{ color: "#fff" }} />
							))}
					</ListItem>
				</Grid2>
			</Grid2>
			{expanded &&
				item.children.map((child: any) => (
					<SwimlaneItem
						key={child.wbs_code}
						item={child}
						selectedWbsCode={selectedWbsCode}
						setSelectedWbsCode={setSelectedWbsCode}
						clickable={clickable}
						isViewer={props?.isViewer ? true : false}
					/>
				))}
		</>
	)
}

import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import "./ActivitiesList.scss"
import { useTranslation } from "react-i18next"
import ViewDetailButton from "../../components/DisplayTable/ViewDetailButton"
import { useMemo, useState } from "react"
import TableCellText from "../../components/DisplayTable/TableCellText"
import {
	activityStatusArray,
	getActivityStatusColor,
	getTotalPages,
} from "../../utils"
import useAppPagination from "../../hooks/useAppPagination"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"
import SearchInput from "../../components/AppSearchBox/AppSearchBox"
import { useDebounce } from "../../constants/constants"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { useGetAllActivitiesQuery } from "../../api/network/projectApiService"
import { ActivityDetail } from "../../api/types/Project"
import { routeUrls } from "../../routes/routeUrls"
import { useLocation, useNavigate } from "react-router-dom"

const tableHeaders = [
	{ name: "Task Code", sortable: true },
	{ name: "Activity Type", sortable: true },
	{ name: "Activity Name", sortable: true },
	{ name: "Planned Start Date", sortable: true },
	{ name: "Actual Start Date", sortable: true },
	{ name: "Planned Finish Date", sortable: true },
	{ name: "Actual Finish Date", sortable: true },
	{ name: "Status", sortable: true },
	{ name: "", sortable: false },
]

const mapColumnToApiField = (columnName: string): string => {
	const columnMap: Record<string, string> = {
		"Task Code": "task_code",
		"Activity Type": "activity_type",
		"Activity Name": "activity_name",
		"Planned Start Date": "planned_start_date",
		"Actual Start Date": "actual_start_date",
		"Planned Finish Date": "planned_finish_date",
		"Actual Finish Date": "actual_finish_date",
		"Status": "status"
	}
	return columnMap[columnName]
}

const ActivitiesList = () => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { t: tUsrList } = useTranslation("translation", {
		keyPrefix: "usersList",
	})

	const navigate = useNavigate()
	const location = useLocation()
	const [statusFilter, setStatusFilter] = useState<string>()
	const [searchTerm, setSearchTerm] = useState("")
	const [sortBy, setSortBy] = useState<string>("")
	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")

	const handleSortActivityData = (columnName: string, isAscending: boolean) => {
		setSortBy(mapColumnToApiField(columnName))
		setSortOrder(isAscending ? "asc" : "desc")
	}

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const {
		data: { results: activitiesData = [], count: totalCount } = {},
		isFetching: isFetchingActivities,
	} = useGetAllActivitiesQuery(
		{
			page: currentPage,
			page_size: pageRowsCount,
			project: location?.state?.project?.id || location?.state?.projectId,
			search: searchTerm,
			status: statusFilter,
			...(sortBy && { sort_by: sortBy }),
			...(sortOrder && { sort_order: sortOrder }),
		},
		{ skip: !location?.state?.project?.id && !location?.state?.projectId },
	)

	const onActivityDetailClick = (id: number) => () => {
		navigate(routeUrls.activity.activityDetails(String(id || 0)), {
			state: { fromActivityList: true },
		})
	}

	const tableColumns = useMemo(
		() => [
			"task_code",
			"activity_type",
			"activity_name",
			(data: ActivityDetail) =>
				formatDateWithMonthName(data.planned_start_date),
			(data: ActivityDetail) =>
				data.actual_start_date &&
				formatDateWithMonthName(data.actual_start_date),
			(data: ActivityDetail) =>
				formatDateWithMonthName(data.planned_finish_date),
			(data: ActivityDetail) =>
				data.actual_finish_date &&
				formatDateWithMonthName(data.actual_finish_date),
			(data: ActivityDetail) => (
				<TableCellText
					sx={{
						color: getActivityStatusColor(data.activity_status),
					}}
				>
					{data.activity_status}
				</TableCellText>
			),
			(data: ActivityDetail) => {
				return <ViewDetailButton onClick={onActivityDetailClick(data.id)} />
			},
		],
		[],
	)

	const statusOptions = useMemo(() => {
		return activityStatusArray.map((s) => ({
			id: s.status_code,
			label: s.status,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const debouncedHandleSearchChange = useDebounce(
		(value: string) => setSearchTerm(value),
		500,
	)

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		debouncedHandleSearchChange(event.target.value)
	}

	const handleStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	const navigateToAddActivity = () => {
		navigate(routeUrls.activity.addActivity, {
			state: { projectId: location?.state?.project?.id },
		})
	}

	const handleRowClick = (data: any) => {
		console.log("RowData", data)
		navigate(routeUrls.activity.activityDetails(data.id), {
			state: {
				activityData: data,
				session:
					location?.state?.project?.active_session || location?.state?.session,
			},
		})
	}

	const handleBack = () => {
		const projectId = location?.state?.project?.id || location?.state?.projectId
		if (projectId) {
			navigate(routeUrls.projects.viewAndEdit(projectId))
		}
	}

	return (
		<div className="activities-list-container">
			<DetailNavBar
				title={t("activities")}
				showBackButton={true}
				onBackPress={handleBack}
			// rightView={
			// 	<AppButton
			// 		btnSize="medium"
			// 		endIcon={<AddOutlined />}
			// 		onClick={navigateToAddActivity}
			// 	>
			// 		{t("add_activity")}
			// 	</AppButton>
			// }
			/>
			<div className="search-and-filters-container">
				<SearchInput
					value={searchTerm}
					onChange={handleSearchChange}
					placeholder={tUsrList("search_here")}
					fullWidth={false}
					height="36px"
				/>
				<AppDropdownFilter
					placeholder={tUsrList("status")}
					value={statusFilter}
					menuItems={statusOptions}
					onChange={handleStatusFilterChange}
				/>
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={activitiesData || []}
				isFetching={isFetchingActivities}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPageCount={totalCount}
				handleSortListData={handleSortActivityData}
			/>
		</div>
	)
}

export default ActivitiesList

import { Box, Grid2, Typography } from "@mui/material"
import { Item } from "./SwimlaneData"
import "./ActivityCard.scss"
import { Svgs } from "../../assets/svgs"
import { useState } from "react"
import SwimlaneActivityDetail from "./SwimlaneActivityDetail"
import { ActivityDetail } from "../../api/types/Project"
import { useLazyGetActivityByIdQuery } from "../../api/network/projectApiService"
import { toast } from "react-toastify"
import AppLoader from "../../components/AppLoader/AppLoader"

const ActivityCard = (activityItem: Item) => {
	const { activity_name, details, status, id, type } = activityItem
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [selectedActivityDetails, setSelectedActivityDetails] =
		useState<ActivityDetail | null>(null)

	const toggleDrawer = () => {
		setIsDrawerOpen((prev) => !prev)
	}

	return (
		<>
			{isDrawerOpen && (
				<SwimlaneActivityDetail
					openDrawer={isDrawerOpen}
					onToggleDrawer={toggleDrawer}
					activityItem={activityItem}
				/>
			)}
			<Grid2 display={"flex"} className="card">
				<div
					style={{
						width: 7,
						backgroundColor: activityItem?.is_marked_red
							? "red"
							: "transparent",
						height: 30,
					}}
				/>
				<Typography className="card-title">{activity_name}</Typography>
				<Typography onClick={toggleDrawer}>
					<Svgs.EyeOutlineBlue />
				</Typography>
				<Typography className="float-text">
					{details?.total_float && details?.total_float < 0
						? details.total_float
						: null}
				</Typography>
			</Grid2>
			{type && (
				<Typography variant="body2" className={`card-type ${type}`}>
					{type}
				</Typography>
			)}
		</>
	)
}

export default ActivityCard

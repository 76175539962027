import * as React from "react"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import Typography from "@mui/material/Typography"
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker"
import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import { PickersDay } from "@mui/x-date-pickers/PickersDay"
import { AppColors } from "../../theme"
import { Moment } from "moment"

const StyledButton = styled(IconButton)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
}))
const StyledDay = styled(PickersDay)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	color: theme.palette.secondary.light,
	...theme.applyStyles("light", {
		color: theme.palette.secondary.dark,
	}),
}))

const AppDatePicker = ({
	label,
	highlightLabelIfDataIsValid,
	touched,
	errorMsg,
	isRequired,
	name,
	value,
	placeholder,
	onChange,
	onBlur,
	disabled,
	slotProps,
	inputProps,
	...props
}: DatePickerProps<any, any> & any) => {
	const { labelSize, inputLabel = {}, ...restSlotProps } = slotProps || {}
	const labelColor = inputLabel.style?.color || "black"
	const handleDateChange = (momentDate: Moment) => {
		const jsDate = momentDate ? momentDate.toDate().toISOString() : null
		onChange(jsDate)
	}

	return (
		<div className="field-wrapper">
			<Typography
				className={`field-label ${highlightLabelIfDataIsValid &&
					(touched ? (errorMsg ? "error" : value ? "secondary" : "") : "")
					}`}
				style={{ fontSize: labelSize || '16px', color: labelColor }}
			>
				{label}
				{isRequired && " *"}
			</Typography>

			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DatePicker
					className={`field-input ${props.className}`}
					format="MM-DD-YYYY"
					sx={{
						width: props.width || "100%",
						"& .MuiOutlinedInput-notchedOutline": {
							border: disabled ? "none" : "1px solid inherit",
							backgroundColor: disabled ? "rgba(230, 230, 230, 0.8)" : "transparent"
						},
						"& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
							borderColor: "#D9D9D9 !important",
						},
						"& .MuiFormHelperText-root": {
							marginLeft: 0,
						},
						"& .MuiInputBase-input": {
							"&::placeholder": {
								color: labelColor,
							},
							height: "8px",
							fontSize: "15px",
						},
						"& .MuiInputBase-input.Mui-disabled": {
							opacity: 1,
							position: "relative",
							zIndex: 50,
						},
						...props.sx,
					}}
					slotProps={{
						...props.slotProps,
						input: {
							...(props.slotProps?.input || {}),
							style: {
								borderRadius: "6px",
								backgroundColor: disabled ? "#E6E6E6" : "#FAFAFA",
								height: "20px",
								...(((props.slotProps?.input as any) || {}).style || {}),
							},
						},
						htmlInput: {
							...(props.slotProps?.htmlInput || {}),
							style: {
								paddingTop: "10px",
								paddingBottom: "10px",
								fontSize: "12px",
								lineHeight: "20px",
								letterSpacing: "-0.15px",
								...(((props.slotProps?.htmlInput as any) || {}).style || {}),
							},
						},
					}}
					value={value}
					onChange={handleDateChange}
					onBlur={onBlur}
					disabled={disabled}
					inputProps={inputProps}
					{...props}
				/>
			</LocalizationProvider>
		</div>
	)
}

export default AppDatePicker
